import { dimensions, fonts, colors, breakpoints } from './variables'
import { getEmSize } from './mixins'

export default `
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    //font-size: ${dimensions.fontSize.regular}px !important;
    font-family: ${fonts.bcTwo} !important;
    line-height: ${dimensions.lineHeight.regular} !important;
  }

  body {
  --theme:'light';
--transparent:rgba(255,255,255,.2);
  --bw:white;
  --icon1:rgba(53, 54, 57,.2);
  --txt2:rgba(53, 54, 57,.4);
  --icon2:rgba(53, 54, 57,.5);
  --inputTxt:#281224;
  --input:rgba(0,0,0,0.03);
  --inputHover:white;
  --selectBg:white;
  --filter:#fff;
  --hase:#7e287e;
  --haase:#7e287e;
  --berlinerWiese:#9bbdba;
  --menuLight:#fff;
  --menuDark:rgba(255,255,255,0);
  --cartLight:#fff;
  --cartHoverLight:${colors.fontDark};
  --cartItemsLight:${colors.fontDark};
  --cartHoverDark:rgba(255,255,255,0);
  --cartItemsDark:rgba(255,255,255,0);
  --cartDark:rgba(255,255,255,0);
  --particles:#fff;
  --main:#fff;
  --dark:none;
  --light:flex;
    --bg:${colors.fontDark};
    --border:rgba(0,0,0,.1);
        --strain:white;

    --background:rgba(238,238,238,.6);
    --headerBg:rgba(102,30,94,.3);
  --txt:rgb(53, 54, 57);

    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: ${fonts.bcRoman};
   // letter-spacing:1px;
    color: var(--txt);
    background-color:  var(--bg);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  body.dark {
--theme:dark;
--transparent:rgba(53, 54, 57,.2);
    --cartHoverLight:rgba(255,255,255,0);
  --cartItemsLight:rgba(255,255,255,0);
  --cartHoverDark:#d7b1d3;
  --cartItemsDark:${colors.fontDark};

    --bw:rgba(238,238,238,.03);

    --icon1:rgba(238,238,238,.5);
    --txt2:rgba(238,238,238,.5);
    --icon2:rgba(238,238,238,.5);
      --inputTxt:#d7b1d3;

      --input:rgba(0,0,0,.06);

  --inputHover:rgba(126, 40, 126, 0.1);

    --selectBg:rgb(53, 54, 57);

  --filter:var(--headerBg);
    --hase:#d7b1d3;
    --haase:#7e287e;
  --berlinerWiese:white;

    --menuLight:rgba(255,255,255,0);
  --menuDark:#fff;
  --cartLight:rgba(255,255,255,0);
  --cartDark:#fff;
    --particles:#fff;
  --main:#281224;
    --headerBg:rgba(102,30,94,.2);

    --dark:flex;
      --light:none;
    --border:rgba(238,238,238,.1);
    --strain:rgba(238,238,238,.1);
        --background:rgba(238,238,238,.07);


  --bg:rgb(53, 54, 57);
  --txt:${colors.fontDark};
  }

  a {
    color: var(--txt);
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid ${colors.ui.light};
    font-size: 85%;
    border-collapse: collapse;
  }

  td,
  th {
    padding: .25rem .5rem;
    border: 1px solid ${colors.ui.light};
  }

  th {
    text-align: left;
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        td {
          background-color: ${colors.ui.whisper};
        }
        tr {
          background-color: ${colors.ui.whisper};
        }
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.414rem;
    margin-bottom: .5rem;
    color: ${colors.fontDark};
    font-weight: 600;
    line-height: ${dimensions.lineHeight.heading};
    text-rendering: optimizeLegibility;
  }

  h1 {
    margin-top: 0;
    font-size: ${dimensions.headingSizes.h1}rem;
    color:var(--txt);
  }

  h2 {
    font-size: ${dimensions.headingSizes.h2}rem;
  }

  h3 {
    font-size: ${dimensions.headingSizes.h3}rem;
  }

  h4, h5, h6 {
    font-size: ${dimensions.headingSizes.h4}rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
        font-family:${fonts.bcHairLine};
font-size:1.3rem !important;
text-align:center;
    letter-spacing: 0.246rem;
  }

  strong {
    color: ${colors.black};
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: .5rem;
  }

  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${colors.ui.light};
  }

  blockquote {
    margin: .8rem 0;
    padding: .5rem 1rem;
    border-left: .25rem solid ${colors.ui.light};
    color: ${colors.gray.calm};

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${getEmSize(breakpoints.md)}em) {
      padding-right: 5rem;
      padding-left: 1.25rem;
    }
  }

  video {
    pointer-events: none;

  }


`
