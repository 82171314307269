import React from "react"
import { useEffect} from "react";
import styled from '@emotion/styled'
import {

  standard_shipping_de
} from '../constants/shippingOptions'


const Nonvisible = styled.div`
display:none;
`




const StateToLocal = (props) => {

  useEffect(() => {
      window.localStorage.setItem('dark', props.localData.dark);
      window.localStorage.setItem('moreTabsOpen', props.localData.moreTabsOpen);
      window.localStorage.setItem('productsTabOpen', props.localData.productsTabOpen);
      window.localStorage.setItem('address2Open', props.localData.address2Open);
      window.localStorage.setItem('_lila_express', props.localData._lila_express);

      window.localStorage.setItem('_lila_cus_mail',props.localData._lila_cus_mail)
      window.localStorage.setItem('_lila_cus_name1',props.localData._lila_cus_name1)
      window.localStorage.setItem('_lila_cus_name2',props.localData._lila_cus_name2)
      window.localStorage.setItem('_lila_cus_street',props.localData._lila_cus_street)
      window.localStorage.setItem('_lila_cus_nr',props.localData._lila_cus_nr)
      window.localStorage.setItem('_lila_cus_code',props.localData._lila_cus_code)
      window.localStorage.setItem('_lila_cus_city',props.localData._lila_cus_city)
      window.localStorage.setItem('_lila_cus_phone',props.localData._lila_cus_phone)
      window.localStorage.setItem('_lila_cus_company',props.localData._lila_cus_company)
      window.localStorage.setItem('_lila_cus_address2',props.localData._lila_cus_address2)
      window.localStorage.setItem('_lila_region',props.localData._lila_region)
      window.localStorage.setItem('_lila_pay',props.localData._lila_pay)

      let rItems = props.localData.cartItems
      let cItems = props.localData._lila_cartItems
    for(let r =0;r<rItems.length;r++){
      let r_id=rItems[r].id
      for(let c=0;c<cItems.length;c++){
        let c_id=cItems[c].id
        if(c_id===r_id){
          cItems[c].c=rItems[r].quantity
        }
      }
    }

      window.localStorage.setItem('_lila_cartItems', JSON.stringify(cItems));



      //console.log(props.firstSelection)
     // console.log(window.localStorage)

    },
    [props.localData.moreTabsOpen, props.localData, props._lila_cus_mail
    ],
  );




  return <Nonvisible/>

}
const defaultContextValue = {
  data: {
    // set your initial data shape here
dark:true,
    //products todo: new product
    _lila_cartItems:[
      {
        id:0,
        c:0
      },
      {
        id:1,
        c:0
      },
      {
        id:2,
        c:0
      },
      {
        id:3,
        c:0
      },
      {
        id:4,
        c:0
      },
      {
        id:5,
        c:0
      },
      {
        id:6,
        c:0
      },
      {
        id:7,
        c:0
      },
      {
        id:8,
        c:0
      },
      {
        id:9,
        c:0
      },
      {
        id:10,
        c:0
      },
      {
        id:11,
        c:0
      }

      ]
   ,
    address2Open:false,
    menuOpen:false,
    productsTabOpen: true,
    merchTabOpen:false,
    moreTabsOpen:false,
    cartOpen:false,

    _lila_filter:[],

    total:0,
    discount:0,

    shipping:0,
    _lila_region:1,
    _lila_delivery:'hermes',
    _lila_express:false,
    _lila_exp_rates:        {
                                id:'de',
                                rates:[14,15,25],
                                zone:0
                            },
    _lila_shipping_options:standard_shipping_de,
    _lila_shipping_solution:standard_shipping_de[0],

    _lila_pay:5,

    _lila_cus_mail:'',
    _lila_cus_name1:'',
    _lila_cus_name2:'',
    _lila_cus_company:'',
    _lila_cus_street:'',
    _lila_cus_nr:'',
    _lila_cus_code:'',
    _lila_cus_city:'',
    _lila_cus_phone:'',
    _lila_cus_address2:'',


    //todo: new product
    cartItems:[{
      id: 0,
      item:'001',
      title:'Zkittlez',
      subTitle:'2g',
      brand:'lilahase',
      quantity:0,
      price:25,
      amount:0,
      isMax:false,
      isMin:false,

      grow:'indoor',
      cbd:'5%',
      thc:'<0.2%',
      made:'made in Berlin',

      smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
      productLink:'/zkittlez'
    },{
        id: 1,
        item:'002',
        title:'Zkittlez',
        subTitle:'5g',
        brand:'lilahase',
        quantity:0,
        price:50,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,

      grow:'indoor',
      cbd:'5%',
      thc:'<0.2%',
      made:'made in Berlin',

      smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
      productLink:'/zkittlez'
    },
      {
        id: 2,
        item:'003',

        title:'Alien Haze',
        subTitle:'2g',
        brand:'tomhemps',
        quantity:0,
        price:25,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,

        grow:'indoor',
        cbd:'5%',
        thc:'<0.2%',
        made:'made in Berlin',

        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/alien-haze'
      },
      {
        id: 3,
        item:'004',
        title:'Alien Haze',
        subTitle:'5g',
        brand:'tomhemps',
        quantity:0,
        price:50,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,

        grow:'indoor',
        cbd:'5%',
        thc:'<0.2%',
        made:'made in Berlin',

        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/alien-haze'
      },
      {
        id: 4,
        item:'005',
        title:'Strawberry Haze',
        subTitle:'2g',
        brand:'berlinerwiese',
        quantity:0,
        price:25,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,

        grow:'indoor',
        cbd:'5%',
        thc:'<0.2%',
        made:'made in Berlin',

        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/strawberry-haze'
      },
      {
        id: 5,
        item:'006',
        title:'Strawberry Haze',
        subTitle:'5g',
        brand:'berlinerwiese',
        quantity:0,
        price:50,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,

        grow:'indoor',
        cbd:'5%',
        thc:'<0.2%',
        made:'made in Berlin',



        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/strawberry-haze'
      },
      {
        id: 6,
        item:'007',
        title:'Spaghetti Cheese',
        subTitle:'2g',
        brand:'mamamary',
        quantity:0,
        price:25,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,


        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/spaghetti-cheese'
      },
      {
        id: 7,
        item:'008',
        title:'Spaghetti Cheese',
        subTitle:'5g',
        brand:'mamamary',
        quantity:0,
        price:50,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,


        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/spaghetti-cheese'
      },
      {
        id: 8,
        item:'009',
        title:'Charas',
        subTitle:'2g',
        brand:'trinacriahemp',
        quantity:0,
        price:25,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,


        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/indian-charas'
      },
      {
        id: 9,
        item:'010',
        title:'Charas',
        subTitle:'5g',
        brand:'trinacriahemp',
        quantity:0,
        price:50,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,


        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/indian-charas'
      },
      {
        id: 10,
        item:'011',
        title:'Sour Tangle',
        subTitle:'2g',
        brand:'sensitivecbd',
        quantity:0,
        price:25,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,


        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/indian-charas'
      },
      {
        id:11,
        item:'012',
        title:'Sour Tangie',
        subTitle:'5g',
        brand:'sensitivecbd',
        quantity:0,
        price:50,
        amount:0,
        isMax:false,
        isMin:false,
        stock:0,


        smallThumb:'http://localhost:8000/static/64b12f3bfdce9ca7c35100ec06a556b8/14b42/zz-2.jpg',
        productLink:'/sour-tangie'
      },



      ],


  },
  set: () => {},
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

class ContextProviderComponent extends React.Component {
  constructor(props) {
    super(props)

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,

    }
  }

  setData(newData) {
   if(newData.cartItems!=undefined) {
     let gut = 0;

     let total = 0;
     for (let n = 0; n < newData.cartItems.length; n++) {
       total += (newData.cartItems[n].price * newData.cartItems[n].quantity)
     }
     if (total >= 60) {
       gut = 5
     }
     if (total >= 100) {
       gut = 10
     }
     if (total >= 200) {
       gut = 20
     }
     if (total >= 500) {
       gut = 50
     }
     newData.discount = gut
     newData.total = total
   }

    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))

  }

componentDidMount(): void {

  const isItem = (item) => {
    if (window.localStorage.getItem(item) != undefined) {
      return true
    }
    return false

  };

  let data = {...this.state.data}

  data._lila_cus_name1 = isItem('_lila_cus_name1')?window.localStorage._lila_cus_name1:''
  data._lila_cus_name2 = isItem('_lila_cus_name2')?window.localStorage._lila_cus_name2:''
  data._lila_cus_mail = isItem('_lila_cus_mail')?window.localStorage._lila_cus_mail:''
  data._lila_cus_street = isItem('_lila_cus_street')?window.localStorage._lila_cus_street:''
  data._lila_cus_nr = isItem('_lila_cus_nr')?window.localStorage._lila_cus_nr:''
  data._lila_cus_phone = isItem('_lila_cus_phone')?window.localStorage._lila_cus_phone:''
  data._lila_cus_address2 = isItem('_lila_cus_address2')?window.localStorage._lila_cus_address2:''
  data._lila_cus_city = isItem('_lila_cus_city')?window.localStorage._lila_cus_city:''
  data._lila_cus_code = isItem('_lila_cus_code')?window.localStorage._lila_cus_code:''
  data._lila_cus_company = isItem('_lila_cus_company')?window.localStorage._lila_cus_company:''
  data._lila_region = isItem('_lila_region')?Number(window.localStorage._lila_region):1
  data._lila_pay = isItem('_lila_pay')?Number(window.localStorage._lila_pay):2


  data.dark = isItem('dark')?JSON.parse(window.localStorage.dark):true;
  data.moreTabsOpen = isItem('moreTabsOpen')?JSON.parse(window.localStorage.moreTabsOpen):false;
  data.productsTabOpen = isItem('productsTabOpen')?JSON.parse(window.localStorage.moreTabsOpen):false;
  data.address2Open = isItem('address2Open')?JSON.parse(window.localStorage.address2Open):false;
  data._lila_express = isItem('_lila_express')?JSON.parse(window.localStorage._lila_express):false;

  data._lila_cartItems = isItem('_lila_cartItems')?JSON.parse(window.localStorage._lila_cartItems):defaultContextValue.data._lila_cartItems

  let c_items = isItem('_lila_cartItems')?JSON.parse(window.localStorage._lila_cartItems):defaultContextValue.data._lila_cartItems
  let r_items = data.cartItems
  let total = 0;

  for(let c=0;c<c_items.length;c++){
    let c_id = c_items[c].id
  for(let r=0;r<r_items.length;r++){
    let r_id = r_items[r].id
    if(c_id===r_id){
      r_items[r].quantity=c_items[c].c
    }

  }
  }

  for(let t=0;t<r_items.length;t++){
    total+=(r_items[t].quantity*r_items[t].price)
//console.log(total)
  }

  let gut =0;

  if (total >= 60) {
    gut = 5
  }
  if (total >= 100) {
    gut = 10
  }
  if (total >= 200) {
    gut = 20
  }
  if (total >= 500) {
    gut = 50
  }
  data.discount=gut
  data.total = total
  data.cartItems =  r_items;
  this.setState(state => ({
    data: {
      ...state.data,
      ...data,
    },
  }))
}


  render() {
    let localData = {...this.state.data}
    return <Provider value={this.state}>{this.props.children} <StateToLocal  localData={localData}/></Provider>
  }
}

export { Consumer as default, ContextProviderComponent }
