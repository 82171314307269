import {
  BitcoinIcon, CreditIcon, GiropayIcon,
  IDEALIcon, KlarnaIcon, LilaGiroPayWrap,
  LilaIconWrap,
  LilaKlarna,
  LilaPaymentMethod,
  LilaSvgContainer, PayPalIcon, TransferIcon
} from './paymentMethodIcons'
import * as React from 'react'
import { FormattedMessage } from 'react-intl';



export const standard_pm_methods = [
  { id:0, value: 'iDEAL', title:'iDEAL Bank', color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><IDEALIcon/></LilaIconWrap><LilaPaymentMethod>iDEAL</LilaPaymentMethod></LilaSvgContainer> },
  { id: 1, value: 'klarna', title:'Klarna', color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><LilaKlarna><KlarnaIcon/></LilaKlarna></LilaIconWrap><LilaPaymentMethod>Klarna (coming soon)</LilaPaymentMethod></LilaSvgContainer>},
  { id:2, value: 'bitcoin',title:'Bitcoin',color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><BitcoinIcon/></LilaIconWrap><LilaPaymentMethod>Bitcoin</LilaPaymentMethod></LilaSvgContainer> },
  { id: 3, value: 'paypal', title:'PayPal', color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><PayPalIcon></PayPalIcon></LilaIconWrap><LilaPaymentMethod>PayPal</LilaPaymentMethod></LilaSvgContainer>},
  { id: 4, value: 'giro', title:'Giropay', color:'darkgrey',label: <LilaSvgContainer><LilaIconWrap><LilaGiroPayWrap><GiropayIcon/></LilaGiroPayWrap></LilaIconWrap><LilaPaymentMethod>Giropay</LilaPaymentMethod></LilaSvgContainer> },
  { id: 5, value: 'credit', title:'Kreditkarte', color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><CreditIcon/></LilaIconWrap><LilaPaymentMethod><FormattedMessage id="pm_1" /></LilaPaymentMethod></LilaSvgContainer> },
  { id:6, value: 'bank', title:'Bank Transfer',color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><TransferIcon/></LilaIconWrap><LilaPaymentMethod><FormattedMessage id="pm_2" /></LilaPaymentMethod></LilaSvgContainer>},
]





