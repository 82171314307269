import * as React from 'react'
import styled from '@emotion/styled'
import Ripples from 'react-ripples'
import { fonts } from '../../styles/variables'
import LocalizedLink from '../links/LocalizedLink'

const LilaInfo = styled.div`
font-size: 0.97rem;
font-family:${fonts.bcExtraLight};
width:100%;
padding-bottom:1rem;
letter-spacing: 1px !important;


`

const LilaProductName = styled.div`
display:flex;
padding-top: 1.25rem;

`

const LilaProductTitle = styled.div`

`
const LilaProductSubTitle = styled.div`
margin-left:1rem;
opacity:.5;
`

const LilaQty = styled.div`
padding-top:1rem;
display:flex;
align-items:center;


display:flex;
align-items:center;
.lila-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  width: 10px;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(90deg);
    }
    &:before {
      transform: rotate(180deg);
    }
  }
}
`


const LilaPlus = styled.div`

width:30px;
height:30px;
border-radius:50%;
border:1px solid var(--border);
display:flex;
justify-content:center;
align-items:center;
`


const LilaMinus = styled.div`
width:30px;
height:30px;
border-radius:50%;
border:1px solid var(--border);
display:flex;
justify-content:center;
align-items:center;

`

const LilaIcon = styled.div`
width:100%;
`

const LilaRipples = styled(Ripples)`
width:30px;
height:30px;
border-radius:50%;
cursor:pointer;
`
const LilaRipples_1 = styled(Ripples)`
width:30px;
height:30px;
border-radius:50%;
cursor:pointer;
margin-right:1rem;
`

const LilaCount = styled.div`
width:80px;
`



const LilaMax = styled.div`
font-family:${fonts.bcHairLine};
`



class ProductInfo extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  render() {
    return(


        <LilaInfo id={'component'}>

<LilaProductName>
  <LocalizedLink to={this.props.link}>
  <LilaProductTitle>{this.props.title}</LilaProductTitle>
  </LocalizedLink>

  <LilaProductSubTitle>{this.props.subTitle}</LilaProductSubTitle>
</LilaProductName>
          <LilaQty>

            <LilaCount>   {this.props.quantity}x {this.props.price}€</LilaCount>
            <LilaRipples_1 onClick={()=>this.props.minus()}>   <LilaMinus> <LilaIcon className={'lila-icon'}></LilaIcon></LilaMinus></LilaRipples_1>
            <LilaRipples onClick={()=>this.props.plus()} >   <LilaPlus> <LilaIcon className={'lila-icon collapsed'}></LilaIcon></LilaPlus></LilaRipples>

          </LilaQty>{ this.props.quantity>=10&&
          <LilaMax>max.</LilaMax>
        }


    </LilaInfo>



    )
  }
}


export default ProductInfo


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
