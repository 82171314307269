import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import styled from "@emotion/styled";
//import {createPaymentIntent} from "./functions";
import {navigate} from "@reach/router";
import { fonts } from '../styles/variables'
import { createPaymentIntent } from '../functions/stripeFunctions'
import {
  getNewOrderID,
  getQuantity,
  getRequestData,
  readBulkedInventory,
  setBulkedInventory
} from '../functions/stockFunctions'
import { sendAdminOrderSuccess, sendCusOrderSuccess } from '../functions/sesFunctions'





 const DivDesktop = styled.div`
  display:flex;
  justify-content:center;
  overflow:hidden;
left:-1px;
position:relative;

.paypal-button paypal-button-context-iframe paypal-button-label-paypal paypal-button-size-medium paypal-button-layout-vertical{
display:flex;
justify-content:center;
}

.paypal-button {
    border-radius: 0px !important;

}
  .paypal-button .paypal-button-shape-pill {
    border-radius: 0px !important;
}

.paypal-button-text {
   font-family:${fonts.button} !important;
   }

#paypal-animation-container{
min-width: 345px !important;
max-width: 345px !important;
}

.paypal-button-container {

min-width: 345px !important;
max-width: 345px !important;
}

   .paypal-button{
  min-width:345px !important;
  max-width:345px !important;
  display:flex;
justify-content:center;
   }

#zoid-paypal-button-abc12c6d2e.paypal-button-layout-vertical > .zoid-outlet {
min-width:345px !important;
display:flex;
justify-content:center;
}
.paypal-button .paypal-button-shape-rect {
 font-family:${fonts.bcHairLine} !important;
letter-spacing:1px !important;
  border-radius: 0rem !important;

  }

  .paypal-button paypal-button-number-0 paypal-button-layout-horizontal paypal-button-shape-rect paypal-button-branding-branded paypal-button-number-single paypal-button-env-sandbox paypal-should-focus paypal-button-label-checkout paypal-button-color-gold paypal-button-logo-color-blue{
    border-radius: 0rem;
background-clip:none;
  }

 `





export default class PaypalExpress extends React.Component<any,any> {
  constructor(props){
    super(props)
  }
  render() {
    const onSuccess = (payment) => {
      // Congratulation, it came here means everything's fine!
      //console.log("The payment was succeeded!", payment);
      //const postBooking = this.props.postBooking

        let cData = this.props.data
        let extra = {
          payment_method_types: ['card'],
          confirmation: false,
          capture_method: 'automatic'
        }
        createPaymentIntent(cData, extra);

        readBulkedInventory(cData.cartItems)
          .then(dynamoData => {
            // console.log(dynamoData)
            const requestData = getRequestData( dynamoData.data, getQuantity(cData.cartItems))
            setBulkedInventory(requestData)
            //todo: send error email to info@

          })

      const orderID = getNewOrderID()
      sendCusOrderSuccess(cData, orderID)
      sendAdminOrderSuccess(cData, orderID)
        //todo:
        //      backend: error/bounce handling
        //      frontend: show notifcation
        navigate('/order-success')
        /* let paymentIntent =  createPaymentIntent(this.props.amount, 'eur',cus_id, false,'manual');
         paymentIntent.then(function(pi) {


         let descr = 'PayPal On Website'+pi.id
         let reservationID=  postBooking(descr, cus_id, pi.id); // post reservation to cloudbeds (reservation, items, note, payment), description used for paymentNotes
         reservationID.then(function (r) {

           if (r != null) {
            // console.log(JSON.parse(r.body))
           }

           navigate('/success')
         });
         // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
       }.bind(this))
   */

    }

    const onCancel = (data) => {
      // User pressed "cancel" or close Paypal's popup!
      //console.log('The payment was cancelled!', data);
      //todo: cancellation notification

      // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }

    const onError = (err) => {
      // The main Paypal's script cannot be loaded or somethings block the loading of that script!
      //console.log("Error!", err);
      // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
      //todo: loading error notification

      // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }

    let env = 'production'; // you can set here to 'production' for production
    let currency = 'EUR'; // or you can set this value from your props or state
    let total = this.props.amount; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

    const client = {
      sandbox:    'YOUR-SANDBOX-APP-ID',
      production: 'AT1-UV0qSvYYRF1fGVWUiXEJ83asdL3J8HCeXMRpPJWCoZyah-rkCe5z5-7wPhooV1Cm1k3aDQGciwLs',
    }
    // In order to get production's app-ID, you will have to send your app to Paypal for approval first
    // For sandbox app-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App"):
    //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
    // For production app-ID:
    //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

    // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!
    return (
      <div>


<DivDesktop>
        <PayPalButton  style={{
          size: 'medium',
          color: 'gold',
          shape: 'pill',
          borderRadius:'0rem !important',
          fontFamily:`${fonts.bcHairLine}`
        }}   options={{
          clientId: "AT1-UV0qSvYYRF1fGVWUiXEJ83asdL3J8HCeXMRpPJWCoZyah-rkCe5z5-7wPhooV1Cm1k3aDQGciwLs",
          currency:"EUR"
        }} amount={total} onError={onError} onSuccess={onSuccess}  />
</DivDesktop>
      </div>
        );
  }
}
