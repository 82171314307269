import * as React from 'react'
import styled from '@emotion/styled'

import LocalizedLink from "./links/LocalizedLink";
import {navigate} from "@reach/router";
import {colors, fonts} from "../styles/variables";
import MatchToggler from "./MatchToggler";
import Fade from 'react-reveal/Fade';

const StyledFooter = styled.footer`
text-align:center;
 align-items: center;
  margin-left:auto;
  margin-right:auto;
  justify-content:space-between;
  width:527px;
    @media screen and (max-width: 600px) {
width: auto;

width:100%;




   }
  display:flex;
  height:80px;
  padding-bottom:.7rem;
  margin-top:2rem;
  padding-left:0.7rem;
  padding-right:0.7rem;

         @media screen and (max-width: 1295px) {
}


`

const FooterBox = styled.div`
 width:auto;
 text-align:left;
 height:65px;
 margin-right:4%;
 padding-right:4%;
 border-right:solid 1px rgba(68, 68, 68, .1);
padding-top:8px;

  @media screen and (max-width: 1295px) {
display:none;
   }

`

const SocialBox = styled.div`
  display:flex;
width:30%;
   border-right:solid 1px rgba(68, 68, 68, .1);
    padding-right:4%;
    height:100%;
align-items:baseline;
      @media screen and (max-width: 1295px) {
border:none;
justify-content:space-between;
width:100%;

    padding-right:0%;
}

`

/*
*
*        <SocialIcon style={{ height: 55, width: 55}} url="https://www.instagram.com/lilahase_030/"
                    fgColor={colors.fontDark} bgColor={colors.bgDark}/>
        <SocialIcon style={{height: 55, width: 55}} url="https://www.facebook.com/lilahase/" fgColor={colors.fontDark}
                    bgColor={colors.bgDark}/>

*
* */

const FlagDiv = styled.div`
display:flex;
justify-content:space-between;
width:100px;
//padding-left:10px;

height:100%;
align-items:flex-end;
`

const StyledLink = styled(LocalizedLink)`
font-family: "Theano Didot", Regular;
letter-spacing: 4px;
color:rgb(68, 68, 68);
display:block;
font-size: 0.9em;
width:auto;
margin-top:2px;


`

const Copyright = styled.div`
  margin-bottom:2%;
  text-align:center;
  letter-spacing: 4px;
  width:100%;
  color:lightgrey;
  margin-left:auto;
  margin-right:auto;
  font-family: "Theano Didot", Regular;
`

const RDiv = styled.div`
width:100% !important;
`

const Invisible = styled.div`
opacity:0;
`




const StyledP = styled.div`
cursor:pointer;
transition: all 1s ease;
display:inline-block;
line-height:10px;


:after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: ${colors.fontDark};
  transition: 300ms;
}

:hover:after {
  width: 100%;
}



`

const IconFlex = styled.div`
display:flex;
justify-content: center;
width:100%;

align-items:flex-end;
`

const Circle = styled.div`
justify-content: center;
display:flex;
align-items:center;
svg{
width:16px;

}




//border-radius:100%;
//background:rgba(238,238,238,.1);
width:70px;
height:70px;
display:flex;


`



const LilaCloud = styled.div`
width:100%;
display:flex;
justify-content:center;
letter-spacing:1px;



.pip {
  width: 24px;
  height: 24px;
  margin: 7px;
  background-color: rgba(238,238,238,.2);
  border-radius: 100%;
  transition: all .3s ease-in;
}

.pip--on {
  background-color: #994095;
  transform: scale(1.1);
}



.binary-digit-group {
  display: flex;
  margin: 0 10px;
}
`

const Company = styled.p`
font-family:${fonts.bcHairLine};
font-size:1em !important;
text-align:center;
letter-spacing:1em;

`

const LilaCoffee = styled.div`
display:flex;
width:30%;
height:100%;
align-items:flex-end;
`

const MadeWith = styled.div`
width:350px;
margin-top:0.7em;
margin-bottom:0.7em;
margin-left:auto;
margin-right:auto;

text-align:center;
display:flex;
justify-content:center;
align-items:center;
font-family:${fonts.bcHairLine};

    @media screen and (max-width: 600px) {
width: auto;
width:100%;




   }
`


const A = styled.a`
display: flex;
align-items: flex-end;
`

const LilaMade=styled.div`
width:100px;
display:flex;
justify-content:flex-end;
`

const LilaBy = styled.div`
width:100px;


`

const Img = styled.img`
cursor:pointer;
`
class Footer extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
      isBefore:false,

    }
  }

  selectDE(){

      navigate(
        "/",
        { replace: true }      )

}

  selectEN(){

      navigate('/en',  { replace: true });
  }



  componentDidMount() {

  }

  render() {
    return(
      <div>
        <Fade bottom cascade >

        <StyledFooter id={'foot'}>








      <IconFlex>
        <Circle>
<MatchToggler/>

        </Circle>

      </IconFlex>

    </StyledFooter>
        </Fade>

      </div>

    )
  }
}


export default Footer


/*
        <LilaCoffee title="Buy us a coffee">
          <A  href="https://www.buymeacoffee.com/lilahase"><Img
                  src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy us a coffee"/></A>

        </LilaCoffee>



                <Fade bottom>
          <MadeWith><LilaMade>made with</LilaMade> <HempHeart/> <LilaBy>by lilacloud.io</LilaBy></MadeWith>
        </Fade>

 */
