import { FormattedMessage } from 'react-intl';

import * as React from 'react'


export const _lilaRegions = [
  { id:0, value: 'be',iso:'be', color:'darkgrey', label: <FormattedMessage id={'region_1'}/>},
  { id:1, value: 'de',iso:'de' ,color:'darkgrey', label: <FormattedMessage id={'region_2'}/> },
  { id:2, value: 'gb',iso:'gb' ,color:'darkgrey', label: <FormattedMessage id={'region_3'}/> },
  { id:3, value: 'it',iso:'it' ,color:'darkgrey', label: <FormattedMessage id={'region_4'}/> },
  { id:4, value: 'ie',iso:'ie' ,color:'darkgrey', label: <FormattedMessage id={'region_5'}/> },
  { id:5, value: 'nl',iso:'nl' ,color:'darkgrey', label: <FormattedMessage id={'region_6'}/> },
  { id:6, value: 'at',iso:'at' ,color:'darkgrey', label: <FormattedMessage id={'region_7'}/> },
  { id:7, value: 'pt',iso:'pt' ,color:'darkgrey', label: <FormattedMessage id={'region_8'}/> },
  { id:8, value: 'es',iso:'es' ,color:'darkgrey', label: <FormattedMessage id={'region_9'}/> },
  { id:9, value: 'se',iso:'se' ,color:'darkgrey', label: <FormattedMessage id={'region_10'}/> },
  { id:10, value: 'ch',iso:'ch' ,color:'darkgrey', label: <FormattedMessage id={'region_11'}/> },
  { id:11, value: 'cz',iso:'cz' ,color:'darkgrey', label: <FormattedMessage id={'region_12'}/> },
]
