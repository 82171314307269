const config = {
  pm: {
    createPaymentIntentUrl: 'https://k9z3e1sd6k.execute-api.us-east-1.amazonaws.com/dev/create-payment-intent'



  },

  mailing:{
    setBulkedInventoryUrl:'',
    sendCusOrderSuccessUrl:'https://1j9clpwhmi.execute-api.us-east-1.amazonaws.com/dev/send-customer-order-success',
    sendAdminOrderSuccessUrl:'https://1j9clpwhmi.execute-api.us-east-1.amazonaws.com/dev/send-admin-order-success',
    sendNewsletterSuccessUrl:'https://1j9clpwhmi.execute-api.us-east-1.amazonaws.com/dev/send-newsletter-success'
  },

  inventory:{
    readBulkedInventoryUrl:' https://of4rgaxhu0.execute-api.us-east-1.amazonaws.com/dev/read-bulked-inventory',
    setBulkedInventoryUrl:'https://of4rgaxhu0.execute-api.us-east-1.amazonaws.com/dev/set-bulked-inventory',

  },

};

export default config;
