import { LilaIconWrap, LilaPaymentMethod, LilaSvgContainer } from './paymentMethodIcons'
import { DHLIcon, DPDIcon, FedExIcon, GLSIcon, HermesIcon, UPSIcon } from './shippingSolutionIcons'
import * as React from 'react'


export const standard_shipping_de = [
  { title:'Hermes', code:'hermes',value: 0,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><HermesIcon/></LilaIconWrap><LilaPaymentMethod>Hermes</LilaPaymentMethod></LilaSvgContainer> },
  {title:'GLS', code:'gls', value: 1, color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><GLSIcon/></LilaIconWrap><LilaPaymentMethod>GLS</LilaPaymentMethod></LilaSvgContainer>},
  { title:'DHL National', code:'dhl', value: 2, color:'darkgrey',label: <LilaSvgContainer><LilaIconWrap><DHLIcon/></LilaIconWrap><LilaPaymentMethod>DHL</LilaPaymentMethod></LilaSvgContainer> },
  {title:'dpd', value: 3, code:'dpd', color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><DPDIcon/></LilaIconWrap><LilaPaymentMethod>dpd</LilaPaymentMethod></LilaSvgContainer> },
]

export const express_shipping_de = [
  { title:'DHL Express', code:'dhlExp', value: 0, color:'darkgrey',label: <LilaSvgContainer><LilaIconWrap><DHLIcon/></LilaIconWrap><LilaPaymentMethod>DHL Express National</LilaPaymentMethod></LilaSvgContainer> },
  { title:'UPS Express Saver',code:'upsExp', value: 1,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><UPSIcon/></LilaIconWrap><LilaPaymentMethod>UPS Express Saver</LilaPaymentMethod></LilaSvgContainer> },
  {title:'GLS Express', code:'glsExp', value: 2, color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><GLSIcon/></LilaIconWrap><LilaPaymentMethod>GLS ExpressParcel National</LilaPaymentMethod></LilaSvgContainer>},
  {title:'dpd express', code:'dpdExp', value: 3, color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><DPDIcon/></LilaIconWrap><LilaPaymentMethod>dpd express</LilaPaymentMethod></LilaSvgContainer> },
  { title:'FedEx Priority Overnight',code:'fedexExp', value: 4,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><FedExIcon/></LilaIconWrap><LilaPaymentMethod>FedEx Priority Overnight</LilaPaymentMethod></LilaSvgContainer>},
]


export const standard_shipping_gb = [
  { title:'DHL', code:'dhl', value: 0, color:'darkgrey',label: <LilaSvgContainer><LilaIconWrap><DHLIcon/></LilaIconWrap><LilaPaymentMethod>DHL International</LilaPaymentMethod></LilaSvgContainer> },
  { title:'Hermes',code:'hermes', value: 1,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><HermesIcon/></LilaIconWrap><LilaPaymentMethod>Hermes International</LilaPaymentMethod></LilaSvgContainer> },

  ]


export const express_shipping_gb = [
  { title:'DHL Express International', code:'dhlExp', value: 0, color:'darkgrey',label: <LilaSvgContainer><LilaIconWrap><DHLIcon/></LilaIconWrap><LilaPaymentMethod>DHL Express International</LilaPaymentMethod></LilaSvgContainer> },
  {title:'GLS EuroExpressParcel',code:'glsExp', value: 1, color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><GLSIcon/></LilaIconWrap><LilaPaymentMethod>EuroExpressParcel</LilaPaymentMethod></LilaSvgContainer>},
  { title:'UPS Express Saver', code:'upsExp', value: 2,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><UPSIcon/></LilaIconWrap><LilaPaymentMethod>UPS Express Saver</LilaPaymentMethod></LilaSvgContainer> },
  {title:'dpd express', code:'dpdExp', value: 3, color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><DPDIcon/></LilaIconWrap><LilaPaymentMethod>dpd express</LilaPaymentMethod></LilaSvgContainer> },
  { title:'FedEx P',code:'fedexExp', value: 4,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><FedExIcon/></LilaIconWrap><LilaPaymentMethod>FedEx International Priority</LilaPaymentMethod></LilaSvgContainer>},

  ]


export const maskRates_01 = {
  mask_01:25


}

export const gutschriftRates =  {
  gut_0:0,
  gut_1:10,
  gut_2:20,
  gut_3:50,
  gut_4:100
}


export const de_dhlExpress = {
  rate_1:14,
  rate_2:15,
  rate_3:25
}



export const dhlExpress_eu = [
  {
    id:'be',
    zone:1,
    rates:[56,67,84]


} ,
  {
    id:'de',
    rates:[14,15,25],
    zone:0


},
  {
    id:'gb',
    zone:3,
    rates:[70,89,128]


},
  {
    id:'it',
    zone:3,
    rates:[70,89,128]


},
  {
    id:'ie',
    zone:4,
    rates:[74,103,152]


},
  {
    id:'nl',
    zone:1,
    rates:[56,67,84]


},
  {
    id:'at',
    zone:2,
    rates:[60,74,102]


},
  {
    id:'pt',
    zone:4,
    rates:[74,103,152]


},
  {
    id:'es',
    zone:3,
    rates:[70,89,128]


},
  {
    id:'se',
    zone:4,
    rates:[74,103,152]


},
  {
    id:'ch',
    zone:5,
    rates:[61,85,126]


},
  {
    id:'cz',
    zone:3,
    rates:[70,89,128]


},]

export const upsExpress_eu = [
  {
    id:'be',
    zone:2,
    rates:[96,115,150]
} ,
  {
    id:'de',
    rates:[32,34,37],
    zone:1
},
  {
    id:'gb',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'it',
    zone:3,
    rates:[129,177,250]
},
  {
    id:'ie',
    zone:4,
    rates:[74,103,152]
},
  {
    id:'nl',
    zone:2,
    rates:[96,115,150]
},
  {
    id:'at',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'pt',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'es',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'se',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'ch',
    zone:5,
    rates:[124,163,227]
},
  {
    id:'cz',
    zone:41,
    rates:[158,227,321]
}]

export const glsExpress_eu = [
  {
    id:'be',
    zone:2,
    rates:[96,115,150]
} ,
  {
    id:'de',
    rates:[32,34,37],
    zone:1
},
  {
    id:'gb',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'it',
    zone:3,
    rates:[129,177,250]
},
  {
    id:'ie',
    zone:4,
    rates:[74,103,152]
},
  {
    id:'nl',
    zone:2,
    rates:[96,115,150]
},
  {
    id:'at',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'pt',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'es',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'se',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'ch',
    zone:5,
    rates:[124,163,227]
},
  {
    id:'cz',
    zone:41,
    rates:[158,227,321]
}]


export const dpdExpress_eu = [
  {
    id:'be',
    zone:2,
    rates:[96,115,150]
} ,
  {
    id:'de',
    rates:[32,34,37],
    zone:1
},
  {
    id:'gb',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'it',
    zone:3,
    rates:[129,177,250]
},
  {
    id:'ie',
    zone:4,
    rates:[74,103,152]
},
  {
    id:'nl',
    zone:2,
    rates:[96,115,150]
},
  {
    id:'at',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'pt',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'es',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'se',
    zone:4,
    rates:[129,177,250]
},
  {
    id:'ch',
    zone:5,
    rates:[124,163,227]
},
  {
    id:'cz',
    zone:41,
    rates:[158,227,321]
}]

export const fedexExpress_eu = [
  {
    id:'be',
    zone:18,
    rates:[89,129,190]
} ,
  {
    id:'de',
    rates:[35,36,39],
    zone:1
},
  {
    id:'gb',
    zone:22,
    rates:[92,134,194]
},
  {
    id:'it',
    zone:22,
    rates:[92,134,194]
},
  {
    id:'ie',
    zone:22,
    rates:[92,134,194]
},
  {
    id:'nl',
    zone:18,
    rates:[89,129,190]
},
  {
    id:'at',
    zone:18,
    rates:[89,129,190]
},
  {
    id:'pt',
    zone:22,
    rates:[92,134,194]
},
  {
    id:'es',
    zone:22,
    rates:[92,134,194]
},
  {
    id:'se',
    zone:22,
    rates:[92,134,194]
},
  {
    id:'ch',
    zone:21,
    rates:[134,185,248]
},
  {
    id:'cz',
    zone:20,
    rates:[126,193,281]
}]

export const de_upsExpress = {
  rate_1:32,
  rate_2:34,
  rate_3:36

}







