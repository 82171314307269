import * as React from 'react'
import Helmet from 'react-helmet'
import SimpleReactLightbox from 'simple-react-lightbox'
import 'react-notifications-component/dist/theme.css'
import { StaticQuery, graphql } from 'gatsby'
import { IntlProvider, addLocaleData } from 'react-intl'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

require('../styles/global.css');

import 'modern-normalize'
import '../styles/normalize'

import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import Footer from "../components/Footer";
import styled from "@emotion/styled";
import LilaHeader from '../components/LilaHeader'
//import { FormattedMessage } from 'react-intl';
import { ContextProviderComponent } from '../components/Context'


const LilaChat = styled.button`
position:fixed;
right:10%;
z-index:999999999;
bottom:10%;
`

//lightbox

const LilaLightbox = styled(SimpleReactLightbox)`
z-index:99999999999;

#SRLLightbox{

z-index:99999999999;
}

`
// Locale data
const enData = require('react-intl/locale-data/en')
const deData = require('react-intl/locale-data/de')



// Translations
const en = require('../locales/en/en.json')
const de = require('../locales/de/de.json')







const messages = { de, en };

addLocaleData([...deData, ...enData])





//favIcons



const apple_touch = require('../../assets/images/favicons/apple-touch-icon.png');
const fav32 = require('../../assets/images/favicons/favicon-32x32.png');
const fav16 = require('../../assets/images/favicons/favicon-16x16.png');
//const fav_saf = require('../../assets/images/favicons/safari-pinned-tab.svg');



const LilaDiv = styled.div`


`

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}



const IndexLayout: React.FC = ({ locale, children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}

    render={(data: StaticQueryProps) => (
<ContextProviderComponent>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <LilaLightbox>
          <LilaDiv>
            <LilaHeader pathContext={locale}/>
          <div id="outer-container">

          <div id={'page-wrap'}>
      <LayoutRoot>
          <ReactNotification />

        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
            { name: 'p:domain_verify', content: '7cefb662d03ddcdd29953e40c1080259' }
            ]}
          link={[
            {
              rel: "apple-touch-icon", sizes: "180x180", href:apple_touch},
            {rel: "icon", type: "image/png", sizes:"32x32", href: fav32},
            {rel:"icon",type:"image/png", sizes:"16x16", href: fav16 },

          ]}
        />



        <LayoutMain >{children}</LayoutMain>
          <Footer></Footer>


      </LayoutRoot>
</div>
      </div>
          </LilaDiv>
          </LilaLightbox>
      </IntlProvider>

</ContextProviderComponent>

    )}
  />
)

export default IndexLayout


/*
*         <SocialIcons>
              <SocialIcon style={{marginRight:'20px' ,height: 32, width: 32}} url="https://www.instagram.com/lilahase_030/"
                          fgColor="lightgrey" bgColor="rgba(255,255,255,0.0)"/>
              <SocialIcon style={{height: 32, width: 32}} url="https://twitter.com/lilahase_cbd"
                          fgColor="lightgrey" bgColor="rgba(255,255,255,0.0)"/>

            </SocialIcons>
*
* */
