import config from '../utils/config'
import * as React from 'react'
import { FormattedMessage } from 'react-intl';
import { standard_pm_methods } from '../constants/paymentMethods'
const cryptoRandomString = require('crypto-random-string');

const regionOptions = [
  { id:0, value: 'be',iso:'be', color:'darkgrey', label: <FormattedMessage id={'region_1'}/>},
  { id:1, value: 'de',iso:'de' ,color:'darkgrey', label: <FormattedMessage id={'region_2'}/> },
  { id:2, value: 'gb',iso:'gb' ,color:'darkgrey', label: <FormattedMessage id={'region_3'}/> },
  { id:3, value: 'it',iso:'it' ,color:'darkgrey', label: <FormattedMessage id={'region_4'}/> },
  { id:4, value: 'ie',iso:'ie' ,color:'darkgrey', label: <FormattedMessage id={'region_5'}/> },
  { id:5, value: 'nl',iso:'nl' ,color:'darkgrey', label: <FormattedMessage id={'region_6'}/> },
  { id:6, value: 'at',iso:'at' ,color:'darkgrey', label: <FormattedMessage id={'region_7'}/> },
  { id:7, value: 'pt',iso:'pt' ,color:'darkgrey', label: <FormattedMessage id={'region_8'}/> },
  { id:8, value: 'es',iso:'es' ,color:'darkgrey', label: <FormattedMessage id={'region_9'}/> },
  { id:9, value: 'se',iso:'se' ,color:'darkgrey', label: <FormattedMessage id={'region_10'}/> },
  { id:10, value: 'ch',iso:'ch' ,color:'darkgrey', label: <FormattedMessage id={'region_11'}/> },
  { id:11, value: 'cz',iso:'cz' ,color:'darkgrey', label: <FormattedMessage id={'region_12'}/> },
]



export function getItemCount(items) {
  let c = 0;
  for(let i=0;i<items.length;i++){
    c+=items[i].quantity
  }
  return c
}

export async function createPaymentIntent(data, extra) {
  const res = await fetch(config.pm.createPaymentIntentUrl, {
    method: 'POST',
    body: JSON.stringify({

            total: ((data.total+data.shipping-data.discount)*100),
        currency:'eur',
        payment_method_types:extra.payment_method_types,
      confirmation:extra.confirmation,
      capture_method:extra.capture_method,
      cus_mail:data._lila_cus_mail,
      cus_city:data._lila_cus_city,
      cus_country:regionOptions[data._lila_region].iso,
      cus_line1: data._lila_cus_street + ' ' +data._lila_cus_nr,
      cus_line2:data._lila_cus_company + ' '+ data._lila_cus_address2,
      cus_code:data._lila_cus_code,
      cus_name:data._lila_cus_name1 + ' '+data._lila_cus_name2,
      phone:data._lila_cus_phone,
      order_id: await cryptoRandomString({length: 10, type: 'url-safe'}),
      shipping_method:data._lila_shipping_solution.title,
      shipping_fees:data.shipping,
      payment_method:standard_pm_methods[data._lila_pay].value,
      tax_items: (((data.total-data.discount)*19)/100),
      tax_shipping: (data.shipping*19)/100,
      tax_total: (((data.total+data.shipping-data.discount)*19)/100),
      discount:data.discount,
      items:await getItemCount(data.cartItems),
      masks:data.cartItems[8].quantity,
      zz_2g:data.cartItems[0].quantity,
      ah_2g:data.cartItems[2].quantity,
      sh_2g:data.cartItems[4].quantity,
      th_2g:data.cartItems[6].quantity,

      zz_5g:data.cartItems[1].quantity,
      ah_5g:data.cartItems[3].quantity,
      sh_5g:data.cartItems[5].quantity,
      th_5g:data.cartItems[7].quantity,



      }
    ),

  });

  const fetchedData = await res.json();
  let raw = await JSON.parse(fetchedData.body)
 // console.log(await raw)

  return await raw
}


