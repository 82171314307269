
import { renderToString } from 'react-dom/server'
import Email from '../templates/email'
import React from 'react'
import config from '../utils/config'
import { _lilaRegions } from '../constants/regions'
import AdminOrderSuccess from '../templates/AdminOrderSuccess'
import NewsletterSuccess from '../templates/NewsletterSuccess'
import Page from '../components/Page'

export async function sendOrderSuccess (confirmData) {
//const htmlString = renderToString(<Email data={confirmData}/>)
  const AWS = require("aws-sdk");

  AWS.config.update({
    accessKeyId: "",
    secretAccessKey: "",
    region: "us-east-1"
});

  const ses = new AWS.SES({ apiVersion: "2010-12-01" });
  const params = {
      Destination: {
        ToAddresses: ["uv101@outlook.de"] // Email address/addresses that you want to send your email
      },
      ConfigurationSetName: "config",
    Message: {
      Body: {
        Html: {
          // HTML Format of the email
          Charset: "UTF-8",
          Data:
            renderToString(<Email data={confirmData}/>)
        },
        Text: {
          Charset: "UTF-8",
          Data: renderToString(<Email data={confirmData}/>)

        }
      },
      Subject: {
        Charset: "UTF-8",
        Data: "Ihre Bestellung"
      }
    },
    Source: "LILA HASE <info@lilahase.com>"
};

  const sendEmail = ses.sendEmail(params).promise();

  sendEmail
    .then(data => {
     // console.log("customer order-success mail submitted to SES", data);
    })
    .catch(error => {
    //  console.log(error);
    });

}

export async function  sendsSelConfirmation (confirmData) {



}





export async function getOrderOverview(data){
  let orderOverview;
  for(let i;i<data.cartItems.length;i++){
    if(data.cartItems[i].quantity>0){
      let item = {

      }
    }
  }
}





export async function sendCusOrderSuccess (data, order_id) {
const iso = _lilaRegions[data._lila_region].iso
  const subjectDE= 'Ihre Bestellung '+order_id
  const subjectEN= 'Your order '+order_id
  let sub;
  if(iso!='de'){
    sub=subjectDE
  }
  else{
    sub = subjectEN
  }
  const res = await fetch(config.mailing.sendCusOrderSuccessUrl, {
    method: 'POST',
    body: JSON.stringify({

        email: await renderToString(<Email orderID={order_id} data={data}></Email>),
        subject:await sub,
        customer_email: data._lila_cus_mail




      }
    ),

  });

  const fetchedData = await res.json();
  let raw = await JSON.parse(fetchedData.body)
  console.log(await raw)

  return await raw
}


export async function  sendAdminOrderSuccess (data, order_id) {
const iso = _lilaRegions[data._lila_region].iso
  const subjectDE= 'LH NEW ORDER '+order_id
const admin = 'info@lilahase.com'
  const res = await fetch(config.mailing.sendAdminOrderSuccessUrl, {
    method: 'POST',
    body: JSON.stringify({

        email: await renderToString(<AdminOrderSuccess orderID={order_id} data={data}></AdminOrderSuccess>),
        subject:subjectDE,
        customer_email: admin




      }
    ),

  });

  const fetchedData = await res.json();
  let raw = await JSON.parse(fetchedData.body)
  console.log(await raw)

  return await raw
}

export async function sendNewsletterSuccess (cus_mail, language) {
//const iso = _lilaRegions[data._lila_region].iso
  const subjectDE= 'LILA NEWS #1';
//const admin = 'info@lilahase.com'
  const res = await fetch(config.mailing.sendNewsletterSuccessUrl, {
    method: 'POST',
    body: JSON.stringify({

        email: await renderToString(<NewsletterSuccess locale={language}/>),
        subject:subjectDE,
        customer_email: cus_mail




      }
    ),

  });

  const fetchedData = await res.json();
  console.log(await fetchedData)
  //let raw = await JSON.parse(fetchedData.body)
 // console.log(await raw)

  return await fetchedData
}




