import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function ZkittlezImg2(props) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "zz-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)




  const {url, altText, title} = props;
  const styles = {


  }

  return (
    <Img
      style={styles}
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt={altText}
      title={title} />
  )
}

export default ZkittlezImg2
