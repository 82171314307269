import ContextConsumer from "../Context"
import ZkittlezSmallCartThumb from '../products/zkittlez/ZkittlezSmallCartThumb'
import React from "react"
import styled from '@emotion/styled'
import { fonts } from '../../styles/variables'
//import PaypalExpress from '../../gateways/PaypalExpress'
import { FormattedMessage } from 'react-intl';

const LilaEmptyCart = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
margin-top:2rem;
font-family:${fonts.bcHairLine};
`


class LilaCartItems extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {}
  }

  setTotal(set, items){
    let val =  0;
    for(let i=0;i<items.length;i++){
      if(items[i].quantity>0){
        val += items[i].amount;
      }
    }
    set({ total: val })
  }



  plusItem(set, data, id) {

    let newCartItems = data.cartItems;
    let newItem = newCartItems[id];
    if (newItem.quantity < 10) {
      newItem.quantity++
      newItem.amount = newItem.price*newItem.quantity;
    } else {
      newItem.isMax = true;
    }
newCartItems[id]=newItem
    console.log(newItem)
    set({ cartItems: newCartItems })
    this.setTotal(set, newCartItems )

  }


  minusItem(set, data, id) {
    let newCartItems = data.cartItems;
    let newItem = newCartItems[id];
    if (newItem.quantity > 1) {
      newItem.quantity--
      newItem.amount = newItem.price*newItem.quantity;

    } else {
      newItem.isMin = true;
    }
newCartItems[id]=newItem
    console.log(newItem)
    set({ cartItems: newCartItems })
    this.setTotal(set, newCartItems )
  }


  removeItem(set, data, id) {
    let newCartItems = data.cartItems;
    let newItem = newCartItems[id];

      newItem.quantity=0
      newItem.amount = 0;


newCartItems[id]=newItem
    console.log(newItem)
    set({ cartItems: newCartItems })
    this.setTotal(set, newCartItems )

  }

  componentDidMount() {

  }

  render() {
    let amt=0;
    return (

      <ContextConsumer>
        {({ data, set }) => (

          <div >
            {

              data.cartItems.map((item) => (

                  item.quantity >=1 &&
                    <ZkittlezSmallCartThumb link={item.productLink} id={item.item} minus={() => this.minusItem(set, data, item.id)}
                                            plus={() => this.plusItem(set, data, item.id)}
                                            removeItem={() => this.removeItem(set,data, item.id)}
                                            quantity={item.quantity}
                                            price={item.price} amount={item.amount} title={item.title} subTitle={item.subTitle}/>

              ))}

            {
              data.total ===0 &&
                <LilaEmptyCart><FormattedMessage id={'cart_00'}/></LilaEmptyCart>
            }

            {

            }


          </div>
        )}
      </ContextConsumer>
    )
  }
}

export default LilaCartItems
