import * as React from 'react'
import styled from '@emotion/styled'
import Headroom from 'react-headroom'
import { push as Menu } from 'react-burger-menu'
import { fallDown as Cart } from 'react-burger-menu'
//import ParticleField from 'react-particles-webgl';
import LocalizedLink from './links/LocalizedLink'
import HeadShake from 'react-reveal/HeadShake';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { FormattedMessage } from 'react-intl';
import ContextConsumer from "./Context"
import Popup from 'reactjs-popup'
import Ripples from 'react-ripples'



import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { colors, fonts } from '../styles/variables'

import { navigate } from '@reach/router'
import LilaCartItems from './cart/CartReader'
import PaypalExpress from '../gateways/PaypalExpress'
import { LilaEye } from '../constants/lilaIcons'
import { getItemCount } from '../functions/stripeFunctions'
import LilaHaseImg from './LilaHaseImg'




const StyledPopup:any = styled(Popup)`

`

const PopIcon = styled.div`
width:10px;

text-align:center;
color:var(--txt);

`

const LilaPopup = styled.div`


position:relative;
z-index:999999999;
.modal > .close {

  cursor: pointer;
  position: absolute;
  display: flex;
  line-height: 20px;
  right: 14px;
  top: 14px;
  font-size: 24px;
  background: var(--bw);
  border-radius: 50%;
    border: 1px solid var(--background);
    z-index:999999999  !important;
width:36px;
height:36px;
justify-content:center;
align-items:center;

}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  //border-radius: 2px;
  font-weight: 600;
  text-transform: uppercase;
  //letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

form {
margin-top:40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
  width:400px;

  @media screen and (max-width:960px){
width:320px;
  margin-left: auto;
margin-right: auto;
  }

}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 2px;
  background: white;
}

input {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 2px;
  background: white;
  border:none;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.modal {

min-height:200px;
width:100%;
padding-left:76px;
padding-top:76px;
  @media screen and (max-width:960px){
  width:100%;
  }

  @media screen and (max-width:627px){
padding-left:5%;
}
}

.popup-content {
background:var(--bg) !important;
width:80% !important;

display:flex;
justify-content:center;
align-items:center;
border:none !important;
padding:0px !important;
border-radius:2px;

@media screen and (max-width:760px){
width:100% !important;
}

}

.popup-overlay{
z-index:999999 !important;
}

.CardDemo{
  @media screen and (max-width:960px){
  width:100% !important;
  }
}

.close-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(135deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
}

`


const config = {
  showCube: false,
  width:'100%',
  dimension: '2D',
  velocity: 0.6,
  boundaryType: 'bounce',
  antialias: false,
  direction: {
    xMin: 0,
    xMax: 2,
    yMin: 0,
    yMax: 2,
    zMin: -1,
    zMax: 1
  },
  lines: {
    colorMode: 'solid',
    color: '#eeeeee',
    transparency: 0.6,
    limitConnections: true,
    maxConnections: 10,
    minDistance: 110,
    visible: true
  },
  particles: {
    colorMode: 'solid',
    color: '#eeeeee',
    transparency: 0.5,
    shape: 'circle',
    boundingBox: 'canvas',
    count: 36,
    minSize: 1,
    maxSize: 18,
    visible: true
  },
  cameraControls: {
    enabled: false,
    enableDamping: false,
    dampingFactor: 0.2,
    enableZoom: false,
    autoRotate: false,
    autoRotateSpeed: 0.3,
    resetCameraFlag: true
  }
}


let navMenuStyles = {

  bmBurgerButton: {
    display:'none'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    right:'1em'
  },
  bmCross: {
    background:'rgba(255,255,255,.0)',

  },
  bmMenuWrap: {
    width:'345px',
    position: 'fixed',
    height: '100%',
    top:0,
    zIndex:99999999,
    overflowY: 'overlay'
  },
  bmMenu: {
    background: 'var(--bg)',
    padding: '0 20px 0',
    fontSize: '1em',
    top:0,
    zIndex:99999999,
    minWidth:'300px !important'

  },
  bmMorphShape: {
    fill: 'white',
    zIndex:99999999

  },
  bmItemList: {
    padding: '8px',
    top:0
  },
  bmItem: {
    display:'flex',
    alignItems:'center'



  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top:0,
    zIndex:99999999

  }
}

let cartMenuStyles = {

  bmBurgerButton: {
    display:'none'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    left:'1em'
  },
  bmCross: {
    background:'rgba(255,255,255,.0)'
  },
  bmMenuWrap: {
    width:'345px',
    position: 'fixed',
    height: '100%',
    top:0,
    zIndex:99999999,
    overflowY: 'overlay'
  },
  bmMenu: {
    background: 'white',
    padding: '0 0px 0',
    fontSize: '1em',
    top:0,
    zIndex:99999999,
    minWidth:'300px !important'

  },
  bmMorphShape: {
    fill: 'white',
    zIndex:99999999

  },
  bmItemList: {
    padding: '0px',
    top:0
  },
  bmItem: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center'



  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top:0,
    zIndex:99999999

  }
}

const StyledMenu = styled(Menu)`
letter-spacing:2px;
background:var(--bg);

.react-slidedown {
transition-duration:.3s;
}

.react-slidedown .closed{

}
.react-slidedown.closed{
display:none !important;

}



`

const StyledCart = styled(Cart)`
letter-spacing:1px;
z-index:9999999999;
background:var(--bg);




.bm-menu{
background:var(--bg) !important;
}

`

const Div = styled.div`
display:block !important;
padding-top:5px;
padding-bottom:5px
`

const LilaBackground = styled.div`
filter:blur(100px);
width:100%;
position:absolute;
height:100%;

 @media screen and (max-width: 1199px) {
padding-left:10%;
padding-right:10%;}


`



const HeadroomWrapper = styled.div`




width:100%;
position:fixed;
  z-index: 9999999;
height:0px;



.headroom {
transition: padding .4s ease;
    -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
display:flex;
padding-left:4.52%;
padding-right:4.52%;
width:100%;
  top: 0;
  left: 0;
  right: 0;

  justify-content:space-between;
align-items:center;
background: var(--headerBg);
display:flex;
transition: background height .4s ease;
border:none;
height:82px;

  @media screen and (max-width:960px){
  height:52px;
  }



}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);



}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;


}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
    transition: transform 200ms ease-in-out;


}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);

}
`

const LilaHeadroom = styled(Headroom)`
height:0px !important;

`

const StyledHeader = styled.div`


`

const CartWrapper = styled.div`


.cls-2{
//transition:all .3s ease;
}
.cls-3{
//transition:all .3s ease;
}
.cls-4{
//transition:all .3s ease;
}
.cls-5{
//transition:all .3s ease;
}



.cls-3{

}



.cls-4{


}

.cls-5-hover{
transition:all .3s ease;

}


:hover {
.cls-3{
fill:var(--cartHoverDark) !important;
}
.cls-4{
fill:var(--hase) !important;
}

.cls-5-hover{
fill:var(--hase) !important;
}
}

cursor:pointer;
width:1.5rem;


     .cls-1 {
                      fill: var(--cartDark);
                    }

                      .cls-2 {
                      fill: var(--cartLight);
                    }
`

const MenuIconWrapper = styled.div`
cursor:pointer;

.cls-3{
//transition:all .3s ease;
}
.cls-2{
//transition:all .3s ease;
}
.cls-4{
//transition:all .3s ease;
}

:hover {
.cls-3{
fill:var(--hase) !important;
}
.cls-4{
fill:var(--hase) !important;

}

}

width:1.1rem;
.cls-1{fill:rgba(255,255,255,0);stroke:var(--menuLight);stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}
.cls-3{fill:rgba(255,255,255,0);stroke:var(--menuLight);stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}

.cls-2{fill:var(--menuDark);}
.cls-4{fill:var(--menuDark);}


`




const DivCenter =styled.div`



transition:all 1s ease;
height:100%;
width:70%;
z-index:-3;


  @media screen and (max-width: 600px) {
position:absolute;
width:100% !important;
}
`



const LilaHaseWrapper = styled.div`
width:100%;
display:flex;
justify-content:center;
padding-top:32.3%;
padding-bottom:1.5rem;

`


const LilaHase_2 = styled.div`
width:100%;
display:flex;
align-items:center;
height:50px;




`

const D = styled.div`
display:flex;
height:100%;
align-items:center;
`



const LilaTab = styled.div`
font-family:${fonts.bcBook};
padding-left:51px;


transition: opacity .4s;
height:2.4rem;
margin-bottom:0.3rem;
div{
font-size:0.97rem;

color:var(--txt);
}
display:flex;
align-items:center;
.plus-minus-toggle {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  width: 10px;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(90deg);
    }
    &:before {
      transform: rotate(180deg);
    }
  }
}
`

const LilaTabHover = styled.div`
font-family:${fonts.bcBook};
padding-left:51px;

:hover{
text-decoration:underline;
text-decoration-color:var(--hase);
}


transition: opacity .4s;
height:2.4rem;
margin-bottom:0.3rem;
div{
font-size:0.97rem;

color:var(--txt);
}
display:flex;
align-items:center;
.plus-minus-toggle {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  width: 11px;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 11px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(90deg);
    }
    &:before {
      transform: rotate(180deg);
    }
  }
}
`

const StyledP = styled.div`
cursor:pointer;
transition: all 1s ease;
display:inline-block;
line-height:10px;


:after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: ${colors.fontDark};
  transition: 300ms;
}

:hover:after {
  width: 100%;
}



`

const LilaLink = styled(LocalizedLink)`
color:var(--txt);
width:100%;


:hover{
text-decoration-color:var(--hase);
text-decoration:underline;
}
`

const LilaSpace = styled.div`
display:flex;
justify-content:center;
align-items:center;
margin-bottom:3rem;
`

const PlusMinus = styled.div`
margin-right:1.2rem;
`

const LilaIndent = styled.div`
margin-right:1.2rem;
    width: 11px;


`

const Lh = styled.div`
width:126px;

.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}
`

const Lh_2 = styled.div`
width:226px;

.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}
`

const LilaSymbol = styled.div`
.cls-1{fill:var(--hase);}

width:64px;
margin-left:7px;

.rotate {
  animation: rotation 7s infinite linear;

}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.67);
  }
  to {
    transform: rotate(359deg) scale(0.67);
  }
}
`

const Gutschrift = styled.div`
margin-top:0.7rem;
font-family:${fonts.bcExtraLight};
font-size:1.5rem;
`

const LilaRowsWrap = styled.div`
width:100%;
display:flex;
margin-bottom:3rem;
margin-top:1rem;
@media screen and (max-width:627px){
display:block;
}
`

const LilaRowsWrap_2 = styled.div`
width:100%;
display:flex;
margin-bottom:5rem;
margin-top:1rem;
`

const GutschriftSub = styled.div`
font-family:${fonts.bcExtraLight};
font-size:1rem;
padding-left:2px;

`
const GutschriftSub_2 = styled.div`
font-family:${fonts.bcExtraLight};
font-size:0.8rem;
padding-left:2px;

`


const Vert = styled.p`
margin-bottom:0rem;
margin-left:1rem;
margin-right:1rem;
`

const LilaTotalDiv = styled.div`
width:82px;
`

const LilAmount = styled.div`
display:flex;
width:36px;
justify-content:flex-end;

`
const LilacAmount = styled.div`
display:flex;
width:36px;
justify-content:flex-end;
color:var(--hase);

`
const LilaMinus = styled.div`
color:var(--hase);

`

const InstaWrap= styled.a`
cursor:pointer;

width:16px;
height:16px;

:hover{

.cls-1{fill:var(--hase);}
.cls-2{fill:var(--hase);}
.cls-3{fill:var(--hase);}

}

.cls-1{

fill:var(--icon2);
transition:all .3s ease;

}
.cls-2{

fill:var(--icon2);
transition:all .3s ease;

}
.cls-3{

fill:var(--icon2);
transition:all .3s ease;

}
`


const MatchWrap= styled.div`
display:flex;
height:100%;
align-items:center;


`

const TwitterWrap= styled.a`
cursor:pointer;
width:16px;
height:16px;

:hover{

.cls-1{fill:var(--hase);}

}

.cls-1{

fill:var(--icon2);
transition:all .3s ease;

}`
const LilaCoffee = styled.div`
display:flex;
height:100%;
align-items:center;
`
const A = styled.a`
display: flex;
align-items: flex-end;
`

const MenuNav = styled.div`
position:absolute;
display:flex;
justify-content:space-between;
align-items:center;
bottom:0;
left:0;
height:45px;
padding-left:28px;
padding-right:28px;
background:var(--bw);
width:345px;

`

const MenuNavBackground = styled.div`
position:absolute;
display:flex;
justify-content:space-between;
align-items:center;
bottom:0;
left:0;
height:45px;
padding-left:28px;
padding-right:28px;
background:var(--bg);
width:345px;

`

const LilaFit = styled.div`
height:4rem;

`


const CloseMenu = styled.div`
 :hover{
 text-decoration:underline;
 }
cursor:pointer;
position:absolute;
z-index:9999999999;
display:flex;
justify-content:center;
align-items:center;
right:0;
top:0px;
width:100%;
height:45px;
background: var(--bg);
transition:all .4 ease;

.close-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 2px;
  position: relative;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(135deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
}
`

const CloseCartWrap = styled.div`
height:45px;
position:absolute;
width:100%;
right:0;
background:var(--bg);
z-index:9999999;



`
const CloseCart = styled.div`
cursor:pointer;
position:absolute;
display:flex;
justify-content:center;
align-items:center;
right:0;
top:0px;
width:100%;
height:45px;
background: var(--bw);
transition:all .4 ease;

 :hover{
 text-decoration:underline;
 }



.close-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 2px;
  position: relative;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(135deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
}
`


const CloseTxt = styled.div`
font-family:${fonts.bcHairLine};
font-size: 1em !important;
letter-spacing:0px;
width:100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
background:var(--bw);

`

const LilaCmc = styled(ContextConsumer)`

`

const LilaPadding = styled.div`
padding-top:45px;
`

const LilaViewCart = styled.div`
 font-size:0.7rem !important;
color:var(--txt) !important;
margin-left:4.3rem;
`

const LilaTotal = styled.div`
margin-top:0.44rem;
font-size: 0.75rem;
width:100%;
padding-left:18px;
display:flex;
padding-right:18px;
`
const LilaSubTotal = styled.div`
margin-top:2rem;
margin-bottom:0.44rem;

font-size: 0.75rem;
width:100%;
padding-left:18px;
display:flex;
padding-right:18px;
`

const LilaTaxInfo = styled.div`
font-size:0.6rem;
letter-spacing:1px;
opacity:.3;
font-size: 0.65rem;
display:flex;
margin-left:4.3rem;

`

const LilaInclusive = styled.div`
display:flex;
`

const LilaExpress = styled.div`
justify-content:center !important;
//padding-left:18px;

`

const LilaGutschrift = styled.div`
justify-content:flex-start !important;
padding-left:18px;
padding-top:4px;
padding-bottom:4px;
background:var(--bw);
font-size: 0.75rem;
display:flex;
align-items:center;



`
const LilaGutschriftDetails = styled.div`
display:flex;
align-items:center;
margin-left:0.3rem;
cursor:pointer;



`

const StyledLilaEye = styled.div`
width:20px;
margin-left:0.3rem;
height:15.7px;

color:var(--hase);
  .cls-1 {
        fill: var(--icon2);
      }
       .cls-2 {
        fill: var(--icon2);
        transition:all .3s ease;
      }

      :hover{
  .cls-2 {
        fill: var(--hase);
      }
      }
`


const LilaRows = styled.div`
margin-left:1rem;

@media screen and (max-width:627px){
margin-left:0rem;
margin-top:1rem;
}


`

const LilaHairline = styled.div`
display:flex;
font-family:${fonts.bcHairLine};


`
const LilaEuro = styled.div`
width:60px;
display:flex;
justify-content:flex-end;
`

const LilaEuro_2 = styled.div`
width:60px;
display:flex;
justify-content:flex-end;
`

const LilaBook = styled.div`
display:flex;
font-family:${fonts.bcBook};

`

const LilaExtraLight = styled.div`
display:flex;
font-family:${fonts.bcExtraLight};

`

const LilaSemiBold = styled.div`
display:flex;
font-family:${fonts.bcSemiBold};

`


const LilaArrow = styled.div`
width:45px;
display:flex;
justify-content:flex-end;
`

const LilaDiscount = styled.div`
width:90px;
display:flex;
justify-content:flex-end;
`

const LilaDelivery = styled.div`
justify-content:flex-start !important;
margin-top:1.9rem;
padding-left:18px;
padding-top:4px;
padding-bottom:4px;
background:var(--bw);
font-family:${fonts.bcHairLine};
font-size:.87rem;


`
const LilaExpressDelivery = styled.div`
justify-content:flex-start !important;
padding-left:18px;
padding-top:4px;
padding-bottom:4px;
background:var(--bw);
border-top:1px solid var(--bg);
font-family:${fonts.bcHairLine};
font-size:.87rem;


`

const Express = styled.div`
text-align:center;
margin-top:3.2rem;
margin-bottom:0.9rem;
display:block !important;
font-size:0.97rem;
font-family:${fonts.bcExtraLight};
`

const LilaLine = styled.div`
font-family:${fonts.bcExtraLight};

margin-top:1.2rem;
margin-bottom:5rem;
:before, :after {
            content: "";
            flex: 1 1;
            border-bottom: 1px solid var(--border);
            margin: auto;
        }

`
const LilaOther = styled.div`
margin-left:1.2rem;
margin-right:1.2rem;
`

const LilaProceedFade = styled.div`
letter-spacing:1px;
z-index:99999999999;
           // border-bottom: 1px solid var(--txt);
       position:fixed;
       bottom:0;
            //border-top: 1px solid ;
background:none;
            width:345px;

            height:45px;
            color:${colors.fontDark};



`
const LilaProceed = styled(Ripples)`



`

const LilaRipples = styled(LocalizedLink)`
background:var(--haase);
width:100%;
heifth:100%;
letter-spacing:1px;
z-index:99999999999;
position:relative;
           // border-bottom: 1px solid var(--txt);
            //border-top: 1px solid ;
background:var(--haase);
            width:345px;

            height:45px;
            color:${colors.fontDark};
display:flex;
justify-content:center;
align-items:center;
`



class LilaHeader extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
      gutschriftOpen:false,
      menuOpen:false,
    cartOpen:false,
      opacity:1,
      productsTabOpen:true,
      merchTabOpen:false, //'plus-minus-toggle collapsed'
      moreTabsOpen:false
    }
    this.openPopup=this.openPopup.bind(this);
    this.closePopup=this.closePopup.bind(this);
    this.openMenu = this.openMenu.bind(this)
    this.openCart = this.openCart.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.closeCart = this.closeCart.bind(this)
    this.handleCartState=this.handleCartState.bind(this)
    this.handleMenuState=this.handleMenuState.bind(this)
    this.toggleProductsTab=this.toggleProductsTab.bind(this)
    this.toggleMerchTab=this.toggleMerchTab.bind(this)
    this.toggleMoreTabs=this.toggleMoreTabs.bind(this)
  }

  openPopup(){
    this.setState({
      gutschriftOpen:true
    })
  }
  closePopup(){
    this.setState({
      gutschriftOpen:false
    })
  }


  toggleProductsTab(set, data){
    set({productsTabOpen:!data.productsTabOpen})
    this.setState({
      productsTabOpen:!this.state.productsTabOpen
    })
  }

  toggleMerchTab(set){
    set({merchTabOpen:!this.state.merchTabOpen})
    this.setState({
      merchTabOpen:!this.state.merchTabOpen
    })
  }
  toggleMoreTabs(set, data){
    set({moreTabsOpen:!data.moreTabsOpen})
    this.setState({
      moreTabsOpen:!this.state.moreTabsOpen
    })
  }

  handleMenuState (state, set) {
    set({menuOpen: state.isOpen})
    this.setState({
      menuOpen:state.isOpen
    })
  }
  handleCartState (state, set) {
    set({ cartOpen: state.isOpen})
this.setState({
  cartOpen:state.isOpen
})
  }


openCart(set){
  set({ cartOpen: true})
  this.setState({
    cartOpen:true
  })
}
openMenu(set){
  set({ menuOpen: true})
  this.setState({
    menuOpen:true
  })
}

closeCart(set){
  set({ cartOpen: false})
  this.setState({
    cartOpen:false
  })

}
closeMenu(set){
  set({ menuOpen: false})
  this.setState({
    menuOpen:false
  })

}

amountCounter(cartItems){
    let amt = 0;
    for(let i=0;i<cartItems.length;i++){

      amt += cartItems[i].amount;
    }
    return amt
}

  componentDidMount() {
  //  console.log(this.props.locale)
    setTimeout(() => {
      this.setState(prevState => ({
        opacity: 0
      }));
    }, 8000);

  }

  selectDE(){

    navigate(
      "/",
      { replace: true }      )

  }

  selectEN(){

    navigate('/en',  { replace: true });
  }


  render() {

    return(
      <LilaCmc>
        {({ data, set }) => (

        <StyledHeader id={'component'} >

          <HeadroomWrapper>

            <LilaHeadroom pinStart={0} downTolerance={0} upTolerance={0} disable={false} >
     <MenuIconWrapper onClick={()=>this.openMenu(set)}>

       <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 26">
         <defs>
         </defs>
         <path className="cls-1"
               d="M695.88,376h-26a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h26a2,2,0,0,1,2,2h0A2,2,0,0,1,695.88,376Z"
               transform="translate(-666.88 -371)"/>
         <path className="cls-3"
               d="M695.88,386h-26a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h26a2,2,0,0,1,2,2h0A2,2,0,0,1,695.88,386Z"
               transform="translate(-666.88 -371)"/>
         <path className="cls-1"
               d="M695.88,396h-26a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h26a2,2,0,0,1,2,2h0A2,2,0,0,1,695.88,396Z"
               transform="translate(-666.88 -371)"/>
         <path className="cls-2" d="M669.88,377h26a3,3,0,1,0,0-6h-26a3,3,0,0,0,0,6Z"
               transform="translate(-666.88 -371)"/>
         <path className="cls-4" d="M695.88,381h-26a3,3,0,0,0,0,6h26a3,3,0,1,0,0-6Z"
               transform="translate(-666.88 -371)"/>
         <path className="cls-2" d="M695.88,391h-26a3,3,0,0,0,0,6h26a3,3,0,1,0,0-6Z"
               transform="translate(-666.88 -371)"/>
       </svg>
     </MenuIconWrapper>
                <DivCenter ></DivCenter>


              <CartWrapper onClick={()=>this.openCart(set)}>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.05 27.97">
                  <defs>


                  </defs>
                  <g>
                    <path className="cls-3" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsDark)':'rgba(255,255,255,0)'}}
                          d="M690.23,361.78a.48.48,0,0,0,.19.39,4,4,0,0,0,2.38.76,5.63,5.63,0,0,0,1.15-.13l-3.58,3.58a.51.51,0,0,0,0,.71.54.54,0,0,0,.36.14.52.52,0,0,0,.35-.14l3.58-3.58a5.63,5.63,0,0,0-.13,1.15,4.09,4.09,0,0,0,.76,2.39.52.52,0,0,0,.39.18.53.53,0,0,0,.39-.18,4.16,4.16,0,0,0,.74-2.12,8.79,8.79,0,0,0,.68.79,3.81,3.81,0,0,0,2.57,1.34h.08a.49.49,0,0,0,.46-.45,3.66,3.66,0,0,0-1.34-2.65,10.92,10.92,0,0,0-1.16-1,12.38,12.38,0,0,0,2.58.31,4.27,4.27,0,0,0,3.39-1.19.51.51,0,0,0,0-.62,4.27,4.27,0,0,0-3.39-1.19,11.87,11.87,0,0,0-1.76.16,11.53,11.53,0,0,0,1.35-1.13,4.24,4.24,0,0,0,1.56-3.24.51.51,0,0,0-.44-.44c-.06,0-1.52-.16-3.24,1.56a11.53,11.53,0,0,0-1.13,1.35,10.57,10.57,0,0,0,.16-1.76,4.27,4.27,0,0,0-1.19-3.39.51.51,0,0,0-.62,0,4.27,4.27,0,0,0-1.19,3.39,12.56,12.56,0,0,0,.31,2.59,10.12,10.12,0,0,0-1-1.17,3.57,3.57,0,0,0-2.64-1.33.49.49,0,0,0-.46.46,3.62,3.62,0,0,0,1.33,2.64,7.43,7.43,0,0,0,.79.68,4.05,4.05,0,0,0-2.11.74A.51.51,0,0,0,690.23,361.78Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className={(getItemCount(data.cartItems)>0)?'cls-5-hover':'cls-5'} style={{fill:'rgba(255,255,255,0)'}}
                          d="M690.23,361.78a.48.48,0,0,0,.19.39,4,4,0,0,0,2.38.76,5.63,5.63,0,0,0,1.15-.13l-3.58,3.58a.51.51,0,0,0,0,.71.54.54,0,0,0,.36.14.52.52,0,0,0,.35-.14l3.58-3.58a5.63,5.63,0,0,0-.13,1.15,4.09,4.09,0,0,0,.76,2.39.52.52,0,0,0,.39.18.53.53,0,0,0,.39-.18,4.16,4.16,0,0,0,.74-2.12,8.79,8.79,0,0,0,.68.79,3.81,3.81,0,0,0,2.57,1.34h.08a.49.49,0,0,0,.46-.45,3.66,3.66,0,0,0-1.34-2.65,10.92,10.92,0,0,0-1.16-1,12.38,12.38,0,0,0,2.58.31,4.27,4.27,0,0,0,3.39-1.19.51.51,0,0,0,0-.62,4.27,4.27,0,0,0-3.39-1.19,11.87,11.87,0,0,0-1.76.16,11.53,11.53,0,0,0,1.35-1.13,4.24,4.24,0,0,0,1.56-3.24.51.51,0,0,0-.44-.44c-.06,0-1.52-.16-3.24,1.56a11.53,11.53,0,0,0-1.13,1.35,10.57,10.57,0,0,0,.16-1.76,4.27,4.27,0,0,0-1.19-3.39.51.51,0,0,0-.62,0,4.27,4.27,0,0,0-1.19,3.39,12.56,12.56,0,0,0,.31,2.59,10.12,10.12,0,0,0-1-1.17,3.57,3.57,0,0,0-2.64-1.33.49.49,0,0,0-.46.46,3.62,3.62,0,0,0,1.33,2.64,7.43,7.43,0,0,0,.79.68,4.05,4.05,0,0,0-2.11.74A.51.51,0,0,0,690.23,361.78Z"
                          transform="translate(-680.14 -353.27)"/>
                    <circle className="cls-1" cx="11" cy="24.97" r="3"/>
                    <circle className="cls-1" cx="21" cy="24.97" r="3"/>
                    <path className="cls-1"
                          d="M688.52,375.23a.48.48,0,0,1-.48-.44l-.07-.56h15.67a.49.49,0,0,0,.49-.41l2-11a.48.48,0,0,0-.11-.41.49.49,0,0,0-.38-.18h-.58a1.3,1.3,0,0,1-.25.48,5.08,5.08,0,0,1-4,1.57,3.89,3.89,0,0,1,.77,2.39,1.48,1.48,0,0,1-1.38,1.38h-.15a4.45,4.45,0,0,1-2.75-1.16,4.42,4.42,0,0,1-.47.78,1.5,1.5,0,0,1-2.33,0,4.77,4.77,0,0,1-.83-1.77l-1.89,1.89a1.5,1.5,0,0,1-2.56-1.06,1.47,1.47,0,0,1,.44-1.06l1.89-1.89a4.75,4.75,0,0,1-1.76-.82,1.44,1.44,0,0,1-.49-.73h-2.67l-.51-4.55a.49.49,0,0,0-.49-.45h-5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h4.55L687,374.9a1.49,1.49,0,0,0,.77,1.12A4.1,4.1,0,0,1,688.52,375.23Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-1"
                          d="M704.64,375.23h-.88a4.38,4.38,0,0,1,.82,1h.06a.5.5,0,0,0,.5-.5A.51.51,0,0,0,704.64,375.23Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-1" d="M693.76,375.23a4.38,4.38,0,0,1,.82,1h3.11a4.17,4.17,0,0,1,.83-1Z"
                          transform="translate(-680.14 -353.27)"/>
                  </g>
                  <g>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M695.69,362.27a.55.55,0,0,1-.36-.15.5.5,0,0,1-.1-.57,18.78,18.78,0,0,1,2.93-4.38,4.34,4.34,0,0,1,3.24-1.56.51.51,0,0,1,.44.44,4.24,4.24,0,0,1-1.56,3.24h0a18.43,18.43,0,0,1-4.38,2.93A.54.54,0,0,1,695.69,362.27Zm5.09-5.6a4.27,4.27,0,0,0-1.91,1.21,13.84,13.84,0,0,0-2,2.66,14.21,14.21,0,0,0,2.66-2h0A4.23,4.23,0,0,0,700.78,356.67Zm-.85,2.27h0Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M700.69,363.27a18.38,18.38,0,0,1-5.17-1,.5.5,0,0,1-.33-.47.51.51,0,0,1,.33-.48,18.51,18.51,0,0,1,5.17-1,4.27,4.27,0,0,1,3.39,1.19.51.51,0,0,1,0,.62A4.31,4.31,0,0,1,700.69,363.27Zm-3.27-1.5a14.33,14.33,0,0,0,3.27.5,4.29,4.29,0,0,0,2.2-.5,4.29,4.29,0,0,0-2.2-.5A13.8,13.8,0,0,0,697.42,361.77Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M700.07,367a3.81,3.81,0,0,1-2.57-1.34h0a16.37,16.37,0,0,1-2.44-3.53.5.5,0,0,1,.1-.58.49.49,0,0,1,.57-.09,15.75,15.75,0,0,1,3.54,2.43,3.62,3.62,0,0,1,1.33,2.65.49.49,0,0,1-.46.46Zm-3.16-3.7a10,10,0,0,0,1.3,1.65h0a4.15,4.15,0,0,0,1.23.89,4,4,0,0,0-.88-1.24A10.85,10.85,0,0,0,696.91,363.34Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M695.69,367.22a.49.49,0,0,1-.39-.19,4.05,4.05,0,0,1-.76-2.39,10.11,10.11,0,0,1,.68-3.06.52.52,0,0,1,.93,0,9.8,9.8,0,0,1,.68,3.06,4.08,4.08,0,0,1-.75,2.39A.51.51,0,0,1,695.69,367.22Zm0-3.74a5.2,5.2,0,0,0-.15,1.16,2.91,2.91,0,0,0,.15.95,3.28,3.28,0,0,0,.14-.95A5.83,5.83,0,0,0,695.69,363.48Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M695.69,362.27a.51.51,0,0,1-.47-.34,17.9,17.9,0,0,1-1-5.16,4.29,4.29,0,0,1,1.19-3.4.51.51,0,0,1,.62,0,4.33,4.33,0,0,1,1.19,3.4,18.27,18.27,0,0,1-1,5.16A.51.51,0,0,1,695.69,362.27Zm0-7.71a4.31,4.31,0,0,0-.5,2.21,13.79,13.79,0,0,0,.5,3.26,14.32,14.32,0,0,0,.5-3.26A4.31,4.31,0,0,0,695.69,354.56Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M695.51,362.44a.54.54,0,0,1-.22-.05,15.94,15.94,0,0,1-3.54-2.44,3.62,3.62,0,0,1-1.33-2.64.49.49,0,0,1,.46-.46,3.67,3.67,0,0,1,2.64,1.34,16.37,16.37,0,0,1,2.44,3.53.51.51,0,0,1-.1.58A.5.5,0,0,1,695.51,362.44ZM691.58,358a4.23,4.23,0,0,0,.88,1.24h0a11.44,11.44,0,0,0,1.65,1.29,10.85,10.85,0,0,0-1.3-1.65A4.08,4.08,0,0,0,691.58,358Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M692.81,362.91a4.12,4.12,0,0,1-2.39-.75.53.53,0,0,1-.18-.39.5.5,0,0,1,.18-.39,4,4,0,0,1,2.39-.76,9.8,9.8,0,0,1,3.06.68.5.5,0,0,1,0,.93A9.8,9.8,0,0,1,692.81,362.91Zm-.95-1.14a4.67,4.67,0,0,0,2.11,0A4.4,4.4,0,0,0,691.86,361.77Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-4" style={{fill:(getItemCount(data.cartItems)>0)?'var(--cartItemsLight)':'rgba(255,255,255,0)'}}
                          d="M690.74,367.22a.51.51,0,0,1-.36-.15.51.51,0,0,1,0-.71l5-4.95a.51.51,0,0,1,.71,0,.5.5,0,0,1,0,.71l-4.95,4.95A.49.49,0,0,1,690.74,367.22Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-2"
                          d="M691.15,381.22a3,3,0,1,1,3-3A3,3,0,0,1,691.15,381.22Zm0-5a2,2,0,1,0,2,2A2,2,0,0,0,691.15,376.22Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-2"
                          d="M701.15,381.22a3,3,0,1,1,3-3A3,3,0,0,1,701.15,381.22Zm0-5a2,2,0,1,0,2,2A2,2,0,0,0,701.15,376.22Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-2"
                          d="M703.65,374.22H687.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h15.8l1.85-10h-.39a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h1a.48.48,0,0,1,.38.18.47.47,0,0,1,.11.41l-2,11A.51.51,0,0,1,703.65,374.22Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-2"
                          d="M689.69,363.22h-3.48a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h3.48a.5.5,0,0,1,.5.5A.51.51,0,0,1,689.69,363.22Z"
                          transform="translate(-680.14 -353.27)"/>
                    <path className="cls-2"
                          d="M704.65,376.22H688.54a1.51,1.51,0,0,1-1.49-1.34l-1.85-16.66h-4.55a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h5a.49.49,0,0,1,.49.44l1.9,17.11a.52.52,0,0,0,.5.45h16.11a.5.5,0,0,1,.5.5A.51.51,0,0,1,704.65,376.22Z"
                          transform="translate(-680.14 -353.27)"/>
                  </g>
                </svg>

              </CartWrapper>


            </LilaHeadroom>
          </HeadroomWrapper>

          <StyledMenu
            disableAutoFocus
            customBurgerIcon={ false }
            onStateChange={(state) => this.handleMenuState(state, set)}

            styles={navMenuStyles}
            isOpen={this.state.menuOpen} pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } >
            <Fade spy={this.state.menuOpen} top cascade>

            <CloseMenu onClick={()=>this.closeMenu(set)}>

              <CloseTxt >close menu</CloseTxt>
            </CloseMenu>
            </Fade>


            <HeadShake delay={1200} spy={this.state.menuOpen}>
              <Zoom spy={this.state.menuOpen}>
            <LilaHaseWrapper onClick={()=>this.closeMenu(set)}>

<LilaHaseImg/>

            </LilaHaseWrapper>
              </Zoom>
            </HeadShake>
            <Fade spy={this.state.menuOpen} top cascade>

            <LilaSpace onClick={()=>this.closeMenu(set)}>
              <Lh >
                <LocalizedLink to={'/'}>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
                  <defs>
                  </defs>
                  <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1"
                        d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
                        transform="translate(-14.76 -19.7)"/>
                  <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
                  <polygon className="cls-3"
                           points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
                  <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
                  <polygon className="cls-3"
                           points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
                </svg>
                </LocalizedLink>
              </Lh>

            </LilaSpace>
            </Fade>
           <LilaLink to={'/'}>
             <Fade spy={this.state.menuOpen} top cascade>
             <LilaTab onClick={()=>this.closeMenu(set)}>
              <LilaIndent/>
              <D><FormattedMessage id={'menu_tab_1'}/></D>
            </LilaTab>
             </Fade>

           </LilaLink>

            <Fade style={{opacity:!data.productsTabOpen?0.1:1}} spy={this.state.menuOpen} top  >
              <LilaTabHover style={{opacity:data.productsTabOpen?.3:1}} onClick={()=>this.toggleProductsTab(set, data)}>
                <PlusMinus className={data.productsTabOpen?'plus-minus-toggle':'plus-minus-toggle collapsed'}></PlusMinus> <D><FormattedMessage id={'menu_tab_2'}/></D></LilaTabHover>
            </Fade>
            <SlideDown   closed={!data.productsTabOpen}>
              <Fade spy={this.state.menuOpen} top>
                <LocalizedLink to={'/cbd-blueten'}>
                  <LilaTab>
                    <LilaIndent/>

                    <D><FormattedMessage id={'menu_tab_2_1'}/></D>
                  </LilaTab>
                </LocalizedLink>
              </Fade>

            </SlideDown>

            <Fade  spy={this.state.menuOpen} top >
<LocalizedLink to={'/'}>
            <LilaTab onClick={()=>this.closeMenu(set)} >
              <LilaIndent/>
              <D >merch</D>
            </LilaTab>
</LocalizedLink>
            </Fade>


            <Fade spy={this.state.menuOpen} top  >
            <LilaTabHover style={{opacity:data.moreTabsOpen?.3:1}} onClick={()=>this.toggleMoreTabs(set, data)}>
            <PlusMinus className={data.moreTabsOpen?'plus-minus-toggle':'plus-minus-toggle collapsed'}></PlusMinus>
              {data.moreTabsOpen === false &&
              <D> <FormattedMessage id={'menu_tab_4'}/></D>
              }
              {data.moreTabsOpen === true &&
              <D> <FormattedMessage id={'menu_tab_4_0'}/></D>
              }
            </LilaTabHover>
            </Fade>

            <SlideDown   closed={!data.moreTabsOpen}>
<div>
  <Fade spy={this.state.menuOpen} top >
    <LocalizedLink to={'/agb'}>

      <LilaTab>
        <LilaIndent/>
        <D ><FormattedMessage id={'menu_tab_4_2'}/></D>
      </LilaTab>
    </LocalizedLink>

    <LocalizedLink to={'/impressum'}>

  <LilaTab>
              <LilaIndent/>

            <D ><FormattedMessage id={'menu_tab_4_1'}/></D>
            </LilaTab>
    </LocalizedLink>


    <LocalizedLink  to={'/zahlungsarten'}>

    <LilaTab>
              <LilaIndent/>

              <D><FormattedMessage id={'menu_tab_4_3'}/></D>
            </LilaTab>
    </LocalizedLink>
      <LocalizedLink  to={'/versand'}>
            <LilaTab>
              <LilaIndent/>
                <D><FormattedMessage id={'menu_tab_4_4'}/></D>
            </LilaTab>
      </LocalizedLink>

    <LocalizedLink  to={'/datenschutz'}>


    <LilaTab>
                    <LilaIndent/>
                  <D ><FormattedMessage id={'menu_tab_4_5'}/></D>
                  </LilaTab>
    </LocalizedLink>

  </Fade>
</div>
            </SlideDown>
            <Fade spy={this.state.menuOpen} top >
            <LilaTab>


              <LilaIndent/>
              <StyledP onClick={this.selectEN}>en</StyledP>
              <Vert>|</Vert>

              <StyledP onClick={this.selectDE}>de</StyledP>


            </LilaTab>
            </Fade>

            <LilaFit></LilaFit>


            <MenuNavBackground/>
            <MenuNav>
              <LilaCoffee title="Buy us a coffee">

              </LilaCoffee>
<InstaWrap target="_blank" href={'https://instagram.com/lilahase_eu'}>

  <svg id="Bold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512.01"><path className="cls-1" d="M256.09,124.54A131.38,131.38,0,1,0,387.46,255.91,131.36,131.36,0,0,0,256.09,124.54Zm0,216.64a85.27,85.27,0,1,1,85.26-85.27A85.24,85.24,0,0,1,256.09,341.18Z" transform="translate(0 -0.01)"/><path className="cls-2" d="M361.56,1.62c-47.11-2.2-163.78-2.09-210.93,0-41.42,1.94-78,12-107.43,41.41C-6,92.27.26,158.61.26,255.91c0,99.59-5.55,164.4,42.94,212.89,49.43,49.41,116.74,42.94,212.89,42.94,98.64,0,132.69.07,167.57-13.44,47.42-18.41,83.22-60.8,86.72-136.93,2.22-47.13,2.09-163.78,0-210.93C506.15,60.57,457.92,6.06,361.56,1.62Zm74.56,434.6c-32.28,32.28-77.06,29.4-180.65,29.4C148.8,465.62,106,467.2,74.82,435.9c-35.95-35.77-29.44-93.22-29.44-180.33,0-117.86-12.1-202.75,106.19-208.81,27.18-1,35.18-1.28,103.6-1.28l1,.64c113.68,0,202.88-11.9,208.23,106.37,1.22,27,1.5,35.09,1.5,103.4,0,105.43,2,148.46-29.74,180.33Z" transform="translate(0 -0.01)"/><circle className="cls-3"  cx="392.67" cy="119.35" r="30.7"/></svg>
</InstaWrap>
            <TwitterWrap target="_blank" href={'https://twitter.com/lilahase_cbd'}>
              <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 416">
                <defs>
                </defs>
                <path className="cls-1"
                      d="M512,97.25a218.64,218.64,0,0,1-60.48,16.57,104.36,104.36,0,0,0,46.18-58,210,210,0,0,1-66.56,25.41A105,105,0,0,0,249.57,153,108,108,0,0,0,252,176.93C164.74,172.67,87.52,130.85,35.65,67.14A105,105,0,0,0,67.9,207.42,103.69,103.69,0,0,1,20.48,194.5v1.15a105.43,105.43,0,0,0,84.1,103.13,104.65,104.65,0,0,1-27.52,3.46,92.77,92.77,0,0,1-19.88-1.79c13.6,41.57,52.2,72.13,98.08,73.12A210.93,210.93,0,0,1,25.12,418.34,197.72,197.72,0,0,1,0,416.9,295.54,295.54,0,0,0,161,464c193.16,0,298.76-160,298.76-298.69,0-4.64-.16-9.12-.39-13.57A209.29,209.29,0,0,0,512,97.25Z"
                      transform="translate(0 -48)"/>
              </svg>
            </TwitterWrap>
              <MatchWrap>

              </MatchWrap>
            </MenuNav>
          </StyledMenu>

    <StyledCart
            styles={cartMenuStyles}
            disableAutoFocus
            customBurgerIcon={ false }
            onStateChange={(state) => this.handleCartState(state, set)}
            right isOpen={data.cartOpen} pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } >
      <Fade spy={this.state.cartOpen} top cascade>
<CloseCartWrap>
        <CloseCart onClick={()=>this.closeCart(set)}>

          <CloseTxt ><FormattedMessage id={'cart_0_0'}/></CloseTxt>
        </CloseCart>
</CloseCartWrap>
      </Fade>
<LilaPadding/>
            <LilaCartItems/>

      {data.total!= 0 &&
<Div>
  <LilaSubTotal>
    <LilaTotalDiv>Subtotal: </LilaTotalDiv><LilAmount>{data.total}€</LilAmount>
    <LilaViewCart>
      <LocalizedLink to={'/cart'}>
        <FormattedMessage id={'cart_0'}/></LocalizedLink>
    </LilaViewCart>
  </LilaSubTotal>
  <LilaGutschrift><LilaTotalDiv><FormattedMessage id={'cart_5'}/></LilaTotalDiv><LilacAmount><LilaMinus>-</LilaMinus>{data.discount}€</LilacAmount><LilaGutschriftDetails onClick={()=>this.openPopup()}><StyledLilaEye><LilaEye/></StyledLilaEye></LilaGutschriftDetails></LilaGutschrift>

  <LilaTotal>
        <LilaTotalDiv>Total:</LilaTotalDiv> <LilAmount>{(data.total-data.discount)}€</LilAmount>
    <LilaTaxInfo>
      <LilaInclusive><FormattedMessage id={'cart_3'}></FormattedMessage>
      </LilaInclusive>

    </LilaTaxInfo>
      </LilaTotal>


</Div>

      }


      <LilaDelivery><FormattedMessage id={'delivery_1'}/></LilaDelivery>
      <LilaExpressDelivery><FormattedMessage id={'delivery_2'}/></LilaExpressDelivery>
      <Express>express checkout</Express>


      <LilaExpress>
        <PaypalExpress amount={data.total+data.shipping-data.discount} data={data}/>
      </LilaExpress>
<LilaLine><LilaOther><FormattedMessage id={'cart_4'}/></LilaOther></LilaLine>
      <LilaProceedFade  ><Fade bottom spy={this.state.cartOpen}><LilaProceed ><LilaRipples to={'/checkout'} ><FormattedMessage id={'cart_2'}></FormattedMessage></LilaRipples></LilaProceed></Fade></LilaProceedFade>
    </StyledCart>
<LilaPopup>
          <StyledPopup
            open={this.state.gutschriftOpen}
            closeOnDocumentClick={true}
            onClose={this.closePopup}
          >
            <div className="modal">
              <a className="close" onClick={this.closePopup}>

           <PopIcon className={'close-icon collapsed'}></PopIcon>
              </a>



              <LilaHase_2>
                <Lh_2>
                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
                    <defs>
                    </defs>
                    <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
                    <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
                    <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
                    <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
                          transform="translate(-14.76 -19.7)"/>
                    <path className="cls-1"
                          d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
                          transform="translate(-14.76 -19.7)"/>
                    <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
                          transform="translate(-14.76 -19.7)"/>
                    <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
                    <polygon className="cls-3"
                             points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
                    <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
                    <polygon className="cls-3"
                             points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
                  </svg>
                </Lh_2>
                <LilaSymbol>
                  <svg className="rotate" id="Layer_4" data-name="Layer 4" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 241.36 179.42">
                    <defs>
                    </defs>
                    <path className="cls-1"
                          d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                          transform="translate(-530.64 -373.08)"/>
                    <path className="cls-1"
                          d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                          transform="translate(-530.64 -373.08)"/>
                  </svg>
                </LilaSymbol>
              </LilaHase_2>
              <Gutschrift>Gutschrift*</Gutschrift>

              <LilaRowsWrap><Fade top spy={this.state.gutschriftOpen}> <GutschriftSub>ab einem Bestellwert von: </GutschriftSub></Fade>


                <LilaRows>
                  <LilaHairline> <LilaEuro>60€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>5€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaHairline>
                  <LilaExtraLight> <LilaEuro>100€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>10€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaExtraLight>
                  <LilaBook> <LilaEuro>200€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>20€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaBook>
                  <LilaSemiBold> <LilaEuro>500€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>50€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaSemiBold>

              </LilaRows></LilaRowsWrap>
              <LilaRowsWrap_2>
              <GutschriftSub_2>*gültig für jede Bestellung.</GutschriftSub_2>
              </LilaRowsWrap_2>


            </div>     </StyledPopup></LilaPopup>
    </StyledHeader>
        )}
      </LilaCmc>



    )
  }
}


export default LilaHeader


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>


//            <LilaHase>
//               <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.43 60.68">
//                 <defs>
//                 </defs>
//                 <polygon className="cls-1" points="17.12 10.98 15.99 1.3 15.93 1.4 13.4 17.59 17.12 10.98"/>
//                 <polygon className="cls-1" points="18 18.89 13.4 17.59 9.85 16.58 22.83 26.68 18 18.89"/>
//                 <polygon className="cls-2" points="9.85 16.58 3.3 27.93 22.83 26.68 9.85 16.58"/>
//                 <polygon className="cls-3" points="11.09 8.77 9.85 16.58 13.4 17.59 11.09 8.77"/>
//                 <polyline className="cls-1" points="3.16 0 0 10.2 6.6 14.89 8.46 6.56 3.16 0"/>
//                 <polygon className="cls-3" points="0 10.2 5.48 20.94 6.6 14.89 0 10.2"/>
//                 <polygon className="cls-3" points="5.48 20.94 3.3 27.93 9.85 16.58 5.48 20.94"/>
//                 <polygon className="cls-4" points="8.46 6.56 6.6 14.89 5.48 20.94 9.85 16.58 8.46 6.56"/>
//                 <polygon className="cls-2" points="15.93 1.4 11.09 8.77 13.4 17.59 15.93 1.4"/>
//                 <polygon className="cls-2" points="25.96 42.22 21.75 46.3 26.11 60.68 31.15 47.82 25.96 42.22"/>
//                 <polygon className="cls-5" points="31.15 47.82 26.11 60.68 31.35 60.68 31.15 47.82"/>
//                 <polygon className="cls-3" points="31.15 47.82 31.35 60.68 37.57 41.79 31.15 47.82"/>
//                 <polygon className="cls-2" points="37.57 41.79 31.35 60.68 40.43 51.81 37.57 41.79"/>
//                 <polygon className="cls-6" points="6.25 57.45 1.06 60.68 7.29 60.68 10.2 54.97 6.25 57.45"/>
//                 <polygon className="cls-4" points="13.01 49.43 21.75 46.3 13.97 36.92 13.01 49.43"/>
//                 <polygon className="cls-6" points="1.71 40.53 6.25 57.45 8.1 52.53 1.71 40.53"/>
//                 <polygon className="cls-3" points="13.97 36.92 1.71 40.53 8.1 52.53 13.97 36.92"/>
//                 <polygon className="cls-3" points="4.89 28.32 1.71 40.52 14.34 31.6 4.89 28.32"/>
//                 <polygon className="cls-4" points="17.38 38.42 16.39 31.1 13.97 36.92 17.38 38.42"/>
//                 <polygon className="cls-2" points="21.75 46.3 13.97 36.92 13.01 49.43 21.75 46.3"/>
//                 <polygon className="cls-7"
//                          points="26.11 60.68 21.75 46.3 13.01 49.43 10.2 54.97 10.2 54.97 10.2 54.97 7.29 60.68 26.11 60.68"/>
//                 <polygon className="cls-8"
//                          points="13.97 36.92 8.1 52.53 6.25 57.45 10.2 54.97 13.01 49.43 13.97 36.92"/>
//                 <polygon className="cls-1" points="25.96 42.22 17.38 38.42 13.97 36.92 21.75 46.3 25.96 42.22"/>
//                 <polygon className="cls-2" points="1.71 40.52 13.98 36.9 15.97 32.12 14.34 31.6 1.71 40.52"/>
//                 <polygon className="cls-9" points="5.58 28.56 16.39 31.1 16.39 31.1 5.58 28.56"/>
//                 <polygon className="cls-9" points="10.79 29.77 5.18 28.45 5.18 28.45 10.79 29.77"/>
//                 <polygon className="cls-9" points="5.58 28.56 16.39 31.1 10.79 29.77 5.18 28.45 5.58 28.56"/>
//                 <polygon className="cls-9" points="5.18 28.45 5.18 28.45 5.18 28.45 5.18 28.45"/>
//                 <polygon className="cls-9" points="16.39 31.1 16.39 31.1 16.39 31.1 16.39 31.1"/>
//                 <polygon className="cls-6" points="5.18 28.45 5.18 28.45 5.18 28.45 5.18 28.45"/>
//                 <polygon className="cls-5"
//                          points="5.18 28.45 14.51 31.66 15.96 32.14 16.39 31.1 5.58 28.56 5.18 28.45"/>
//                 <polygon className="cls-6" points="5.18 28.45 5.18 28.45 5.18 28.45 5.58 28.56 5.18 28.45"/>
//                 <polygon className="cls-4" points="16.39 31.1 16.39 31.1 16.39 31.1 16.39 31.1"/>
//                 <polygon className="cls-3" points="3.3 27.93 5.18 28.45 10.79 29.77 15.57 30.91 22.83 26.68 3.3 27.93"/>
//                 <polygon className="cls-6" points="22.83 26.68 15.57 30.91 16.39 31.1 22.83 26.68"/>
//               </svg>
//             </LilaHase>
