import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import styled from "@emotion/styled";
import ContextConsumer from './Context'


const MatchBox = styled.div`
      .cls-1{fill:#e0af4c;}
      .cls-2{fill:#edc988;}

cursor:pointer;
display:flex;
align-items:end;
justify-content:right;
`

class MatchToggler extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      dark:true
    }
    this.toggleMatch=this.toggleMatch.bind(this)
  }

  toggleMatch(toggleTheme, set){
   toggleTheme(!this.state.dark ? 'dark' : 'light')
    set({dark:!this.state.dark})
    this.setState({
      dark:!this.state.dark
    })

  }

  render() {
    return (

      <ContextConsumer>
        {({ data, set }) => (
          <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <MatchBox>
          <svg onClick={e => this.toggleMatch(toggleTheme, set)} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165.31 461.89">
            <defs>
              <style>
              </style>
            </defs>
            <title>{this.state.dark? 'need a light?': 'toggle dark mode'}</title>
            <path className="cls-1" d="M226.68,290.52V495.58q10.57.56,21.4.57t21.37-.57V290.52Z"
                  transform="translate(-167.02 -34.26)"/>
            <path className="cls-2" d="M226.68,290.52V495.58q9.91.53,20,.56V290.52Z"
                  transform="translate(-167.02 -34.26)"/>
            <path style={{ fill: this.state.dark ? 'none': '#e17a2d'}} className="cls-3"
                  d="M300.85,124.59c-.53,19-6.39,34.8-22.2,50.61,0,0,20.27-53.27-1.19-89-22.82-38-55.89-51.9-55.89-51.9s2.39,48.87-10,81.21C196.87,153.6,167,178.71,167,218.3c0,40.68,37,73.61,82.66,73.64s82.65-33,82.65-73.64C332.34,185.43,318.61,152.94,300.85,124.59Z"
                  transform="translate(-167.02 -34.26)"/>
            <path style={{ fill: this.state.dark ? 'none': '#f4e028'}} className="cls-4"
                  d="M233.27,135.49s1.31,29.64-5.51,49.25c-8,23.12-24.44,38.36-24.44,62.36,0,24.67,20.32,44.65,45.39,44.66s43.53-20.06,45.39-44.66C298.76,185.33,233.27,135.49,233.27,135.49Z"
                  transform="translate(-167.02 -34.26)"/>
            <path style={{ fill: this.state.dark ? 'none': '#af67a9'}} className="cls-5"
                  d="M239,191.9s.83,18.78-3.49,31.21c-5.1,14.65-15.49,24.3-15.49,39.52a28.53,28.53,0,0,0,28.76,28.29c15.89,0,27.58-12.71,28.76-28.29C280.47,223.49,239,191.9,239,191.9Z"
                  transform="translate(-167.02 -34.26)"/>
            <path style={{ fill: this.state.dark ? '#994095': 'none'}} className="cls-6"
                  d="M269.66,291.26c9.74-10.14,16.11-36.83,16.11-56,0-31-16.75-42.29-37.42-42.29s-37.43,11.29-37.43,42.29c0,18.87,6.2,45.36,15.72,55.54"
                  transform="translate(-167.02 -34.26)"/>
            <path style={{ fill: this.state.dark ? '#7e287e': 'none'}} className="cls-7" d="M269.66,291.26c9.74-10.14,16.11-36.83,16.11-56,0-31-68.65,45.36-59.13,55.54"
                  transform="translate(-167.02 -34.26)"/>
          </svg>
          </MatchBox>

        )}

      </ThemeToggler>
        )}
      </ContextConsumer>
    )
  }
}

export default MatchToggler


//              onClick={toggleTheme(this.state.dark ? 'dark' : 'light')}
