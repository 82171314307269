import * as React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { fonts } from '../styles/variables'
import {AiFillCreditCard} from 'react-icons/ai'
import {FaCcPaypal} from "react-icons/fa";
import {FaIdeal} from "react-icons/fa";
import {RiBankLine} from "react-icons/ri"
import {GrAmex} from "react-icons/gr";
import {GrVisa} from "react-icons/gr";
import {GrBitcoin} from "react-icons/gr";
import {RiMastercardLine} from "react-icons/ri"

export const LilaSvgContainer = styled.div`
display:flex;
align-items:center;



`

export const LilaPaymentMethod = styled.div`
margin-left:0.7rem;
font-family:${fonts.bcExtraLight};
letter-spacing:0px;
letter-spacing:0px;
`

export const LilaGiroPayWrap = styled.div`
width:26px;
`
export const LilaKlarna = styled.div`
display:flex;
align-items:center;

svg{width:20px;
}
`

export const LilaIconWrap = styled.div`
width:40px;
display:flex;
justify-content:center;
align-items:center;
svg{
width:40px;
}
`

export const LilaIconWrap_2 = styled.div`
width:40px;
display:flex;
align-items:center;
justify-content:center;


`


export const LilaFirstCreditLine = styled.div`
display:flex;
justify-content:center;
align-items:center;
width:100%;
height:10px;
`
const LilaSecondCreditLine = styled.div`
display:flex;
justify-content:center;

width:100%;
height:15px;

svg{
width:30px !important;
height:20px !important;
}

path{
position:absolute;
top:0;
}
`


const LilaMaster = styled(RiMastercardLine)`
width:12px;

`
const LilaVisa = styled(GrVisa)`
width:11px;
`

const LilaAmex = styled(GrAmex)`


`

export const LilaMasterVisaAmex = () =>

  <div>
  <LilaFirstCreditLine>
    <LilaMaster /> <LilaVisa/>
  </LilaFirstCreditLine>
    <LilaSecondCreditLine>
    <LilaAmex size={'30px'} />
    </LilaSecondCreditLine>
  </div>


export const CreditIcon = () => <AiFillCreditCard size={'22px'}/>


export const KlarnaIcon = () => <svg version="1.1" viewBox="0 0 45 25" height="25" width="45" xmlns="http://www.w3.org/2000/svg">
  <title>Klarna Payment Badge</title>
  <g fill="none" fill-rule="evenodd">
    <g transform="translate(-310 -37)" fill-rule="nonzero">
      <g transform="translate(310 37)">
        <rect x="5.6843e-14" width="45" height="25" rx="4.321" fill="#FFB3C7"/>
        <g transform="translate(4.4136 8.4)" fill="#0A0B09">
          <path
            d="m36.38 6.2463c-0.58875 0-1.066 0.48158-1.066 1.0757 0 0.594 0.47725 1.0757 1.066 1.0757 0.58874 0 1.0661-0.48167 1.0661-1.0757 0-0.59416-0.47734-1.0757-1.0661-1.0757zm-3.5073-0.83166c0-0.81338-0.68897-1.4726-1.5389-1.4726s-1.539 0.65925-1.539 1.4726c0 0.81339 0.68898 1.4728 1.539 1.4728s1.5389-0.65941 1.5389-1.4728zm0.0057148-2.8622h1.6984v5.7242h-1.6984v-0.36584c-0.47982 0.3302-1.059 0.52431-1.6837 0.52431-1.6531 0-2.9933-1.3523-2.9933-3.0205s1.3402-3.0204 2.9933-3.0204c0.6247 0 1.2039 0.1941 1.6837 0.5244v-0.36619zm-13.592 0.74562v-0.74554h-1.7389v5.7241h1.7428v-2.6725c0-0.90167 0.96849-1.3863 1.6405-1.3863 0.0068818 0 0.013306 6.6771e-4 0.020188 7.527e-4v-1.6656c-0.68973 0-1.3241 0.298-1.6646 0.7452zm-4.3316 2.1166c0-0.81338-0.68905-1.4726-1.539-1.4726-0.84991 0-1.539 0.65925-1.539 1.4726 0 0.81339 0.68905 1.4728 1.539 1.4728 0.84998 0 1.539-0.65941 1.539-1.4728zm0.0056186-2.8622h1.6985v5.7242h-1.6985v-0.36584c-0.47982 0.3302-1.059 0.52431-1.6836 0.52431-1.6532 0-2.9934-1.3523-2.9934-3.0205s1.3402-3.0204 2.9934-3.0204c0.62464 0 1.2038 0.1941 1.6836 0.5244v-0.36619zm10.223-0.15396c-0.67846 0-1.3206 0.21255-1.7499 0.79895v-0.64465h-1.6911v5.7239h1.7119v-3.0081c0-0.87046 0.57847-1.2967 1.275-1.2967 0.74646 0 1.1756 0.44996 1.1756 1.2849v3.0199h1.6964v-3.6401c0-1.3321-1.0496-2.238-2.4179-2.238zm-17.374 5.8782h1.7777v-8.2751h-1.7777v8.2751zm-7.8091 0.0022581h1.8824v-8.2789h-1.8824v8.2789zm6.584-8.2789c0 1.7923-0.69219 3.4596-1.9256 4.6989l2.602 3.5803h-2.325l-2.8278-3.891 0.72981-0.55152c1.2103-0.91484 1.9045-2.3132 1.9045-3.8367h1.8421z"/>
        </g>
      </g>
    </g>
  </g>
</svg>



export const PayPalIcon = () => <FaCcPaypal/>


// export const GiropayIcon = <svg></svg> todo: exchange with png (see bottom)

export const IDEALIcon = () => <FaIdeal/>


export const TransferIcon = () => <RiBankLine/>


export const BitcoinIcon = () => <GrBitcoin/>


export function GiropayIcon(props) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "giropay.png" }) {
        childImageSharp {
          fluid(maxWidth: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const {url, altText, title} = props;
  const styles = {

  }

  return (
    <Img
      style={styles}
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt={altText}
      title={title} />
  )
}
