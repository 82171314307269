import config from '../utils/config'
import { standard_pm_methods } from '../constants/paymentMethods'
import { getItemCount } from './stripeFunctions'
import { renderToString } from 'react-dom/server'
import Email from '../templates/email'
import React from 'react'
var moment = require('moment'); // require
const cryptoRandomString = require('crypto-random-string');

export function getNewOrderID(){
  const first = moment().format('YYYYMMDD')
  const second = cryptoRandomString({length: 6, type: 'base64'});

return first + '-'+second

}


export function getRequestData(dynamoData, items){
  let request = [];
  let inventory = dynamoData.Responses.inventory
  for(let r =0;r<items.length;r++){

    for (let f=0;f<inventory.length;f++){
     // console.log(items[r].id)
      if(items[r].id===inventory[f].id.S){
//console.log('Hello my Friend')
        let newQuantity = Number(inventory[f].quantity.N)-items[r].quantity
        let entry =        {
          PutRequest: {
            Item: {
              "id": { "S": items[r].id },
              "quantity": { "N": newQuantity.toString()},
            }
          }
        }

        request.push(entry)

      }
    }


  }
  return request
}


function getDynamoItems(items){
  let dynamoItems = []
  for(let i=0;i<items.length;i++){

      let entry = {'id': {S: items[i].item}}
      dynamoItems.push(entry)


    }

  return dynamoItems
}

export function getQuantity(items){
  let quantityItems = []
  for(let i=0;i<items.length;i++){

      let entry = {
        id:items[i].item,
        quantity:items[i].quantity
      }
        quantityItems.push(entry)


  }

  return quantityItems
}




export async function  readBulkedInventory (items) {
const dynamoItems = await getDynamoItems(items)
  const res = await fetch(config.inventory.readBulkedInventoryUrl, {
    method: 'POST',
    body: JSON.stringify({

          keys:dynamoItems




      }
    ),

  });

  const fetchedData = await res.json();
  let raw = await JSON.parse(fetchedData.body)
 // console.log(await raw)

  return await raw
}



export async function  setBulkedInventory (items) {
//const dynamoItems = await getDynamoItems(items)
 // const quantityItems = await getQuantity(items)
  const res = await fetch(config.inventory.setBulkedInventoryUrl, {
    method: 'POST',
    body: JSON.stringify({

        items: items




      }
    ),

  });

  const fetchedData = await res.json();
  return fetchedData
}




export async function  minusBulkedStocks (items) {



}
export async function  plusBulkedStocks (items) {



}






