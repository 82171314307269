import * as React from 'react'
import styled from '@emotion/styled'
import { fonts } from '../styles/variables'
import { getItemCount } from '../functions/stripeFunctions'
import { _lilaRegions } from '../constants/regions'
import { standard_pm_methods } from '../constants/paymentMethods'
//require('../styles/global.css')

const Lh = styled.div`
width:126px;
margin-left:auto;
margin-right:auto;
align-items:center;
.cls-1{fill:var(--hase);}
.cls-2{fill:none;}
.cls-3{fill:var(--hase);}
`




let moment = require('moment'); // require




const mailDE = {

  order_mail_1:'Übersicht Ihrer Bestellung',
  order_mail_2:'Bestell-ID  ',
  order_mail_3:'Artikel',
  order_mail_4:'Datum',
  order_mail_5:'Zahlungsmethode',
  order_mail_6:'Zwischensumme',
  order_mail_7:'Versand',
  order_mail_8:'Gutschrift',
  order_mail_9:'Gesamt',
  order_mail_10:'Liebe/r',
  order_mail_11:'vielen Dank für Ihre Bestellung, diese ist bei uns eingegangen. Im Folgenden finden Sie die Bestellübersicht im Detail. Wir werden nun schnellstmöglich den Versand Ihrer Produkte vorbereiten und Ihnen anschließend eine Bestätigung mit Ihrer Sendungsnummer zukommen lassen.',
  order_mail_12:'Falls Sie weitere Fragen zu Ihrer Bestellung haben oder Hilfe benötigen, können Sie sich gerne mit einer Antwort auf diese Email an unseren Kundenservice wenden.',
  order_mail_13:'Wir hoffen Sie bald wieder auf unserer Seite zu begrüßen.',
  order_mail_14:'Hinweis für Zahlung per Banküberweisung :',
  order_mail_15:'Wir bitten Sie, den offenen Rechnungsbetrag innerhalb der nächsten Werktage auf das u.g. Konto zu überweisen. Bitte achten Sie darauf Ihre Bestell-ID im Verwendungszweck anzugeben. Wir versenden Ihre Produkte, sobald die Zahlung auf unserem Konto eingegangen ist.',
  order_mail_16:'Unsere Kontoverbindung:',
  order_mail_17:'Kontoinhaber:',
  order_mail_18:'IBAN:',
  order_mail_19:'BIC/SWIFT:',
  order_mail_20:'Bank',
  order_mail_21:'Steuertabelle',
  order_mail_22:'MwSt.',
  order_mail_23:'Brutto',
  order_mail_24:'Netto',
  order_mail_25:'inkl. MwSt.',
  order_mail_26:'Sollte es bei der Zahlungsabwicklung zu Problemen gekommen sein, können Sie jedezeit bequem Ihre Zahlung unter folgendem Link vervollständigen:',

}
const mailEN = {

  order_mail_1:'Overview of your order',
  order_mail_2:'Order ID ',
  order_mail_3:'Items',
  order_mail_4:'Date',
  order_mail_5:'Payment method',
  order_mail_6:'Subtotal',
  order_mail_7:'Shipping',
  order_mail_8:'Discount',
  order_mail_9:'Total',
  order_mail_10:'Dear',
  order_mail_11:'your order placed successfully and will be shipped within the next 24 hours (M-F). Once it ships, you will receive a confirmation mail with your tracking number.',
  order_mail_12:'If you have further questions about your order, you can always contact our customer service.',
  order_mail_13:'Wir hoffen Sie bald wieder auf unserer Seite zu begrüßen.',
  order_mail_14:'Note for manual bank transfers :',
  order_mail_15:'Please specify your order id as the reason for payments to our account mentioned below or in our imprint.' ,
  order_mail_16:'Our bank account:',
  order_mail_17:'Account holder:',
  order_mail_18:'IBAN:',
  order_mail_19:'BIC/SWIFT:',
  order_mail_20:'Bank:',
  order_mail_21:'Taxes',
  order_mail_22:'TAX',
  order_mail_23:'Gross',
  order_mail_24:'Net.',
  order_mail_25:'TAX incl.',
  order_mail_26:'If you had problems with your payment, you can easily complete your payment at any time through the following link:',

}

const StyledComponent = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;

`
const style = "@font-face {font-family: \"billcorporatenarrowW00-medium\"; src: url(\"//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.eot\"); src: url(\"//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.eot?#iefix\") format(\"embedded-opentype\"), url(\"//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.woff2\") format(\"woff2\"), url(\"//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.woff\") format(\"woff\"), url(\"//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.ttf\") format(\"truetype\"), url(\"//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.svg#billcorporatenarrowW00-medium\") format(\"svg\"); }\n"

const LilaInlay = styled.div`

@font-face {font-family: "billcorporatenarrowW00-medium"; src: url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.eot"); src: url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.woff") format("woff"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.svg#billcorporatenarrowW00-medium") format("svg"); }

width:100%;
display:flex;
justify-content:center;
`

const LilaMain = styled.div`
max-width: 600px;
min-width: 600px;
width: 600px;
`
const LilaMailHeader = styled.div`
display:flex;

`
const LilaLeft = styled.div`
background-color: #e4f1e1;
`

const LilaMiddle = styled.div`

`
const LilaRight = styled.div`
background-color: #e4f1e1;

`

const DearLila = styled.div`




`
const LilaOverviewTxt = styled.div`




`
const LilaTitle = styled.div`



`

const LilaSub = styled.div`

`

const LilaSpace = styled.div`
height:2rem;
`
const LilaSpace2 = styled.div`
height:1rem;
`
const LilaSpace3 = styled.div`
height:0.6rem;
`

const LilaOverview = styled.div`
padding-left:64px;
padding-right:64px;
`

const Tr = styled.div`



`

const Td = styled.div`
width:33%;
border:none;
padding:0;


`
const TrHairline = styled.div`




`

const LilaRow1 = styled.div`
display:flex;
margin-bottom:0.7rem;

`
const LilaRow2 = styled.div`
display:flex;

`
const LilaCell1 = styled.div`



`
const LilaCell2 = styled.div`



`

const LilaLine = styled.div`
border-top:1px solid var(--border);
`

const LilaImg = styled.img`

`

const LilaCount = styled.div`

`

const LilaImgWrapper = styled.div`

`

const LilaNote = styled.div`


`
const LilaNote2 = styled.div`


`

const LilaAddition = styled.div`
font-family:${fonts.bcExtraLight};
opacity:.5;
font-size:0.8rem;
`

const Left = styled.div`
width:50%;
`
const Right = styled.div`
width:50%;
`
const Footer = styled.div`
display:flex;
width:100%;
`

const LilaMail = styled.a`
margin-left:0.3rem;
`

const LilaTaxes = styled.div`

`

const LilaTaxTitle = styled.div`

`

const LilaTaxCell = styled.div`
`

const LilaTaxRow = styled.tr`
`

class Email extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  render() {
    let data = this.props.data
    let pmLink = this.props.paymentLink
    //sample data todo
    //let count = 2
    //let created = '2020-04-04'
    const pm = standard_pm_methods[data._lila_pay].title
    let iso = _lilaRegions[data._lila_region].iso
    let txt = mailDE
    if(iso!='de'){
      txt=mailEN
    }

    const now = moment().locale(iso).format('MMMM Do YYYY h:mm')


    return(

<html>
<head>
  <link href="//db.onlinewebfonts.com/c/02654c5f87934978cd2129477ad40869?family=billcorporatenarrowW00-medium" rel="stylesheet" type="text/css"/>

  <style>

</style>
</head>

        <LilaInlay style={{ width:'100%', justifyContent:'center', display:'flex'}} id={'component'}>

          <LilaMain style={{ fontFamily: 'billcorporatenarrowW00-medium', maxWidth: '600px', minWidth: '600px', width: '600px'}}>
<LilaMailHeader style={{display:'flex'}}>
  <LilaLeft style={{backgroundColor: '#e4f1e1'}}>
    <img data-imagetype="External" src="https://stripe-images.s3.amazonaws.com/notifications/hosted/20180110/Header/Left.png" alt="" style={{display:'block', border:0, lineHeight:'100%', width:'252px', height:'156px'}} width="252" height="156"/>
  </LilaLeft>
  <LilaMiddle>
    <a href={'https://www.lilahase.com/'}>
      <img data-imagetype="External" src="https://stripe-images.s3.amazonaws.com/emails/acct_1GUhncKElFbRwvfD/3/twelve_degree_icon@2x.png" alt="" style={{display:'block', border:0, lineHeight:'100%',width:'96px', height:'156px'}} width="96" height="156"/></a>
  </LilaMiddle>
  <LilaRight style={{backgroundColor: '#e4f1e1'}}>
    <img data-imagetype="External" src="https://stripe-images.s3.amazonaws.com/notifications/hosted/20180110/Header/Right.png" alt="" style={{display:'block', border:0, lineHeight:'100%', width:'252px', height:'156px'}} width="252" height="156"/>
  </LilaRight>
</LilaMailHeader>
  <LilaSpace style={{height:'2rem'}}/>
  <LilaSpace2 style={{height:'1rem'}}/>

<LilaOverview style={{paddingLeft:'64px', paddingRight:'64px', fontFamily:'billcorporatenarrowW00-medium, sans-serif'}}>
  <DearLila style={{fontWeight:'bold', fontFamily:'billcorporatenarrowW00-medium',
    textAlign:'center',
    width:'100%'}}>
    {txt.order_mail_10 + ' ' + data._lila_cus_name1 + ' '+data._lila_cus_name2+ ','}
  </DearLila>
  <br/>
  <LilaOverviewTxt style={{fontFamily:'billcorporatenarrowW00-medium',

    width:'100%', textAlign:'center'}}>
    {txt.order_mail_11}
  </LilaOverviewTxt>
  <LilaSpace style={{height:'2rem'}}/>
  <LilaSpace2 style={{height:'1rem'}}/>
  <LilaTitle style={{fontWeight:'bold', fontFamily:fonts.bcMedium, width:'100%', textAlign:'center'}}>{txt.order_mail_1}</LilaTitle>
  <LilaSub style={{fontFamily:fonts.bcMedium, width:'100%', textAlign:'center'}}>{txt.order_mail_2 + this.props.orderID}</LilaSub>
  <LilaSpace style={{height:'2rem'}}/>
  <Tr style={{fontSize:'0.9rem',opacity:.5, border:'none', fontFamily:fonts.bcMedium, display:'flex'}}>
    <Td style={{width:'30%',border:'none', padding:0 }}>{txt.order_mail_3}</Td>
    <Td style={{width:'40%',border:'none', padding:0 }}>{txt.order_mail_4}</Td>
    <Td style={{width:'30%',border:'none', padding:0 }}>{txt.order_mail_5}</Td>
  </Tr>
  <TrHairline style={{fontSize:'0.9rem',border:'none', fontFamily:fonts.bcMedium, display:'flex'}}>
    <Td style={{width:'30%',border:'none', padding:0 }}>{getItemCount(data.cartItems)}</Td>
    <Td style={{width:'40%',border:'none', padding:0 }}>{now}</Td>
    <Td style={{width:'30%',border:'none', padding:0 }}>{pm}</Td>
  </TrHairline>
  <LilaSpace style={{height:'2rem'}}/>

  {

    data.cartItems.map((item) => (

      item.quantity >=1 &&
      <LilaRow1 style={{display:'flex', marginBottom:'0.7rem'}}>
        <LilaCell1 style={{width:'30%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}><LilaImgWrapper style={{position:'relative', width:'50px'}}><LilaImg style={{width:'50px'}} src={item.smallThumb}/> <LilaCount style={{position:'absolute',height:'24px',width:'24px',display:'flex',justifyContent:'center', alignItems:'center', borderRadius:'50%', background:'#7e287e', color:'white', top:'-12px', right:'-12px', fontFamily:fonts.bcExtraLight, fontSize:'0.6rem'}}>{item.quantity}</LilaCount></LilaImgWrapper></LilaCell1>
        <LilaCell1 style={{width:'40%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}>{item.title + ' ' + item.subTitle}</LilaCell1>
        <LilaCell2 style={{border:'none', padding:0,display:'flex', alignItems:'center',justifyContent:'flex-end', fontFamily:fonts.bcMedium, width:'60px'}}>{item.quantity*item.price}€</LilaCell2>
      </LilaRow1 >
    ))
  }



  <LilaLine style={{borderTop:'1px solid rgba(0,0,0,.1)'}}></LilaLine>
  <LilaSpace style={{height:'2rem'}}/>
  <LilaRow2 style={{display:'flex' }}>
    <LilaCell1 style={{width:'30%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}></LilaCell1>
    <LilaCell1 style={{width:'40%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}>{txt.order_mail_6}</LilaCell1>
    <LilaCell2 style={{border:'none', padding:0,display:'flex', alignItems:'center',justifyContent:'flex-end', fontFamily:fonts.bcMedium, width:'60px'}}>{data.total}€</LilaCell2>
  </LilaRow2>
  <LilaRow2 style={{display:'flex' }}>
    <LilaCell1 style={{width:'30%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}></LilaCell1>
    <LilaCell1 style={{width:'40%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}>{txt.order_mail_7}</LilaCell1>
    <LilaCell2 style={{border:'none', padding:0,display:'flex', alignItems:'center',justifyContent:'flex-end', fontFamily:fonts.bcMedium, width:'60px'}}>{data.shipping}€</LilaCell2>
  </LilaRow2>
  <LilaRow2 style={{display:'flex' }}>
    <LilaCell1 style={{width:'30%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}></LilaCell1>
    <LilaCell1 style={{width:'40%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}>{txt.order_mail_8}</LilaCell1>
    <LilaCell2 style={{border:'none', padding:0,display:'flex', alignItems:'center',justifyContent:'flex-end', fontFamily:fonts.bcMedium, width:'60px'}}>{'-'+data.discount}€</LilaCell2>
  </LilaRow2>
  <LilaSpace2 style={{height:'1rem'}}></LilaSpace2>
  <LilaRow2 style={{display:'flex' }}>
    <LilaCell1 style={{width:'30%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}></LilaCell1>
    <LilaCell1 style={{width:'40%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}>{txt.order_mail_9}</LilaCell1>
    <LilaCell2 style={{border:'none', padding:0,display:'flex', alignItems:'center',justifyContent:'flex-end', fontFamily:fonts.bcMedium, width:'60px'}}>{data.total+data.shipping-data.discount}€</LilaCell2>
  </LilaRow2>
  <LilaRow2 style={{display:'flex' }}>
    <LilaCell1 style={{width:'30%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}></LilaCell1>
    <LilaCell1 style={{width:'40%', border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}></LilaCell1>
    <LilaCell2 style={{fontSize:'0.7rem',border:'none', padding:0,display:'flex', alignItems:'center',justifyContent:'flex-end', fontFamily:fonts.bcMedium, opacity:.5, width:'60px'}}>{txt.order_mail_25}</LilaCell2>
  </LilaRow2>

  <div style={{display:'flex',width:'100%', justifyContent:'flex-end' }}>
    <div style={{fontSize:'0.7rem', border:'none', padding:0, fontFamily:fonts.bcMedium, width:'70%', opacity:.5}}>
      <LilaTaxes style={{paddingTop:'10%', width:'100%'}}>
        <LilaTaxTitle style={{textDecoration:'underline',paddingBottom:'6%',fontSize:'0.7rem'}}> {txt.order_mail_21}</LilaTaxTitle>
        <div style={{display:'flex', width:'100%'}}>
          <LilaTaxCell style={{fontSize:'0.6rem',width:'87px',border:'none', padding:0,  fontFamily:fonts.bcMedium}}>
            {txt.order_mail_24} <br/>
            {((data.total+data.shipping-data.discount)/1.16).toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2
            })}

          </LilaTaxCell>
          <LilaTaxCell style={{fontSize:'0.7rem',width:'87px',border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}>
            {txt.order_mail_22}<br/>
            {((data.total+data.shipping-data.discount)-((data.total+data.shipping-data.discount)/1.16)).toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2
            })}
          </LilaTaxCell>
          <LilaTaxCell style={{fontSize:'0.7rem',width:'87px',border:'none', padding:0, display:'flex', alignItems:'center', fontFamily:fonts.bcMedium}}>
            {txt.order_mail_23} <br/>
            {(data.total+data.shipping-data.discount).toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2
            })}

          </LilaTaxCell>
        </div>

      </LilaTaxes>

    </div>
  </div>
  <LilaSpace style={{height:'2rem'}}/>
  <LilaSpace2 style={{height:'1rem'}}/>
  {standard_pm_methods[data._lila_pay].value != 'bank' &&
  <LilaNote style={{ fontFamily: fonts.bcMedium, textAlign: 'center' }}>
    {txt.order_mail_26}
    <a href={pmLink}></a>
  </LilaNote>
  }
  {standard_pm_methods[data._lila_pay].value === 'bank' &&
  <LilaNote style={{ fontFamily: fonts.bcMedium, textAlign: 'center' }}>
    {txt.order_mail_15}
    <a href={pmLink}></a>
  </LilaNote>
  }
  <LilaSpace style={{height:'2rem'}}/>
  <LilaSpace2 style={{height:'1rem'}}/>
  <LilaNote style={{fontFamily:fonts.bcMedium, textAlign:'center'}}>
    {txt.order_mail_12}


  </LilaNote>

  <LilaSpace style={{height:'2rem'}}></LilaSpace>

  <LilaNote2 style={{fontFamily:fonts.bcMedium,textAlign:'center'}}> {txt.order_mail_13}</LilaNote2>
  <LilaSpace style={{height:'2rem'}}></LilaSpace>
  <Lh style={{width:'126px', marginLeft:'auto', marginRight:'auto', alignItems:'center'}}>
    <a href={'https://www.lilahase.com/'}>

    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
      <defs>
      </defs>
      <path style={{fill:'#7e287e'}} className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
      <path style={{fill:'#7e287e'}} className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
      <path style={{fill:'#7e287e'}} className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
      <path style={{fill:'#7e287e'}} className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
            transform="translate(-14.76 -19.7)"/>
      <path style={{fill:'#7e287e'}} className="cls-1"
            d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
            transform="translate(-14.76 -19.7)"/>
      <path style={{fill:'#7e287e'}} className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
            transform="translate(-14.76 -19.7)"/>
      <polygon style={{fill:'none'}} className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
      <polygon style={{fill:'#7e287e'}} className="cls-3"
               points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
      <polygon  style={{fill:'none'}} className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
      <polygon style={{fill:'#7e287e'}} className="cls-3"
               points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
    </svg>
    </a>
  </Lh>
  <LilaSpace style={{height:'2rem'}}></LilaSpace>
  <LilaLine style={{borderTop:'1px solid rgba(0,0,0,.1)'}}></LilaLine>
  <LilaSpace style={{height:'2rem'}}/>


  <LilaSpace style={{height:'2rem'}}></LilaSpace>
  <LilaAddition style={{paddingLeft:'0%', fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem'}}>{txt.order_mail_16}</LilaAddition>
  <LilaSpace3 style={{height:'0.6rem'}}></LilaSpace3>
  <div style={{paddingLeft:'1%', paddingTop:'5%'}}>
  <LilaAddition style={{width:'100%',display:'flex',fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem'}}>
    <div style={{width:'33%'}}>{txt.order_mail_17}</div>
    <div style={{width:'66%'}}>{'LILA HASE UG (haftungsbeschränkt)'}</div>
    </LilaAddition>
  <LilaAddition style={{width:'100%',display:'flex',fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem'}}>
    <div style={{width:'33%'}}>{txt.order_mail_18}</div>
    <div style={{width:'66%'}}>{'DE49 1001 7997 2631 4267 60'}</div>
    </LilaAddition>
  <LilaAddition style={{width:'100%',display:'flex',fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem'}}>
    <div style={{width:'33%'}}>{txt.order_mail_19}</div>
    <div style={{width:'66%'}}>{'HOLVDEB1'}</div>
    </LilaAddition>
  <LilaAddition style={{width:'100%',display:'flex',fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem'}}>
    <div style={{width:'33%'}}>{txt.order_mail_20}</div>
    <div style={{width:'66%'}}>{'Holvi'}</div>
    </LilaAddition>
  </div>
<Footer style={{display:'flex', width:'100%'}}>
  <Left style={{width:'50%'}}>

  </Left>
  <Right style={{width:'50%'}}></Right>
</Footer>
</LilaOverview>

</LilaMain>

          <link href="//db.onlinewebfonts.com/c/02654c5f87934978cd2129477ad40869?family=billcorporatenarrowW00-medium" rel="stylesheet" type="text/css"/>

    </LilaInlay>

</html>

    )
  }
}


export default Email


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
