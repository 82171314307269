import * as React from 'react'
import styled from '@emotion/styled'
import { fonts } from '../styles/variables'

//require('../styles/global.css')

const Lh = styled.div`
width:126px;
margin-left:auto;
margin-right:auto;
align-items:center;
.cls-1{fill:var(--hase);}
.cls-2{fill:none;}
.cls-3{fill:var(--hase);}
`




//let moment = require('moment'); // require




const mailDE = {

  news_success_1:'Herzlich Willkommen bei der LILA Mission!',
  news_success_2:'Du erhälst von uns zukünftig Benachrichtigungen, sobald neue Produkte, Features, Merch uvm. erscheinen. ',
  news_success_3:'Du möchtest den Newsletter nicht mehr erhalten?',
  news_success_4:'Schade - jedoch kein Problem!',
  news_success_5:'Falls Du den Newsletter nicht mehr erhalten möchtest, sende uns bitte eine Mail an',
  news_success_6:'Vielen Dank für Deine Unterstützung!',

}
const mailEN = {
  news_success_1:'Warmly welcome to the LILA mission!',
  news_success_2:'You will be notified about new launches, restocks, merch and much more.',
  news_success_3:'',
  news_success_4:'',
  news_success_5:'If you no longer want to receive our newsletter, please send us an email to',
  news_success_6:'Thank you!',

}


const LilaInlay = styled.div`

@font-face {font-family: "billcorporatenarrowW00-medium"; src: url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.eot"); src: url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.woff") format("woff"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/02654c5f87934978cd2129477ad40869.svg#billcorporatenarrowW00-medium") format("svg"); }

width:100%;
display:flex;
justify-content:center;
`

const LilaMain = styled.div`
max-width: 600px;
min-width: 600px;
width: 600px;
`
const LilaMailHeader = styled.div`
display:flex;

`
const LilaLeft = styled.div`
background-color: #e4f1e1;
`

const LilaMiddle = styled.div`

`
const LilaRight = styled.div`
background-color: #e4f1e1;

`

const DearLila = styled.div`




`


const LilaSpace = styled.div`
height:2rem;
`
const LilaSpace2 = styled.div`
height:1rem;
`
const LilaSpace3 = styled.div`
height:0.6rem;
`

const LilaOverview = styled.div`
padding-left:64px;
padding-right:64px;
`


const LilaLine = styled.div`
border-top:1px solid var(--border);
`



const LilaAddition = styled.div`
font-family:${fonts.bcExtraLight};
opacity:.5;
font-size:0.8rem;
`

const Left = styled.div`
width:50%;
`
const Right = styled.div`
width:50%;
`
const Footer = styled.div`
display:flex;
width:100%;
`



class NewsletterSuccess extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  render() {

    //sample data todo
    //let count = 2
    //let created = '2020-04-04'

    let txt = mailDE
    if(this.props.locale!='de'){
      txt=mailEN
    }

    //const now = moment().locale(iso).format('DD.MM.YYYY hh:mm ')


    return(

      <html>
      <head>
        <link href="//db.onlinewebfonts.com/c/02654c5f87934978cd2129477ad40869?family=billcorporatenarrowW00-medium" rel="stylesheet" type="text/css"/>

        <style>

        </style>
      </head>

      <LilaInlay style={{ width:'100%', justifyContent:'center', display:'flex'}} id={'component'}>

        <LilaMain style={{ fontFamily:fonts.bcMedium, maxWidth: '600px', minWidth: '600px', width: '600px'}}>
          <LilaMailHeader style={{display:'flex'}}>
            <LilaLeft style={{backgroundColor: '#e4f1e1'}}>
              <img data-imagetype="External" src="https://stripe-images.s3.amazonaws.com/notifications/hosted/20180110/Header/Left.png" alt="" style={{display:'block', border:0, lineHeight:'100%', width:'252px', height:'156px'}} width="252" height="156"/>
            </LilaLeft>
            <LilaMiddle>
              <a href={'https://www.lilahase.com/'}>
                <img data-imagetype="External" src="https://stripe-images.s3.amazonaws.com/emails/acct_1GUhncKElFbRwvfD/3/twelve_degree_icon@2x.png" alt="" style={{display:'block', border:0, lineHeight:'100%',width:'96px', height:'156px'}} width="96" height="156"/></a>
            </LilaMiddle>
            <LilaRight style={{backgroundColor: '#e4f1e1'}}>
              <img data-imagetype="External" src="https://stripe-images.s3.amazonaws.com/notifications/hosted/20180110/Header/Right.png" alt="" style={{display:'block', border:0, lineHeight:'100%', width:'252px', height:'156px'}} width="252" height="156"/>
            </LilaRight>
          </LilaMailHeader>
          <LilaSpace style={{height:'2rem'}}/>
          <LilaSpace2 style={{height:'1rem'}}/>

          <LilaOverview style={{paddingLeft:'64px', paddingRight:'64px', fontFamily:fonts.bcMedium}}>
            <DearLila style={{fontSize:'1.2rem',fontWeight:400, fontFamily:fonts.bcMedium,
              textAlign:'center',
              width:'100%'}}>
              {txt.news_success_1}
            </DearLila>

            <LilaSpace style={{height:'2rem'}}/>


            <LilaSpace style={{height:'2rem'}}></LilaSpace>
            <LilaAddition style={{paddingLeft:'0%', fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem', textAlign:'center'}}>{txt.news_success_2}</LilaAddition>
            <LilaSpace style={{height:'2rem'}}/>
            <LilaSpace3 style={{height:'0.6rem'}}></LilaSpace3>
            <DearLila style={{ fontSize:'0.9rem',fontWeight:200, fontFamily:fonts.bcMedium,
              textAlign:'center',
              width:'100%'}}>
              {txt.news_success_3}
            </DearLila>
            <LilaSpace3 style={{height:'0.6rem'}}></LilaSpace3>
            <LilaSpace3 style={{height:'0.6rem'}}></LilaSpace3>
            <DearLila style={{
              fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem', textAlign:'center',
              width:'100%'}}>
              {txt.news_success_5} <a href={'mailto:support@lilahase.com'}>support@lilahase.com</a>
            </DearLila>
            <LilaSpace3 style={{height:'2.4rem'}}></LilaSpace3>

            <DearLila style={{
              fontFamily:fonts.bcMedium,opacity:.5,fontSize:'0.8rem', textAlign:'center',
              width:'100%'}}>
              {txt.news_success_6}
            </DearLila>
            <LilaSpace3 style={{height:'2.4rem'}}></LilaSpace3>


            <Lh style={{width:'126px', marginLeft:'auto', marginRight:'auto', alignItems:'center'}}>
              <a href={'https://www.lilahase.com/'}>

                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
                  <defs>
                  </defs>
                  <path style={{fill:'#7e287e'}} className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
                  <path style={{fill:'#7e287e'}} className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
                  <path style={{fill:'#7e287e'}} className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
                  <path style={{fill:'#7e287e'}} className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path style={{fill:'#7e287e'}} className="cls-1"
                        d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path style={{fill:'#7e287e'}} className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
                        transform="translate(-14.76 -19.7)"/>
                  <polygon style={{fill:'none'}} className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
                  <polygon style={{fill:'#7e287e'}} className="cls-3"
                           points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
                  <polygon  style={{fill:'none'}} className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
                  <polygon style={{fill:'#7e287e'}} className="cls-3"
                           points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
                </svg>
              </a>
            </Lh>
            <LilaSpace style={{height:'2rem'}}></LilaSpace>
            <LilaLine style={{borderTop:'1px solid rgba(0,0,0,.1)'}}></LilaLine>
            <Footer style={{display:'flex', width:'100%'}}>
              <Left style={{paddingTop:'2.1rem', paddingBottom:'1.1rem',width:'100%', display:'flex', justifyContent:'center'}}>
                <a href={'https://instagram.com/lilahase_cbd'}><img width='32px' src="https://img.icons8.com/fluent/48/000000/instagram-new.png"/></a>
                <a href={'https://twitter.com/lilahase_cbd'}><img width='32px' src="https://img.icons8.com/color/48/000000/twitter-circled.png"/></a>

              </Left>
            </Footer>
          </LilaOverview>

        </LilaMain>

        <link href="//db.onlinewebfonts.com/c/02654c5f87934978cd2129477ad40869?family=billcorporatenarrowW00-medium" rel="stylesheet" type="text/css"/>

      </LilaInlay>

      </html>

    )
  }
}


export default NewsletterSuccess


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
