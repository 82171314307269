import * as React from 'react'
import styled from '@emotion/styled'
import ZkittlezImg2 from './ZkittlezImg2'
import ProductInfo from '../ProductInfo'
import Ripples from 'react-ripples'
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { fonts } from '../../../styles/variables'


import MaskImg1 from '../masks/MaskImg1'
import ZkittlezImg3 from './ZkittlezImg3'
import AhImg2 from '../alien-haze/AhImg2'
import AhImg3 from '../alien-haze/AhImg3'
import ShImg1 from '../sh/ShImg1'



const LilaCartItem = styled.div`
width:100%;
transition:all .4s ease;


`

const LilaProductInfo = styled.div`
width:100%;
display:flex;
align-items:center;
padding-left:18px;



`

const Img = styled.div`
width:180px;

`

const LilaRemove = styled(Ripples)`
 :hover{
 text-decoration:underline;
 }
 cursor:pointer;
width:100%;
display:flex;
justify-content:center;
border:1px solid var(--border);
height:40px;
align-items:center;
font-size: 0.97rem;
font-family:${fonts.bcHairLine};

`


class ZkittlezSmallCartThumb extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {

    }
  }



  componentDidMount() {

  }

  render() {
    return(

<Fade top>
        <LilaCartItem id={'component'}>
          <LilaProductInfo>
<ProductInfo link={this.props.link} plus={this.props.plus} minus={this.props.minus} price ={this.props.price} amount={this.props.amount} title={this.props.title} subTitle={this.props.subTitle} quantity={this.props.quantity}/>
            {this.props.id==='001' &&
          <Img>
            <ZkittlezImg2/></Img>
            }
            {this.props.id==='002' &&
          <Img>
            <ZkittlezImg3/></Img>
            }
            {this.props.id==='003' &&
          <Img>
            <AhImg2/></Img>
            }
            {this.props.id==='004' &&
          <Img>
            <AhImg3/></Img>
            }
            {this.props.id==='005' &&
          <Img>
            <ShImg1/></Img>
            }
            {this.props.id==='006' &&
          <Img>
            <ShImg1/></Img>
            }
            {this.props.id==='009' &&
          <Img>
            <MaskImg1/></Img>
            }
          </LilaProductInfo>
          <LilaRemove onClick={() => this.props.removeItem()}><FormattedMessage id={'cart_1'}/></LilaRemove>

        </LilaCartItem>
</Fade>


    )
  }
}


export default ZkittlezSmallCartThumb


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
