//import * as React from 'react'
import styled from '@emotion/styled'
const SVG = styled.svg`
transition:all .3s ease;
:hover{
.cls-2{
fill:var(--hase) !important;
}
}

.cls-1{
fill:var(--icon1);
}

.cls-2{
transition:all .3s ease;

fill:var(--icon1) !important;


}
`

const SVG3 = styled.svg`
transition:all .3s ease;
:hover{
.cls-2{
fill:var(--haase) !important;
}
}

.cls-1{
fill:rgba(53, 54, 57,.2);
}

.cls-2{
transition:all .3s ease;

fill:rgba(53, 54, 57,.2);


}
`

const SVG2 = styled.svg`
transition:all .3s ease;
:hover{
.cls-2{
//fill:var(--haase);
}
}

.cls-1{
fill:white;
}

.cls-2{
transition:all .3s ease;

fill:white !important;


}
`

const LilaIcon = styled.svg`
width:24px;
.cls-1{
fill:var(--icon1);
transition:all .3s ease;
}

.cls-2{
transition:all .3s ease;
fill:var(--icon1) !important;


.
`

const EcoBag = () =>
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270.01 315">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-1"
            d="M766.63,954.12c0-12.56-3-20.4-5.63-24.79-2.57,4.38-5.62,12.22-5.62,24.79,0,12.1,2.84,26,5.62,36.7C763.79,980.11,766.63,966.2,766.63,954.12Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M806.71,1016a45.55,45.55,0,0,0,16.87-2.82,45.79,45.79,0,0,0-16.87-2.8,120.77,120.77,0,0,0-23.46,2.81A121,121,0,0,0,806.71,1016Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M804.76,974.58c8.88-8.89,12.27-16.58,13.55-21.51-4.91,1.27-12.61,4.66-21.51,13.55-8.54,8.54-16.36,20.38-22,29.93C784.38,990.94,796.21,983.12,804.76,974.58Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M730.56,1040.82a36.21,36.21,0,0,0,8.76-6.43,62.53,62.53,0,0,0,8.06-10.39,62.24,62.24,0,0,0-10.39,8.06A35.83,35.83,0,0,0,730.56,1040.82Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M774.63,1024a62.24,62.24,0,0,0,8.06,10.39,35.83,35.83,0,0,0,8.76,6.43,36.21,36.21,0,0,0-6.43-8.76A62.53,62.53,0,0,0,774.63,1024Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M725.2,966.62c-8.88-8.88-16.57-12.28-21.51-13.55,1.28,4.91,4.67,12.61,13.56,21.51,8.54,8.54,20.38,16.36,29.93,22C741.57,987,733.75,975.16,725.2,966.62Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M890.38,1106c-6.69-8.92-8.24-22-4.12-35a209.09,209.09,0,0,0,5.41-21.27h-46.6a101,101,0,0,1-168.13,0H630.33a211.94,211.94,0,0,0,5.41,21.28c4.12,13,2.58,26-4.12,35a28.1,28.1,0,0,0-5.24,21.55c2.18,13.36,14.63,23.44,29,23.44H866.68c14.32,0,26.76-10.08,28.94-23.44a28.06,28.06,0,0,0-5.24-21.55Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M738.76,1013.19a121,121,0,0,0-23.46-2.81,45.55,45.55,0,0,0-16.87,2.81A45.76,45.76,0,0,0,715.3,1016,120.86,120.86,0,0,0,738.76,1013.19Z"
            transform="translate(-626 -836)"/>
      <path className="cls-1"
            d="M646.53,898.65c-3.41,11.53-7,24.8-10.26,38.6h40.67a101,101,0,0,1,168.13,0h40.67c-3.24-13.8-6.86-27.07-10.26-38.6-2.86-9.71-1.37-19.3,4-25.65a22.5,22.5,0,0,0-17.21-37H659.75a22.5,22.5,0,0,0-17.21,37C647.9,879.35,649.39,888.94,646.53,898.65Z"
            transform="translate(-626 -836)"/>
      <path className="cls-2"
            d="M684.77,1041.14a89.78,89.78,0,0,0,152.47,0,5.63,5.63,0,0,1,4.77-2.64h51.72a201.31,201.31,0,0,0,2.27-28.08c.14-18.56-3.2-40.53-7.76-61.92H842a5.63,5.63,0,0,1-4.77-2.64,89.78,89.78,0,0,0-152.47,0A5.63,5.63,0,0,1,680,948.5H633.77c-4.56,21.39-7.9,43.35-7.77,61.91a201.33,201.33,0,0,0,2.27,28.09H680A5.63,5.63,0,0,1,684.77,1041.14Zm-1.17-31.61c.92-1.07,9.56-10.41,31.7-10.41a113.39,113.39,0,0,1,17,1.48,144.82,144.82,0,0,1-23-18.07c-19.28-19.28-17.6-35.75-17.52-36.45a5.62,5.62,0,0,1,4.93-4.93c.72-.07,17.17-1.75,36.45,17.52a125.1,125.1,0,0,1,12.72,15.21,127.31,127.31,0,0,1-1.75-19.76c0-27.26,12.84-37.72,13.38-38.16a5.65,5.65,0,0,1,7,0c.55.44,13.39,10.9,13.39,38.16a126.13,126.13,0,0,1-1.76,19.76,125.17,125.17,0,0,1,12.73-15.21c19.27-19.27,35.74-17.59,36.45-17.52a5.63,5.63,0,0,1,4.93,4.93c.08.7,1.76,17.17-17.52,36.45a144.9,144.9,0,0,1-23,18.07,113.63,113.63,0,0,1,17-1.48c22.14,0,30.78,9.34,31.69,10.41a5.64,5.64,0,0,1,0,7.32c-.91,1.06-9.55,10.4-31.69,10.4a103.66,103.66,0,0,1-11.66-.79A46,46,0,0,1,806,1049.13a5.62,5.62,0,0,1-5.59,6.25c-.21,0-.41,0-.62,0a45.6,45.6,0,0,1-25-13,69.26,69.26,0,0,1-8.11-10.1v34.37a5.63,5.63,0,1,1-11.25,0v-34.37a69.26,69.26,0,0,1-8.11,10.1,45.63,45.63,0,0,1-25,13c-.21,0-.42,0-.62,0a5.62,5.62,0,0,1-5.59-6.25A45.8,45.8,0,0,1,727,1026.46a103.32,103.32,0,0,1-11.65.79c-22.14,0-30.78-9.34-31.7-10.4A5.64,5.64,0,0,1,683.6,1009.53Z"
            transform="translate(-626 -836)"/>
    </g>
  </SVG>

const Heart = () =>

  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.36 272.88">
    <defs>
      <style>

        </style>
        </defs>
        <g>
        <path className="cls-2" d="M1058.63,687.09a46.31,46.31,0,0,0-17.06,2.84,46,46,0,0,0,17.06,2.85,121.71,121.71,0,0,0,23.7-2.85A122.56,122.56,0,0,0,1058.63,687.09Z" transform="translate(-945.64 -527.91)"/>
        <path className="cls-2" d="M1068.64,642.88c-9-9-16.76-12.42-21.75-13.71,1.3,5,4.72,12.75,13.71,21.75,8.64,8.63,20.6,16.53,30.25,22.2C1085.18,663.47,1077.27,651.5,1068.64,642.88Z" transform="translate(-945.64 -527.91)"/>
        <path className="cls-2" d="M1162.74,629.16c-5,1.3-12.75,4.72-21.75,13.72-8.63,8.63-16.53,20.59-22.2,30.24,9.64-5.67,21.62-13.58,30.24-22.2C1158,641.93,1161.45,634.15,1162.74,629.16Z" transform="translate(-945.64 -527.91)"/>
        <path className="cls-2" d="M1099.13,630.24c0,12.23,2.87,26.27,5.69,37.1,2.81-10.84,5.68-24.89,5.68-37.1,0-12.7-3.07-20.62-5.68-25.06C1102.22,609.6,1099.13,617.53,1099.13,630.24Z" transform="translate(-945.64 -527.91)"/>
        <path className="cls-2" d="M1074,717.87a36.43,36.43,0,0,0,8.86-6.51,63.05,63.05,0,0,0,8.15-10.5,63.05,63.05,0,0,0-10.5,8.15A36.43,36.43,0,0,0,1074,717.87Z" transform="translate(-945.64 -527.91)"/>
        <path className="cls-2" d="M1118.59,700.86a63.05,63.05,0,0,0,8.15,10.5,36.43,36.43,0,0,0,8.86,6.51,36.69,36.69,0,0,0-6.51-8.86A63.46,63.46,0,0,0,1118.59,700.86Z" transform="translate(-945.64 -527.91)"/>
        <path className="cls-2" d="M1173,527.91a90,90,0,0,0-68.22,30.92,90,90,0,0,0-68.22-30.92,91.06,91.06,0,0,0-91,91c0,48.36,139.6,167.2,155.51,180.59a5.71,5.71,0,0,0,7.33,0C1124.39,786.07,1264,667.23,1264,618.87A91.07,91.07,0,0,0,1173,527.91Zm10,165.72c-.92,1.08-9.66,10.52-32,10.52a105.81,105.81,0,0,1-11.78-.8,46.66,46.66,0,0,1,11,22.9,5.76,5.76,0,0,1-1.64,4.66,5.67,5.67,0,0,1-4,1.66l-.63,0a46.27,46.27,0,0,1-25.28-13.14,70.69,70.69,0,0,1-8.2-10.2v40.43a5.69,5.69,0,0,1-11.37,0V709.2a70,70,0,0,1-8.19,10.2,46.27,46.27,0,0,1-25.28,13.14l-.64,0a5.68,5.68,0,0,1-5.65-6.32,46.44,46.44,0,0,1,11-22.9,105.81,105.81,0,0,1-11.78.8c-22.38,0-31.12-9.44-32-10.52a5.68,5.68,0,0,1,0-7.39c.92-1.08,9.66-10.52,32-10.52a114.82,114.82,0,0,1,17.19,1.49A146.4,146.4,0,0,1,1052.56,659c-19.48-19.48-17.79-36.14-17.72-36.84a5.7,5.7,0,0,1,5-5c.7-.08,17.38-1.75,36.84,17.72a127.79,127.79,0,0,1,12.86,15.37,127.71,127.71,0,0,1-1.78-20c0-27.55,13-38.13,13.53-38.57a5.71,5.71,0,0,1,7.06,0c.54.44,13.52,11,13.52,38.57a128.9,128.9,0,0,1-1.77,20A127.72,127.72,0,0,1,1133,634.84c19.47-19.47,36.17-17.81,36.85-17.72a5.69,5.69,0,0,1,5,5c.08.7,1.77,17.36-17.72,36.84a146.32,146.32,0,0,1-23.25,18.26,114.59,114.59,0,0,1,17.19-1.49c22.37,0,31.11,9.44,32,10.52A5.68,5.68,0,0,1,1183,693.63Z" transform="translate(-945.64 -527.91)"/>
        <path className="cls-2" d="M1127.3,689.93a122,122,0,0,0,23.71,2.85,46,46,0,0,0,17-2.85,46.16,46.16,0,0,0-17-2.84A122.66,122.66,0,0,0,1127.3,689.93Z" transform="translate(-945.64 -527.91)"/>
        </g>
        </SVG>



        const Spot = () =>

  <LilaIcon id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.02 77.81">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-1"
            d="M478.52,376.85A7,7,0,0,0,484,379.1a11.28,11.28,0,0,0,1.53-.13l-1.58,1.58a1.39,1.39,0,0,0,2,2l3.18-3.19v5a1.39,1.39,0,0,0,2.78,0v-5l3.19,3.17a1.39,1.39,0,1,0,2-2L495.39,379a11.71,11.71,0,0,0,1.55.13,7,7,0,0,0,5.43-2.25,1.4,1.4,0,0,0,0-1.71,6.62,6.62,0,0,0-3.68-2.05,8.31,8.31,0,0,0,3-5.28,1.41,1.41,0,0,0-1.22-1.45c-.29,0-2.91-.27-5.93,2.18,0-.3.07-.6.07-.9a8.06,8.06,0,0,0-3.44-6.74,1.37,1.37,0,0,0-1.46,0,7.91,7.91,0,0,0-3.37,7.6,8.4,8.4,0,0,0-5.89-2.14,1.4,1.4,0,0,0-1.21,1.45,8.27,8.27,0,0,0,3,5.28,6.57,6.57,0,0,0-3.67,2.05A1.37,1.37,0,0,0,478.52,376.85Z"
            transform="translate(-465.43 -348.19)"/>
      <path className="cls-1"
            d="M500.37,412.76a1.39,1.39,0,1,0-.4,2.75c7.58,1.07,9.93,3,9.93,3.54,0,1.25-6.62,4.17-19.46,4.17S471,420.3,471,419.05c0-.57,2.35-2.47,9.92-3.54a1.39,1.39,0,1,0-.39-2.75c-4.6.65-12.31,2.33-12.31,6.29,0,4.78,11.52,6.95,22.23,6.95s22.24-2.17,22.24-6.95C512.68,415.09,505,413.41,500.37,412.76Z"
            transform="translate(-465.43 -348.19)"/>

      <path className="cls-2"
            d="M490.44,420.44a1.37,1.37,0,0,0,1.13-.58c1-1.35,23.88-33.32,23.88-46.66a25,25,0,0,0-50,0c0,13.34,22.91,45.31,23.88,46.66A1.4,1.4,0,0,0,490.44,420.44Zm0-61.14a13.9,13.9,0,1,1-13.89,13.9A13.92,13.92,0,0,1,490.44,359.3Z"
            transform="translate(-465.43 -348.19)"/>
    </g>
  </LilaIcon>


const ThankYou = () =>

  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-1"
            d="M487.86,363.76a4.08,4.08,0,0,0-1.5.25,4.08,4.08,0,0,0,1.5.25,10.46,10.46,0,0,0,2.08-.25A10.46,10.46,0,0,0,487.86,363.76Z"
            transform="translate(-477.92 -349.76)"/>
      <path className="cls-1"
            d="M488.74,359.87a4.23,4.23,0,0,0-1.91-1.2,4.28,4.28,0,0,0,1.2,1.91,14.15,14.15,0,0,0,2.66,1.95A13.84,13.84,0,0,0,488.74,359.87Z"
            transform="translate(-477.92 -349.76)"/>
      <path className="cls-1"
            d="M497,358.66a4.44,4.44,0,0,0-1.92,1.21,13.84,13.84,0,0,0-2,2.66,13.84,13.84,0,0,0,2.66-1.95A4.38,4.38,0,0,0,497,358.66Z"
            transform="translate(-477.92 -349.76)"/>
      <path className="cls-1"
            d="M491.42,358.76a13.71,13.71,0,0,0,.5,3.26,13.71,13.71,0,0,0,.5-3.26,4.26,4.26,0,0,0-.5-2.2A4.23,4.23,0,0,0,491.42,358.76Z"
            transform="translate(-477.92 -349.76)"/>
      <path className="cls-1"
            d="M489.22,366.47a3.17,3.17,0,0,0,.77-.58,5,5,0,0,0,.72-.92,5.53,5.53,0,0,0-.92.72A3.15,3.15,0,0,0,489.22,366.47Z"
            transform="translate(-477.92 -349.76)"/>
      <path className="cls-1"
            d="M493.13,365a5.53,5.53,0,0,0,.72.92,3.21,3.21,0,0,0,.78.58,3.15,3.15,0,0,0-.57-.78A5.61,5.61,0,0,0,493.13,365Z"
            transform="translate(-477.92 -349.76)"/>
      <path className="cls-1"
            d="M497.92,349.76a7.91,7.91,0,0,0-6,2.72,7.9,7.9,0,0,0-6-2.72,8,8,0,0,0-8,8c0,4.25,12.28,14.7,13.68,15.88a.49.49,0,0,0,.32.12.47.47,0,0,0,.32-.12c1.4-1.18,13.68-11.63,13.68-15.88A8,8,0,0,0,497.92,349.76Zm.88,14.57a3.62,3.62,0,0,1-2.82.93,9.22,9.22,0,0,1-1-.07,4.17,4.17,0,0,1,1,2,.5.5,0,0,1-.14.41.51.51,0,0,1-.36.15h-.05a4.13,4.13,0,0,1-2.23-1.16,6.46,6.46,0,0,1-.72-.9v3.56a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5V365.7a5.27,5.27,0,0,1-.72.9,4.11,4.11,0,0,1-2.22,1.16h-.06a.5.5,0,0,1-.49-.56,4,4,0,0,1,1-2,9.24,9.24,0,0,1-1,.07,3.62,3.62,0,0,1-2.82-.93.5.5,0,0,1,0-.65,3.68,3.68,0,0,1,2.82-.92,9.72,9.72,0,0,1,1.51.13,12.68,12.68,0,0,1-2-1.61c-1.72-1.71-1.57-3.17-1.56-3.24a.49.49,0,0,1,.44-.43,4.25,4.25,0,0,1,3.24,1.55,12.54,12.54,0,0,1,1.13,1.36,10.66,10.66,0,0,1-.16-1.76,4.27,4.27,0,0,1,1.19-3.39.48.48,0,0,1,.62,0,4.27,4.27,0,0,1,1.19,3.39,11.84,11.84,0,0,1-.15,1.76,10.59,10.59,0,0,1,1.13-1.36,4.25,4.25,0,0,1,3.24-1.55.49.49,0,0,1,.44.43,4.32,4.32,0,0,1-1.56,3.24,12.75,12.75,0,0,1-2,1.61,9.72,9.72,0,0,1,1.51-.13,3.68,3.68,0,0,1,2.82.92A.5.5,0,0,1,498.8,364.33Z"
            transform="translate(-477.92 -349.76)"/>
      <path className="cls-1"
            d="M493.9,364a10.46,10.46,0,0,0,2.08.25,4,4,0,0,0,1.5-.25,4,4,0,0,0-1.5-.25A10.46,10.46,0,0,0,493.9,364Z"
            transform="translate(-477.92 -349.76)"/>
    </g>
  </SVG>


const Shop = () =>
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-1"
            d="M488.92,375.76h6v-10h-6Zm1-8.5a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5Z"
            transform="translate(-477.92 -347.76)"/>
      <rect className="cls-1" x="5" y="18" width="3" height="5"/>
      <rect className="cls-1" x="13" y="20" width="2" height="2"/>
      <rect className="cls-1" x="20" y="18" width="3" height="5"/>
      <path className="cls-1" d="M496.81,358.76h8.92L501.87,351a.51.51,0,0,0-.45-.28h-3.18a7,7,0,0,1-1.43,8Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-1"
            d="M484.92,353.76a6.88,6.88,0,0,1,.69-3h-3.19a.49.49,0,0,0-.44.28l-3.87,7.72H487A7,7,0,0,1,484.92,353.76Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-1"
            d="M495.51,359.76a6.93,6.93,0,0,1-7.17,0H477.92v1a2,2,0,0,0,2,2,2,2,0,0,0,1.5-.68,2,2,0,0,0,1.5.68,2,2,0,0,0,1.5-.68,2,2,0,0,0,1.5.68,2,2,0,0,0,1.5-.68,2,2,0,0,0,1.5.68,2,2,0,0,0,1.5-.68,2,2,0,0,0,1.5.68,2,2,0,0,0,1.5-.68,2,2,0,0,0,1.5.68,2,2,0,0,0,1.5-.68,2,2,0,0,0,1.5.68,2,2,0,0,0,1.5-.68,2,2,0,0,0,1.5.68,2,2,0,0,0,1.5-.68,2,2,0,0,0,3.5-1.32v-1Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-1"
            d="M505.42,374.76h-1.5v-11a3,3,0,0,0-3,0,3,3,0,0,0-3,0,3,3,0,0,0-3,0,3,3,0,0,0-3,0,3,3,0,0,0-3,0,3,3,0,0,0-3,0,3,3,0,0,0-3,0,3,3,0,0,0-3,0v11h-1.5a.5.5,0,0,0,0,1h9.5v-10.5a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5v10.5h9.5a.5.5,0,1,0,0-1Zm-18.5-3.5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5v-6a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5Zm15,0a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5v-6a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-1"
            d="M492.41,351.76a1.9,1.9,0,0,0-.5-1.34,2,2,0,0,0-.5,1.34,5,5,0,0,0,.5,1.91A4.87,4.87,0,0,0,492.41,351.76Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-2"
            d="M494.69,352.45a2.53,2.53,0,0,0-.72.44,6.34,6.34,0,0,0-.71.75,4.58,4.58,0,0,0,.86-.57A2.84,2.84,0,0,0,494.69,352.45Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-2"
            d="M489.72,353.07a4.93,4.93,0,0,0,.86.57,5,5,0,0,0-.71-.75,2.5,2.5,0,0,0-.71-.44A2.76,2.76,0,0,0,489.72,353.07Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-2"
            d="M491.92,359.76a6,6,0,1,0-6-6A6,6,0,0,0,491.92,359.76Zm-4.29-5.3a2.38,2.38,0,0,1,1.32-.74,3,3,0,0,1-1.07-1.9.5.5,0,0,1,.44-.52,3,3,0,0,1,2.12.77c0-.1,0-.21,0-.31a2.93,2.93,0,0,1,1.24-2.43.55.55,0,0,1,.53,0,2.93,2.93,0,0,1,1.23,2.43c0,.11,0,.22,0,.32a3,3,0,0,1,2.13-.78.5.5,0,0,1,.44.52,3,3,0,0,1-1.07,1.9,2.38,2.38,0,0,1,1.32.74.47.47,0,0,1,0,.61,2.45,2.45,0,0,1-1.95.81,5.27,5.27,0,0,1-.56,0l.57.57a.49.49,0,0,1,0,.7.51.51,0,0,1-.36.15.49.49,0,0,1-.35-.15L492.42,356v1.79a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5V356l-1.14,1.14a.5.5,0,0,1-.71,0,.48.48,0,0,1,0-.7l.57-.57a5.28,5.28,0,0,1-.55,0,2.5,2.5,0,0,1-2-.81A.5.5,0,0,1,487.63,354.46Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-2" d="M493.4,354.77a3.06,3.06,0,0,0,1.54,0A2.84,2.84,0,0,0,493.4,354.77Z"
            transform="translate(-477.92 -347.76)"/>
      <path className="cls-2" d="M490.44,354.77a2.81,2.81,0,0,0-1.53,0A3,3,0,0,0,490.44,354.77Z"
            transform="translate(-477.92 -347.76)"/>
    </g>
  </SVG>


const Weight = () =>

  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.7 241.69">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-1" d="M578.9,607.29a4.28,4.28,0,0,0,4.32,4.32h17.26a4.28,4.28,0,0,0,4.32-4.32V603H578.9Z"
            transform="translate(-553 -369.92)"/>
      <path className="cls-1" d="M742.91,607.29a4.27,4.27,0,0,0,4.31,4.32h17.27a4.27,4.27,0,0,0,4.31-4.32V603H742.91Z"
            transform="translate(-553 -369.92)"/>
      <path className="cls-1"
            d="M790.38,490.76H557.32a4.31,4.31,0,0,0-4.32,4.32,21.6,21.6,0,0,0,21.58,21.58H773.12a21.6,21.6,0,0,0,21.58-21.58A4.31,4.31,0,0,0,790.38,490.76Z"
            transform="translate(-553 -369.92)"/>
      <path className="cls-1"
            d="M783.54,542.55l-16-16a4.31,4.31,0,0,0-3-1.26H583.22a4.32,4.32,0,0,0-3.06,1.26l-16,16H783.54Z"
            transform="translate(-553 -369.92)"/>
      <rect className="cls-1" x="60.42" y="181.27" width="120.85" height="17.26"/>
      <path className="cls-1"
            d="M742.91,572.77a4.31,4.31,0,0,1-4.32,4.31H609.11a4.31,4.31,0,0,1-4.31-4.31V551.19H561.64V590a4.31,4.31,0,0,0,4.31,4.32h215.8a4.31,4.31,0,0,0,4.32-4.32V551.19H742.91Z"
            transform="translate(-553 -369.92)"/>
      <path className="cls-2"
            d="M638.78,456.23a80.87,80.87,0,0,0,9-.6A35.29,35.29,0,0,0,639.35,473a4.33,4.33,0,0,0,1.24,3.53,4.29,4.29,0,0,0,3,1.26l.48,0a35.13,35.13,0,0,0,19.2-10,54.42,54.42,0,0,0,6.22-7.75v22.06h8.63V460.07a53.06,53.06,0,0,0,6.22,7.75,35.12,35.12,0,0,0,19.19,10l.48,0a4.3,4.3,0,0,0,3.06-1.26,4.37,4.37,0,0,0,1.24-3.53A35.29,35.29,0,0,0,700,455.63a80.53,80.53,0,0,0,8.94.6c17,0,23.62-7.16,24.32-8a4.32,4.32,0,0,0,0-5.61c-.7-.82-7.33-8-24.32-8a88.14,88.14,0,0,0-13,1.13,110.29,110.29,0,0,0,17.65-13.86c14.8-14.79,13.51-27.43,13.46-28a4.35,4.35,0,0,0-3.8-3.8c-.51-.07-13.19-1.32-28,13.46a97.49,97.49,0,0,0-9.76,11.66,97.11,97.11,0,0,0,1.35-15.15c0-20.92-9.85-28.95-10.27-29.29a4.35,4.35,0,0,0-5.36,0c-.41.34-10.27,8.37-10.27,29.29a97.11,97.11,0,0,0,1.35,15.15,96.56,96.56,0,0,0-9.76-11.66c-14.78-14.78-27.44-13.52-28-13.46a4.33,4.33,0,0,0-3.79,3.8c-.06.53-1.34,13.17,13.45,28a110.29,110.29,0,0,0,17.65,13.86,88,88,0,0,0-13.05-1.13c-17,0-23.61,7.17-24.32,8a4.32,4.32,0,0,0,0,5.61C615.17,449.07,621.8,456.23,638.78,456.23Z"
            transform="translate(-553 -369.92)"/>
    </g>
  </SVG>


const GrowMethod = () =>

  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.23 28">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <rect className="cls-1" x="1.72" y="17.99" width="12" height="3" rx="0.5"/>
      <rect className="cls-1" x="15.23" y="18" width="12" height="3" rx="0.5"/>
      <path className="cls-1" d="M495.55,375.35a.51.51,0,0,0,.5.43h7a.49.49,0,0,0,.49-.43l.8-5.57h-9.58Z"
            transform="translate(-478.32 -347.79)"/>
      <path className="cls-2"
            d="M497,359.63a6.13,6.13,0,0,0,2,1v4.12h1v-4.13a6.21,6.21,0,0,0,2-1,2.52,2.52,0,0,0,1-1.88.5.5,0,0,0-.4-.47,2.4,2.4,0,0,0-1.62.36c0-.12,0-.24,0-.36a2.88,2.88,0,0,0-1.24-2.42.51.51,0,0,0-.53,0,2.91,2.91,0,0,0-1.23,2.42,2.19,2.19,0,0,0,0,.36,2.42,2.42,0,0,0-1.63-.36.5.5,0,0,0-.39.47A2.48,2.48,0,0,0,497,359.63Z"
            transform="translate(-478.32 -347.79)"/>
      <path className="cls-2"
            d="M483.56,359h.26a7.65,7.65,0,0,0-1.24,1,4.4,4.4,0,0,0-1.29,2.58.46.46,0,0,0,.15.38.47.47,0,0,0,.35.15h0a4.4,4.4,0,0,0,2.58-1.29,8.15,8.15,0,0,0,1.14-1.49v4.53h1v-4.53a8.15,8.15,0,0,0,1.14,1.49,4.4,4.4,0,0,0,2.58,1.29h0a.47.47,0,0,0,.35-.15.46.46,0,0,0,.15-.38,4.33,4.33,0,0,0-1.29-2.58,7.35,7.35,0,0,0-1.26-1h.24a10.83,10.83,0,0,0,2.37-.22,5.44,5.44,0,0,0,3-1.85.51.51,0,0,0,.09-.41.54.54,0,0,0-.26-.33,5.36,5.36,0,0,0-2.74-.46l.17-.16a6.73,6.73,0,0,0,2-3.74.52.52,0,0,0-.14-.4.53.53,0,0,0-.41-.15,6.73,6.73,0,0,0-3.74,2,11.41,11.41,0,0,0-1,1.22,10.62,10.62,0,0,0,.25-2.11,6.56,6.56,0,0,0-1.64-4.35.51.51,0,0,0-.71,0,6.48,6.48,0,0,0-1.65,4.35,9.85,9.85,0,0,0,.24,2,9.73,9.73,0,0,0-.83-.95,6.25,6.25,0,0,0-3.62-1.84.46.46,0,0,0-.39.15.47.47,0,0,0-.15.39,6.25,6.25,0,0,0,1.84,3.62h0a5.29,5.29,0,0,0-2.55.48.49.49,0,0,0-.25.33.52.52,0,0,0,.08.4,5.18,5.18,0,0,0,3,1.84A10.37,10.37,0,0,0,483.56,359Z"
            transform="translate(-478.32 -347.79)"/>
      <path className="cls-1" d="M482.05,375.35a.49.49,0,0,0,.49.43h7a.51.51,0,0,0,.5-.43l.79-5.57h-9.58Z"
            transform="translate(-478.32 -347.79)"/>
    </g>
  </SVG>


const Glastiegel = () =>

  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.35 174.24">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <rect className="cls-1" x="6.22" width="87.12" height="31.11" rx="9.33"/>
      <path className="cls-2"
            d="M628.13,473.63a3.13,3.13,0,0,0,0-4.83c-.4-.32-9.8-7.83-25.1-7.83a68.71,68.71,0,0,0-9.88.83,65.69,65.69,0,0,0,10.38-8.19,40.54,40.54,0,0,0,11.92-26.38,3.14,3.14,0,0,0-.92-2.22,3.11,3.11,0,0,0-2.2-.91h0A40.52,40.52,0,0,0,585.93,436,65.35,65.35,0,0,0,578,446a59.51,59.51,0,0,0,.59-7.82c0-14.65-7.56-23.65-7.89-24a3.21,3.21,0,0,0-4.72,0c-.33.37-7.89,9.37-7.89,24h0a32,32,0,0,0-13.32-9.1,3.06,3.06,0,0,0-2.52.35,3.1,3.1,0,0,0-1.42,2.11,32.49,32.49,0,0,0,4.93,21.12,66.16,66.16,0,0,0,9.92,11.68,48.92,48.92,0,0,0-9.78-1.2,27.19,27.19,0,0,0-17,5.7,3.1,3.1,0,0,0,0,4.69,27.26,27.26,0,0,0,17,5.7,53.83,53.83,0,0,0,11.56-1.56l-26.52,26.52a3.11,3.11,0,0,0,4.4,4.4l26.52-26.52a53.75,53.75,0,0,0-1.56,11.56,27.22,27.22,0,0,0,5.7,17,3.08,3.08,0,0,0,2.34,1.07,3.11,3.11,0,0,0,2.35-1.07,27.29,27.29,0,0,0,5.7-17,50.14,50.14,0,0,0-1.24-10,71.5,71.5,0,0,0,12.45,10.57,35.21,35.21,0,0,0,18.52,5.42,21.7,21.7,0,0,0,3-.18,3.13,3.13,0,0,0,2.5-4,33.28,33.28,0,0,0-10.08-14.08c.48,0,1,0,1.46,0C618.33,481.46,627.73,474,628.13,473.63Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-1"
            d="M541.18,485.17a32.56,32.56,0,0,1-16.39-6.93,9.34,9.34,0,0,1,0-14.07,32.71,32.71,0,0,1,16.49-6.92c-.28-.43-.56-.85-.82-1.26a38.43,38.43,0,0,1-5.78-25.48,9.33,9.33,0,0,1,11.76-7.39,32.11,32.11,0,0,1,6.9,3.2c.36-1.38.77-2.66,1.2-3.89H479.92v74.68h49.32Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-1"
            d="M561.24,410.14a9.44,9.44,0,0,1,14.18,0,36.54,36.54,0,0,1,4,6.08h0a21.68,21.68,0,0,0-9.62-17.46l-2-1.37a15.47,15.47,0,0,1-6.85-12.28H498.51a15.44,15.44,0,0,1-6.85,12.28l-2,1.37A21.68,21.68,0,0,0,480,416.21h77.28A36.5,36.5,0,0,1,561.24,410.14Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-1"
            d="M568.33,518a9.32,9.32,0,0,1-7-3.21,31.92,31.92,0,0,1-5.83-11.41h-6L539.73,513a9.28,9.28,0,0,1-15.33-9.69H479.92v9.34a9.35,9.35,0,0,0,9.34,9.33h80.89a9.35,9.35,0,0,0,9.34-9.33V508a28.83,28.83,0,0,1-4.13,6.73A9.33,9.33,0,0,1,568.33,518Z"
            transform="translate(-479.92 -347.76)"/>
    </g>
  </SVG>


const Cert = () =>
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 27.99">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-2"
            d="M493.13,364a5.61,5.61,0,0,0,.72.93,3.15,3.15,0,0,0,.78.57,3.15,3.15,0,0,0-.57-.78A6.76,6.76,0,0,0,493.13,364Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M496,363.28a4,4,0,0,0,1.5-.25,4,4,0,0,0-1.5-.25,11.26,11.26,0,0,0-2.08.25A10.46,10.46,0,0,0,496,363.28Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M487.86,362.78a4.08,4.08,0,0,0-1.5.25,4.08,4.08,0,0,0,1.5.25A10.65,10.65,0,0,0,490,363,11.47,11.47,0,0,0,487.86,362.78Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M489.22,365.49a3.11,3.11,0,0,0,.77-.57,5.12,5.12,0,0,0,.72-.93,6.11,6.11,0,0,0-.92.72A3.15,3.15,0,0,0,489.22,365.49Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M488.74,358.89a4.23,4.23,0,0,0-1.91-1.2,4.23,4.23,0,0,0,1.2,1.91,13.72,13.72,0,0,0,2.66,1.95A14.15,14.15,0,0,0,488.74,358.89Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M495.81,359.6a4.37,4.37,0,0,0,1.21-1.91,4.29,4.29,0,0,0-1.92,1.2,14.15,14.15,0,0,0-2,2.66A13.43,13.43,0,0,0,495.81,359.6Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M491.42,357.78a13.8,13.8,0,0,0,.5,3.27,13.8,13.8,0,0,0,.5-3.27,6.16,6.16,0,0,0-.5-2.47A6.16,6.16,0,0,0,491.42,357.78Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M483.92,355.42v5.91a10.48,10.48,0,0,0,4.68,8.73l3.32,2.22,3.33-2.22a10.49,10.49,0,0,0,4.67-8.73v-5.91a19.68,19.68,0,0,1-8-3.75A19.62,19.62,0,0,1,483.92,355.42Zm9.5,2.36a11.72,11.72,0,0,1-.15,1.76,9.86,9.86,0,0,1,1.13-1.35,4.24,4.24,0,0,1,3.24-1.56.51.51,0,0,1,.44.44,4.34,4.34,0,0,1-1.56,3.24,12.16,12.16,0,0,1-2,1.6,11.13,11.13,0,0,1,1.51-.13,3.62,3.62,0,0,1,2.82.93.5.5,0,0,1,0,.65,3.68,3.68,0,0,1-2.82.92,9.22,9.22,0,0,1-1-.07,4.19,4.19,0,0,1,1,2,.52.52,0,0,1-.14.41.54.54,0,0,1-.36.14h-.05a4.07,4.07,0,0,1-2.23-1.15,7.1,7.1,0,0,1-.72-.9v3.55a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-3.55a5.69,5.69,0,0,1-.72.9,4.06,4.06,0,0,1-2.22,1.15h-.06a.5.5,0,0,1-.35-.14.49.49,0,0,1-.14-.41,4,4,0,0,1,1-2,9.24,9.24,0,0,1-1,.07,3.68,3.68,0,0,1-2.82-.92.5.5,0,0,1,0-.65,3.62,3.62,0,0,1,2.82-.93,11.13,11.13,0,0,1,1.51.13,12.09,12.09,0,0,1-2-1.6c-1.72-1.72-1.57-3.18-1.56-3.24a.51.51,0,0,1,.44-.44c.06,0,1.52-.16,3.24,1.56a11.53,11.53,0,0,1,1.13,1.35,10.57,10.57,0,0,1-.16-1.76,6.46,6.46,0,0,1,1.11-3.8.51.51,0,0,1,.79,0A6.57,6.57,0,0,1,493.42,357.78Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-1"
            d="M502.47,352.79A16.35,16.35,0,0,1,492.3,348a.52.52,0,0,0-.76,0,16.31,16.31,0,0,1-10.17,4.83.5.5,0,0,0-.45.49v8.05a13.48,13.48,0,0,0,6,11.23l4.72,3.14a.45.45,0,0,0,.27.08.46.46,0,0,0,.28-.08l4.71-3.14a13.48,13.48,0,0,0,6-11.23v-8.05A.49.49,0,0,0,502.47,352.79Zm-1.55,8.54a11.45,11.45,0,0,1-5.12,9.56l-3.6,2.41a.54.54,0,0,1-.28.08.53.53,0,0,1-.27-.08L488,370.89a11.47,11.47,0,0,1-5.12-9.56V355a.49.49,0,0,1,.41-.49,18.67,18.67,0,0,0,8.27-3.88.51.51,0,0,1,.64,0,18.72,18.72,0,0,0,8.28,3.88.49.49,0,0,1,.4.49Z"
            transform="translate(-480.92 -347.79)"/>
    </g>
  </SVG>

const Cert2 = () =>
  <SVG2 id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 27.99">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-2"
            d="M493.13,364a5.61,5.61,0,0,0,.72.93,3.15,3.15,0,0,0,.78.57,3.15,3.15,0,0,0-.57-.78A6.76,6.76,0,0,0,493.13,364Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M496,363.28a4,4,0,0,0,1.5-.25,4,4,0,0,0-1.5-.25,11.26,11.26,0,0,0-2.08.25A10.46,10.46,0,0,0,496,363.28Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M487.86,362.78a4.08,4.08,0,0,0-1.5.25,4.08,4.08,0,0,0,1.5.25A10.65,10.65,0,0,0,490,363,11.47,11.47,0,0,0,487.86,362.78Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M489.22,365.49a3.11,3.11,0,0,0,.77-.57,5.12,5.12,0,0,0,.72-.93,6.11,6.11,0,0,0-.92.72A3.15,3.15,0,0,0,489.22,365.49Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M488.74,358.89a4.23,4.23,0,0,0-1.91-1.2,4.23,4.23,0,0,0,1.2,1.91,13.72,13.72,0,0,0,2.66,1.95A14.15,14.15,0,0,0,488.74,358.89Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M495.81,359.6a4.37,4.37,0,0,0,1.21-1.91,4.29,4.29,0,0,0-1.92,1.2,14.15,14.15,0,0,0-2,2.66A13.43,13.43,0,0,0,495.81,359.6Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M491.42,357.78a13.8,13.8,0,0,0,.5,3.27,13.8,13.8,0,0,0,.5-3.27,6.16,6.16,0,0,0-.5-2.47A6.16,6.16,0,0,0,491.42,357.78Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-2"
            d="M483.92,355.42v5.91a10.48,10.48,0,0,0,4.68,8.73l3.32,2.22,3.33-2.22a10.49,10.49,0,0,0,4.67-8.73v-5.91a19.68,19.68,0,0,1-8-3.75A19.62,19.62,0,0,1,483.92,355.42Zm9.5,2.36a11.72,11.72,0,0,1-.15,1.76,9.86,9.86,0,0,1,1.13-1.35,4.24,4.24,0,0,1,3.24-1.56.51.51,0,0,1,.44.44,4.34,4.34,0,0,1-1.56,3.24,12.16,12.16,0,0,1-2,1.6,11.13,11.13,0,0,1,1.51-.13,3.62,3.62,0,0,1,2.82.93.5.5,0,0,1,0,.65,3.68,3.68,0,0,1-2.82.92,9.22,9.22,0,0,1-1-.07,4.19,4.19,0,0,1,1,2,.52.52,0,0,1-.14.41.54.54,0,0,1-.36.14h-.05a4.07,4.07,0,0,1-2.23-1.15,7.1,7.1,0,0,1-.72-.9v3.55a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-3.55a5.69,5.69,0,0,1-.72.9,4.06,4.06,0,0,1-2.22,1.15h-.06a.5.5,0,0,1-.35-.14.49.49,0,0,1-.14-.41,4,4,0,0,1,1-2,9.24,9.24,0,0,1-1,.07,3.68,3.68,0,0,1-2.82-.92.5.5,0,0,1,0-.65,3.62,3.62,0,0,1,2.82-.93,11.13,11.13,0,0,1,1.51.13,12.09,12.09,0,0,1-2-1.6c-1.72-1.72-1.57-3.18-1.56-3.24a.51.51,0,0,1,.44-.44c.06,0,1.52-.16,3.24,1.56a11.53,11.53,0,0,1,1.13,1.35,10.57,10.57,0,0,1-.16-1.76,6.46,6.46,0,0,1,1.11-3.8.51.51,0,0,1,.79,0A6.57,6.57,0,0,1,493.42,357.78Z"
            transform="translate(-480.92 -347.79)"/>
      <path className="cls-1"
            d="M502.47,352.79A16.35,16.35,0,0,1,492.3,348a.52.52,0,0,0-.76,0,16.31,16.31,0,0,1-10.17,4.83.5.5,0,0,0-.45.49v8.05a13.48,13.48,0,0,0,6,11.23l4.72,3.14a.45.45,0,0,0,.27.08.46.46,0,0,0,.28-.08l4.71-3.14a13.48,13.48,0,0,0,6-11.23v-8.05A.49.49,0,0,0,502.47,352.79Zm-1.55,8.54a11.45,11.45,0,0,1-5.12,9.56l-3.6,2.41a.54.54,0,0,1-.28.08.53.53,0,0,1-.27-.08L488,370.89a11.47,11.47,0,0,1-5.12-9.56V355a.49.49,0,0,1,.41-.49,18.67,18.67,0,0,0,8.27-3.88.51.51,0,0,1,.64,0,18.72,18.72,0,0,0,8.28,3.88.49.49,0,0,1,.4.49Z"
            transform="translate(-480.92 -347.79)"/>
    </g>
  </SVG2>


const Stats = () =>
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.06 134.24">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-2"
            d="M507.72,440.35a25.39,25.39,0,0,0,4.14,2.74,24.87,24.87,0,0,0-3.42-3.6,13.45,13.45,0,0,0-3.42-2.14A13.4,13.4,0,0,0,507.72,440.35Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2" d="M503.81,448.47a14.24,14.24,0,0,0,7.37,0A14,14,0,0,0,503.81,448.47Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2"
            d="M518.28,419.67a24,24,0,1,0,24,24A24,24,0,0,0,518.28,419.67Zm19.36,14.68c0,.5-.42,4.82-5.13,9.11a11.5,11.5,0,0,1,6.34,3.53,2.38,2.38,0,0,1,0,3c-.12.16-3.11,3.87-9.37,3.87a18.33,18.33,0,0,1-2.65-.22l2.73,2.73a2.4,2.4,0,1,1-3.39,3.39l-5.5-5.49v8.59a2.4,2.4,0,1,1-4.79,0v-8.56l-5.5,5.47a2.4,2.4,0,1,1-3.38-3.4l2.74-2.73a18.47,18.47,0,0,1-2.66.22c-6.27,0-9.26-3.71-9.38-3.87a2.4,2.4,0,0,1,0-3,11.5,11.5,0,0,1,6.34-3.53c-4.71-4.29-5.1-8.61-5.13-9.11a2.39,2.39,0,0,1,2.1-2.49c.5-.07,5-.47,10.24,3.75-.05-.52-.12-1-.12-1.55a13.89,13.89,0,0,1,5.92-11.63,2.4,2.4,0,0,1,2.53,0,13.89,13.89,0,0,1,5.93,11.63c0,.49-.08,1-.12,1.49,5.17-4.15,9.66-3.77,10.16-3.69A2.39,2.39,0,0,1,537.64,434.35Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2" d="M525.38,448.47a14.2,14.2,0,0,0,7.36,0A14,14,0,0,0,525.38,448.47Z"
            transform="translate(-479.92 -347.76)"/>
      <polygon className="cls-1" points="86.3 1.4 86.3 28.77 113.66 28.77 86.3 1.4"/>
      <path className="cls-2"
            d="M520.71,434.06a9.4,9.4,0,0,0-2.39-6.43,9.38,9.38,0,0,0-2.4,6.43,24,24,0,0,0,2.4,9.16A24.15,24.15,0,0,0,520.71,434.06Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2"
            d="M528.83,440.35a13.73,13.73,0,0,0,2.71-3,13.32,13.32,0,0,0-3.43,2.14,26,26,0,0,0-3.42,3.6A25.61,25.61,0,0,0,528.83,440.35Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-1"
            d="M563.82,381.32a2.39,2.39,0,0,1-2.39-2.4V347.76H482.32a2.39,2.39,0,0,0-2.4,2.4V479.6a2.39,2.39,0,0,0,2.4,2.4H592.59a2.4,2.4,0,0,0,2.4-2.4V381.32Zm14.39,4.79a2.4,2.4,0,0,1,0,4.8H539.85a2.4,2.4,0,1,1,0-4.8Zm-57.54-4.79a2.4,2.4,0,0,1,0-4.79h33.56a2.4,2.4,0,1,1,0,4.79Zm-24-19.18h57.53a2.4,2.4,0,0,1,0,4.8H496.7a2.4,2.4,0,0,1,0-4.8Zm0,14.39h14.39a2.4,2.4,0,0,1,0,4.79H496.7a2.4,2.4,0,0,1,0-4.79Zm0,9.58h33.56a2.4,2.4,0,1,1,0,4.8H496.7a2.4,2.4,0,0,1,0-4.8Zm0,9.59h81.51a2.4,2.4,0,0,1,0,4.8H496.7a2.4,2.4,0,0,1,0-4.8Zm83.9,12a2.4,2.4,0,0,1-2.39,2.4h-48a2.4,2.4,0,0,1,0-4.8h48A2.39,2.39,0,0,1,580.6,407.69Zm-83.9-2.4h24a2.4,2.4,0,1,1,0,4.8h-24a2.4,2.4,0,0,1,0-4.8Zm21.58,67.12A28.77,28.77,0,1,1,547,443.65,28.79,28.79,0,0,1,518.28,472.41ZM547,417.28a2.39,2.39,0,0,1,2.4-2.4h28.77a2.4,2.4,0,0,1,0,4.79H549.44A2.39,2.39,0,0,1,547,417.28Zm33.56,9.59a2.39,2.39,0,0,1-2.39,2.39h-24a2.4,2.4,0,0,1,0-4.79h24A2.39,2.39,0,0,1,580.6,426.87Zm-28.76,9.58a2.39,2.39,0,0,1,2.39-2.39h24a2.4,2.4,0,0,1,0,4.79h-24A2.39,2.39,0,0,1,551.84,436.45ZM583,467.62h-9.59a2.4,2.4,0,0,1,0-4.8H583a2.4,2.4,0,0,1,0,4.8Zm0-9.59H559a2.4,2.4,0,1,1,0-4.8h24a2.4,2.4,0,1,1,0,4.8Z"
            transform="translate(-479.92 -347.76)"/>
    </g>
  </SVG>


const Stats2 = () =>
  <SVG2 id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.06 134.24">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-2"
            d="M507.72,440.35a25.39,25.39,0,0,0,4.14,2.74,24.87,24.87,0,0,0-3.42-3.6,13.45,13.45,0,0,0-3.42-2.14A13.4,13.4,0,0,0,507.72,440.35Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2" d="M503.81,448.47a14.24,14.24,0,0,0,7.37,0A14,14,0,0,0,503.81,448.47Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2"
            d="M518.28,419.67a24,24,0,1,0,24,24A24,24,0,0,0,518.28,419.67Zm19.36,14.68c0,.5-.42,4.82-5.13,9.11a11.5,11.5,0,0,1,6.34,3.53,2.38,2.38,0,0,1,0,3c-.12.16-3.11,3.87-9.37,3.87a18.33,18.33,0,0,1-2.65-.22l2.73,2.73a2.4,2.4,0,1,1-3.39,3.39l-5.5-5.49v8.59a2.4,2.4,0,1,1-4.79,0v-8.56l-5.5,5.47a2.4,2.4,0,1,1-3.38-3.4l2.74-2.73a18.47,18.47,0,0,1-2.66.22c-6.27,0-9.26-3.71-9.38-3.87a2.4,2.4,0,0,1,0-3,11.5,11.5,0,0,1,6.34-3.53c-4.71-4.29-5.1-8.61-5.13-9.11a2.39,2.39,0,0,1,2.1-2.49c.5-.07,5-.47,10.24,3.75-.05-.52-.12-1-.12-1.55a13.89,13.89,0,0,1,5.92-11.63,2.4,2.4,0,0,1,2.53,0,13.89,13.89,0,0,1,5.93,11.63c0,.49-.08,1-.12,1.49,5.17-4.15,9.66-3.77,10.16-3.69A2.39,2.39,0,0,1,537.64,434.35Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2" d="M525.38,448.47a14.2,14.2,0,0,0,7.36,0A14,14,0,0,0,525.38,448.47Z"
            transform="translate(-479.92 -347.76)"/>
      <polygon className="cls-1" points="86.3 1.4 86.3 28.77 113.66 28.77 86.3 1.4"/>
      <path className="cls-2"
            d="M520.71,434.06a9.4,9.4,0,0,0-2.39-6.43,9.38,9.38,0,0,0-2.4,6.43,24,24,0,0,0,2.4,9.16A24.15,24.15,0,0,0,520.71,434.06Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-2"
            d="M528.83,440.35a13.73,13.73,0,0,0,2.71-3,13.32,13.32,0,0,0-3.43,2.14,26,26,0,0,0-3.42,3.6A25.61,25.61,0,0,0,528.83,440.35Z"
            transform="translate(-479.92 -347.76)"/>
      <path className="cls-1"
            d="M563.82,381.32a2.39,2.39,0,0,1-2.39-2.4V347.76H482.32a2.39,2.39,0,0,0-2.4,2.4V479.6a2.39,2.39,0,0,0,2.4,2.4H592.59a2.4,2.4,0,0,0,2.4-2.4V381.32Zm14.39,4.79a2.4,2.4,0,0,1,0,4.8H539.85a2.4,2.4,0,1,1,0-4.8Zm-57.54-4.79a2.4,2.4,0,0,1,0-4.79h33.56a2.4,2.4,0,1,1,0,4.79Zm-24-19.18h57.53a2.4,2.4,0,0,1,0,4.8H496.7a2.4,2.4,0,0,1,0-4.8Zm0,14.39h14.39a2.4,2.4,0,0,1,0,4.79H496.7a2.4,2.4,0,0,1,0-4.79Zm0,9.58h33.56a2.4,2.4,0,1,1,0,4.8H496.7a2.4,2.4,0,0,1,0-4.8Zm0,9.59h81.51a2.4,2.4,0,0,1,0,4.8H496.7a2.4,2.4,0,0,1,0-4.8Zm83.9,12a2.4,2.4,0,0,1-2.39,2.4h-48a2.4,2.4,0,0,1,0-4.8h48A2.39,2.39,0,0,1,580.6,407.69Zm-83.9-2.4h24a2.4,2.4,0,1,1,0,4.8h-24a2.4,2.4,0,0,1,0-4.8Zm21.58,67.12A28.77,28.77,0,1,1,547,443.65,28.79,28.79,0,0,1,518.28,472.41ZM547,417.28a2.39,2.39,0,0,1,2.4-2.4h28.77a2.4,2.4,0,0,1,0,4.79H549.44A2.39,2.39,0,0,1,547,417.28Zm33.56,9.59a2.39,2.39,0,0,1-2.39,2.39h-24a2.4,2.4,0,0,1,0-4.79h24A2.39,2.39,0,0,1,580.6,426.87Zm-28.76,9.58a2.39,2.39,0,0,1,2.39-2.39h24a2.4,2.4,0,0,1,0,4.79h-24A2.39,2.39,0,0,1,551.84,436.45ZM583,467.62h-9.59a2.4,2.4,0,0,1,0-4.8H583a2.4,2.4,0,0,1,0,4.8Zm0-9.59H559a2.4,2.4,0,1,1,0-4.8h24a2.4,2.4,0,1,1,0,4.8Z"
            transform="translate(-479.92 -347.76)"/>
    </g>
  </SVG2>


const OutOfStock = () =>

  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.3 265.3">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-1"
            d="M1077.29,524.7A132.79,132.79,0,0,0,944.64,657.35c0,73.14,59.5,132.65,132.65,132.65s132.65-59.51,132.65-132.65S1150.43,524.7,1077.29,524.7ZM971.17,657.35a106.12,106.12,0,0,1,169.93-84.79L992.5,721.16A106.28,106.28,0,0,1,971.17,657.35Zm106.12,106.12a106,106,0,0,1-66.24-23.34l149-149a106,106,0,0,1-82.78,172.36Z"
            transform="translate(-944.64 -524.7)"/>
      <path className="cls-1"
            d="M1081.27,571.3a4.71,4.71,0,0,0-8,0c-.53,1.15-13.8,27.42-13.71,52.71a105.88,105.88,0,0,0,.89,13.09,101.16,101.16,0,0,0-9.91-14.06c-19.54-23.26-37.94-27.33-38.73-27.51a4.7,4.7,0,0,0-4,1.15,4.24,4.24,0,0,0-1.24,3.89c.18,1.33,5.48,32,27.24,54.92a87.53,87.53,0,0,0-40.95-7A4.62,4.62,0,0,0,989.3,651a4.25,4.25,0,0,0,.26,4.33c.44.71,9.64,15.13,29.36,26.89l74.46-74.46A147.75,147.75,0,0,0,1081.27,571.3Z"
            transform="translate(-944.64 -524.7)"/>
      <path className="cls-1"
            d="M1165.28,651a4.44,4.44,0,0,0-3.63-2.47,89.49,89.49,0,0,0-40.85,6.8c10.08-10.61,16.53-22.81,20.69-33.07L1036.17,727.57a52.79,52.79,0,0,0,19.45-6.64,67,67,0,0,0,17.25-14.32v34.75a4.42,4.42,0,0,0,8.84,0V706.61c8.31,9.55,23,21.49,44.22,21.49a4.36,4.36,0,0,0,4.33-5.31c-.18-1-4.24-18.13-26.88-28,41.65-8.76,60.75-38,61.63-39.45A4.23,4.23,0,0,0,1165.28,651Z"
            transform="translate(-944.64 -524.7)"/>
    </g>
  </svg>



const Eye = () =>

  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162.62 82.51">
    <defs>
      <style>

      </style>
    </defs>
    <g>
      <path className="cls-1"
            d="M750.68,377.5c-42.74,0-77,36.86-81.3,41.72L670,419c1-.49,22.91-11.17,53.56-15.78h0a182.56,182.56,0,0,1,27.1-2.11c44.13,0,79.39,17.26,80.87,18l.45.18C827.75,414.47,793.49,377.5,750.68,377.5Z"
            transform="translate(-669.38 -377.5)"/>
      <circle className="cls-1" cx="81.3" cy="47.15" r="11.79"/>
      <path className="cls-1"
            d="M750.68,454.12a29.38,29.38,0,0,0,24.79-45.29A173.29,173.29,0,0,0,750.68,407,17.68,17.68,0,1,1,733,424.65,17.7,17.7,0,0,1,750.68,407a174.64,174.64,0,0,0-24.78,1.85,29.38,29.38,0,0,0,24.78,45.3Z"
            transform="translate(-669.38 -377.5)"/>
      <path className="cls-2"
            d="M813.14,430.48a2.86,2.86,0,0,1-.58.06,2.94,2.94,0,0,1-2.47-1.34c-.14-.21-3.85-5.6-12.89-7.56a3,3,0,0,1,1.25-5.77,28.52,28.52,0,0,1,15.29,8.48l10.49-2.09A205.14,205.14,0,0,0,782.82,410a35.26,35.26,0,0,1-32.14,50c36.9,0,67.49-23.68,77.86-32.75a3.19,3.19,0,0,1-.66.28Zm-7.33,2.15c-6.52,6.52-13,6.75-13.86,6.75h-.1a2.94,2.94,0,0,1-2.86-3,2.75,2.75,0,0,1,3-2.86c.26,0,4.73-.1,9.66-5a2.95,2.95,0,0,1,4.17,4.17Z"
            transform="translate(-669.38 -377.5)"/>
      <path className="cls-2"
            d="M715.32,424.65A35.11,35.11,0,0,1,718.55,410a206,206,0,0,0-41.43,12.22l10.5,2.1a28.52,28.52,0,0,1,15.29-8.48,3,3,0,0,1,2.71.8l5.89,5.89a2.95,2.95,0,0,1-4.17,4.17L702.62,422c-7.92,2.2-11.22,7-11.36,7.21a3,3,0,0,1-2.47,1.32,2.76,2.76,0,0,1-.57-.06l-14.73-2.94a3.06,3.06,0,0,1-.67-.28c10.37,9.07,41,32.75,77.86,32.75A35.4,35.4,0,0,1,715.32,424.65Zm-5.81,14.73h-.1c-.89,0-7.34-.23-13.85-6.75a2.95,2.95,0,1,1,4.16-4.17c5,5,9.46,5,9.66,5h0a3,3,0,0,1,3,2.9A2.92,2.92,0,0,1,709.51,439.38Z"
            transform="translate(-669.38 -377.5)"/>
    </g>
  </svg>

const Bag = () => <SVG3 id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 589.5 750.27">
  <defs>
    <style>

    </style>
  </defs>
  <g>
    <path className="cls-1"
          d="M826.16,889.75h53.59a13.39,13.39,0,0,0,13.4-13.4V662a13.35,13.35,0,0,0-3.93-9.47l-53.59-53.59a13.39,13.39,0,0,0-22.87,9.47v268A13.39,13.39,0,0,0,826.16,889.75Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-1"
          d="M585,889.75h53.59a13.39,13.39,0,0,0,13.4-13.4v-268a13.39,13.39,0,0,0-22.87-9.47l-53.59,53.59A13.39,13.39,0,0,0,571.6,662V876.35A13.39,13.39,0,0,0,585,889.75Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-1" d="M678.79,675.39H786v-67a37.08,37.08,0,0,1,2.41-13.4h-112a37.09,37.09,0,0,1,2.42,13.4Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M659.85,1190a86.3,86.3,0,0,0,20.87-15.33c7-7,13.57-15.91,19.21-24.75-8.86,5.65-17.74,12.19-24.76,19.2A86.27,86.27,0,0,0,659.85,1190Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M623.52,1117.51c-18.29,0-31.36,3.19-40.18,6.7,8.81,3.51,21.88,6.7,40.18,6.7,18,0,38.14-3,55.88-6.7C661.65,1120.54,641.52,1117.51,623.52,1117.51Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M719,983.53c0,28.81,6.76,61.91,13.4,87.43,6.63-25.54,13.39-58.64,13.39-87.43,0-29.93-7.24-48.59-13.41-59.06C726.24,934.89,719,953.57,719,983.53Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M868.87,981c-11.7,3.06-30,11.12-51.23,32.32-20.37,20.36-39,48.54-52.35,71.28,22.74-13.37,51-32,71.3-52.33C857.74,1011.09,865.83,992.74,868.87,981Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M647.11,1013.31c-21.17-21.17-39.49-29.26-51.23-32.29,3,11.7,11.1,30,32.28,51.24,20.38,20.35,48.56,39,71.3,52.33C686.09,1061.85,667.47,1033.64,647.11,1013.31Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M1013.73,755.77H919.94V876.35a40.13,40.13,0,0,1-40.19,40.19H826.16A40.13,40.13,0,0,1,786,876.35V755.77H678.79V876.35a40.14,40.14,0,0,1-40.2,40.19H585a40.13,40.13,0,0,1-40.19-40.19V755.77H451a13.27,13.27,0,0,0-13.4,13.4v509.11a67,67,0,0,0,67,67H960.14a67,67,0,0,0,67-67V769.17A13.27,13.27,0,0,0,1013.73,755.77Zm-97,377.15c-2.18,2.54-22.76,24.78-75.5,24.78a244.56,244.56,0,0,1-27.76-1.88c22.35,25.59,25.88,52.76,26,54a13.42,13.42,0,0,1-13.32,14.89,14.68,14.68,0,0,1-1.48-.08c-1.32-.16-32.54-3.9-59.59-30.95a165.7,165.7,0,0,1-19.32-24.05v108.67a13.4,13.4,0,1,1-26.79,0V1169.61a165,165,0,0,1-19.31,24.05c-27.06,27-58.28,30.79-59.6,30.95-.5.05-1,.08-1.48.08a13.42,13.42,0,0,1-13.32-14.89c.14-1.22,3.66-28.39,26-54a244.56,244.56,0,0,1-27.76,1.88c-52.74,0-73.32-22.24-75.49-24.78a13.41,13.41,0,0,1,0-17.43c2.17-2.53,22.75-24.78,75.49-24.78a270.53,270.53,0,0,1,40.5,3.51c-18.87-12.28-38.8-27-54.8-43-45.91-45.92-41.92-85.17-41.72-86.82a13.41,13.41,0,0,1,11.76-11.78c1.54-.18,40.88-4.16,86.8,41.77a301.84,301.84,0,0,1,30.31,36.21,300.78,300.78,0,0,1-4.19-47c0-64.92,30.58-89.86,31.89-90.9a13.48,13.48,0,0,1,16.62,0c1.3,1,31.88,26,31.88,90.9a300.78,300.78,0,0,1-4.19,47,301,301,0,0,1,30.32-36.21c45.9-46,85.17-42,86.8-41.77a13.43,13.43,0,0,1,11.76,11.78c.19,1.65,4.18,40.9-41.73,86.82-16,16-35.92,30.74-54.8,43a270.64,270.64,0,0,1,40.5-3.51c52.74,0,73.32,22.25,75.5,24.78A13.41,13.41,0,0,1,916.73,1132.92Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M764.82,1150c5.66,8.84,12.2,17.74,19.21,24.75A85.92,85.92,0,0,0,804.9,1190a86.27,86.27,0,0,0-15.32-20.88C782.55,1162.15,773.67,1155.61,764.82,1150Z"
          transform="translate(-437.63 -595)"/>
    <path className="cls-2"
          d="M785.35,1124.21c17.76,3.66,37.88,6.7,55.88,6.7,18.29,0,31.36-3.19,40.18-6.7-8.8-3.51-21.87-6.7-40.18-6.7C823.2,1117.51,803.09,1120.54,785.35,1124.21Z"
          transform="translate(-437.63 -595)"/>
  </g>
</SVG3>



export const LilaEye = styled(Eye)`

.cls-1{
        fill: var(--txt) !important;

}
svg{width:24px;

}

`


export const LilaOos = styled(OutOfStock)`
 .cls-1 {
        fill: #578280;}

`


export const LilaStats = styled(Stats)`
        .cls-1 {
        fill: var(--txt);
      }
`


export const LilaCert = styled(Cert)`
          .cls-1 {
        fill: var(--txt);
      }
`

export const LilaStats2 = styled(Stats2)`
        .cls-1 {
        fill: var(--txt);
      }
`


export const LilaCert2 = styled(Cert2)`
          .cls-1 {
        fill: var(--txt);
      }
`


export const LilaGlass = styled(Glastiegel)`
        .cls-1 {
        fill: var(--txt);
      }
`

export const LilaGrowMethod = styled(GrowMethod)`
        .cls-1 {
        fill: var(--txt);
      }
`

export const LilaWeight = styled(Weight)`
        .cls-1 {
        fill: var(--txt);
      }
`

export const LilaShop = styled(Shop)`
       .cls-1 {
        fill: var(--txt);
      }
`


export const LilaThankYou = styled(ThankYou)`
        .cls-1 {
        fill: var(--txt);
      }
`

export const LilaSpot = styled(Spot)`
svg{
width:24px !important;
}

          .cls-1 {
        fill: var(--icon1);
      }

        .cls-2 {
        fill: var(--svg);
      }
`

export const LilaHeart = styled(Heart)`
 .cls-1 {
        fill: var(--icon1);

        .cls-2 {
        fill: var(--icon1);
      }
`

export const LilaEcoBag = styled(EcoBag)`
     .cls-1 {
        fill: var(--icon1);
      }

`
export const LilaShopBag = styled(Bag)`
     .cls-1 {
        fill: var(--icon1);
      }

`



